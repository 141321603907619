import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit'
import apiRequest from '../utils/api'

export const setOnboarding = createAsyncThunk(
  'auth/onboard',
  async (userId: string | undefined = undefined, { dispatch }) => {
    await dispatch(
      apiRequest({
        method: 'post',
        route: 'user/onboard',
        data: {},
        userId,
      }),
    ).then(unwrapResult)
  },
)
