import styled from 'styled-components'

const FileInput = styled.input<{ error: boolean }>`
  border: ${props =>
    props.error ? `1px solid ${props.theme.colors.error}` : 'none'};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.text};
  font-size: 1rem;
  width: 100%;
`

export default FileInput
