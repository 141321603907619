import React from 'react'
import styled from 'styled-components'
import { Box } from 'atomic-layout'
import { astraTheme } from '../styles/theme'

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'ion-icon': { name: string; style?: unknown }
    }
  }
}

const Icon: React.FC<{ className?: string; name: string; style?: any }> = ({
  className,
  name,
  style,
}) => {
  return (
    <Box
      className={className}
      flex
      alignItems='center'
      justifyContent='center'
      style={style}
    >
      <ion-icon name={name} />
    </Box>
  )
}

interface IconProps {
  color?: keyof typeof astraTheme.colors
  name: string
  size?: number
  style?: any
}

const IonIcon = styled(Icon)<IconProps>`
  color: ${props => props.theme.colors[props.color || 'text']};
  font-size: ${props => (props.size ? `${props.size}px` : '10px')};
`

export default IonIcon
