import React from 'react'
import styled from 'styled-components'
import { Box } from 'atomic-layout'

const Container = styled(Box)`
  height: ${props => (props.isOpen ? 'auto' : 0)};
  overflow: hidden;
  transition: height 0.2s ease-in-out;
`

interface Props {
  isOpen: boolean
}

const AccordionContent: React.FC<Props> = ({ children, isOpen }) => (
  <Container isOpen={isOpen}>{children}</Container>
)

export default AccordionContent
