import React from 'react'
import styled from 'styled-components'
import { Box } from 'atomic-layout'

import { ButtonSecondary, Container, Text } from '../../atoms'
import { sanitizeUrl } from 'src/utils/helpers'

const ClientLogo = styled.img`
  border-radius: 27px;
  height: 54px;
  width: 54px;
`

const ClientAppModule = ({ clientApp }) => {
  if (!clientApp) return null

  return (
    <Container>
      <Box flex alignItems='center' justifyContent='space-between'>
        <ClientLogo
          src={clientApp.square_logo_url}
          alt={`${clientApp.name} logo`}
        />
      </Box>
      <Box marginVertical={12}>
        <Text fontFamily='heavy' size='large' textAlign='left'>
          {clientApp.name}
        </Text>
      </Box>
      <Text color='textSubtle' size='small'>
        {clientApp.description}
      </Text>
      {clientApp.app_link && (
        <Box marginTop={20}>
          <ButtonSecondary
            as='a'
            href={sanitizeUrl(clientApp.app_link)}
            target='_blank'
            display='block'
            fullWidth
          >
            Visit App
          </ButtonSecondary>
        </Box>
      )}
    </Container>
  )
}

export default ClientAppModule
