import React from 'react'
import { useSelector } from 'react-redux'
import { Composition } from 'atomic-layout'
import { useForm } from 'react-hook-form'

import TermsOfService from './TermsOfService'
import { selectClient, selectIsLoggedIn, selectIsSigningUp } from './authSlice'
import { EMAIL_REGEX } from '../../utils/auth'
import { Button, Container, Input, Text } from '../../atoms'

const areasMobile = `
  title
  subtitle
  form
`

const formAreasMobile = `
  email
  firstName
  lastName
  legal
  button
`

interface SignupModuleProps {
  onSubmit: (data: {
    firstName: string
    lastName: string
    email: string
  }) => void
}

const SignUpModule: React.FC<SignupModuleProps> = ({ onSubmit }) => {
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
  })

  const client = useSelector(selectClient)
  const isSigningUp = useSelector(selectIsSigningUp)
  const isLoggedIn = useSelector(selectIsLoggedIn)

  return (
    <Composition areas={areasMobile} as={Container}>
      {Areas => (
        <>
          <Areas.Title marginBottom={3}>
            <Text fontFamily='heavy' size='large'>
              Create your profile
            </Text>
          </Areas.Title>
          <Areas.Subtitle marginBottom={15}>
            <Text fontFamily='medium' size='small' color='textSubtle'>
              Enter your email address and legal name.
            </Text>
          </Areas.Subtitle>
          <Areas.Form>
            <form
              onSubmit={async e => {
                e.preventDefault()
                await handleSubmit(onSubmit as any)(e)
              }}
            >
              <Composition areas={formAreasMobile} gap={10}>
                {FormAreas => (
                  <>
                    <FormAreas.Email>
                      <Input
                        id='email'
                        type='email'
                        placeholder='Email Address'
                        bordercolor={errors.email && 'error'}
                        {...register('email', {
                          required: true,
                          pattern: EMAIL_REGEX,
                        })}
                      />
                    </FormAreas.Email>
                    <FormAreas.FirstName>
                      <Input
                        id='firstName'
                        type='text'
                        placeholder='First Name'
                        bordercolor={errors.firstName && 'error'}
                        {...register('firstName', {
                          required: true,
                        })}
                      />
                    </FormAreas.FirstName>
                    <FormAreas.LastName>
                      <Input
                        id='lastName'
                        type='text'
                        placeholder='Last Name'
                        bordercolor={errors.lastName && 'error'}
                        {...register('lastName', {
                          required: true,
                        })}
                      />
                    </FormAreas.LastName>
                    {!client.name && (
                      <FormAreas.Legal>
                        <TermsOfService isLoggedIn={isLoggedIn} />
                      </FormAreas.Legal>
                    )}
                    <FormAreas.Button>
                      <Button
                        id='auth-button'
                        type='submit'
                        disabled={isSigningUp || !isValid}
                        fullWidth
                      >
                        {isSigningUp ? 'Creating Profile...' : 'Continue'}
                      </Button>
                    </FormAreas.Button>
                  </>
                )}
              </Composition>
            </form>
          </Areas.Form>
        </>
      )}
    </Composition>
  )
}

export default SignUpModule
