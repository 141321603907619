import React from 'react'
import { Box } from 'atomic-layout'
import styled from 'styled-components'

import { Text } from '../../atoms'

const Container = styled(Box)`
  cursor: pointer;
  padding: 15px 18px;
  &:hover {
    background: ${({ theme }) => theme.colors.backgroundTinted};
  }
`

const AddressRow = ({ address }) => {
  const streetLine1 = address?.street_line_1 || ''
  const streetLine2 = address?.street_line_2 || ''
  const city = address?.city || ''
  const state = address?.state || ''
  const zipCode = address?.zip_code || ''
  return (
    <Container flex alignItems='center' justifyContent='space-between'>
      <Box flexGrow={1}>
        <Text fontFamily='heavy' size='small'>
          {`${streetLine1}\n${streetLine2}`}
        </Text>
        <Text color='textSubtle' size='xsmall'>
          {`${city}, ${state} ${zipCode}`}
        </Text>
      </Box>
      <Text color='primary' cursor='pointer' fontFamily='medium' size='small'>
        Edit
      </Text>
    </Container>
  )
}

export default AddressRow
