import React from 'react'
import styled from 'styled-components'
import { astraTheme } from '../styles/theme'

interface StyledIconProps {
  color?: keyof typeof astraTheme.colors
  size?: number
}
const IconStyled = styled.span<StyledIconProps>`
  color: ${props =>
    props.color ? props.theme.colors[props.color] : props.theme.colors.primary};
  font-size: ${props => (props.size ? `${props.size}px` : '10px')};
`

interface IconProps {
  className?: string
  color?: keyof typeof astraTheme.colors
  name: string
  size?: number
}
const Icon: React.FC<IconProps> = ({ color, name, size }) => (
  <IconStyled color={color} className={`icon-${name}`} size={size} />
)

export default Icon
