import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { navigate } from '@reach/router'
import { Box, Composition } from 'atomic-layout'
import * as queryString from 'query-string'

import DebitCardRow from './DebitCardRow'
import { Collapse, useDisclosure } from '@chakra-ui/react'
import Spinner from 'src/components/Spinner'
import VgsCollectForm from './VgsCollectForm'
import { ButtonSecondary, Container, Text } from 'src/atoms'
import { getAddresses, selectAddressesLoading } from './addressesSlice'
import { selectClient, selectUser } from 'src/features/auth/authSlice'
import { cardsSelectors, getCards, selectCardsLoading } from './cardsSlice'
import {
  selectIframeOrigin,
  sendClosedMessage,
} from 'src/features/sdkMessaging/sdkMessagingSlice'
import { User, UserType } from 'src/features/auth/types'

const ConnectDebitCardModule: React.FC = () => {
  const dispatch = useDispatch()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const {
    redirect_uri: redirectUri,
    continue_to: continueTo,
    return_to: returnTo,
    debit_direct: debitDirectParam,
    state,
  } = queryString.parse(window.location.search)

  const addressesLoading = useSelector(selectAddressesLoading)
  const cardsLoading = useSelector(selectCardsLoading)
  const clientId = useSelector(selectClient).clientId
  const debitCards = useSelector(cardsSelectors.selectAll)
  const iframeOrigin = useSelector(selectIframeOrigin)

  const user = useSelector(selectUser) as User
  const isBusinessUser = user.userType === UserType.BUSINESS

  const loading = addressesLoading || cardsLoading
  const returnToSplit =
    returnTo && typeof returnTo === 'string' ? returnTo.split('?')[1] : ''
  const returnToParts = returnToSplit ? queryString.parse(returnToSplit) : {}

  const debitDirect =
    debitDirectParam === 'true' || returnToParts.debit_direct === 'true'

  useEffect(() => {
    dispatch(getAddresses())
    // @ts-ignore
    dispatch(getCards({ clientId }))
  }, [clientId, dispatch])

  useEffect(() => {
    if (!loading && debitDirect) {
      onOpen()
    } else {
      onClose()
    }
  }, [debitDirect, loading, onClose, onOpen])

  const handleClose = () => {
    if (debitDirect) {
      if (continueTo || returnTo) {
        navigate((continueTo as string) || (returnTo as string))
      } else if (clientId && redirectUri) {
        const oAuthState = state
          ? `${redirectUri.indexOf('?') !== -1 ? '&' : '?'}state=${state}`
          : ''
        const link = `${redirectUri}${oAuthState}`

        if (iframeOrigin) {
          dispatch(sendClosedMessage())
        } else {
          navigate(link)
        }
      }
    } else {
      onClose()
    }
  }

  const renderDebitCards = () => {
    if (!debitCards || debitCards.length === 0) {
      return null
    }

    return (
      <Box marginBottom={20}>
        {debitCards.map((card: any) => (
          <DebitCardRow
            key={card.id}
            card={card}
            isDisabled={Boolean(clientId) || debitDirect}
          />
        ))}
      </Box>
    )
  }

  if (loading) {
    return (
      <Box flex alignItems='center' justifyContent='center' marginVertical={30}>
        <Spinner size='small' />
      </Box>
    )
  }

  return (
    <Composition
      areas={`
        title
        subtitle
        cards
        connectForm
        connectButton
      `}
      as={Container}
    >
      {({ Title, Subtitle, Cards, ConnectForm, ConnectButton }) => (
        <>
          <Collapse in={!isOpen} animateOpacity>
            {!debitDirect && (
              <>
                <Title marginBottom={3}>
                  <Text fontFamily='heavy' size='large'>
                    {isBusinessUser
                      ? 'Connect Business Debit Card'
                      : 'Connect Debit Card'}
                  </Text>
                </Title>
                <Subtitle marginBottom={20}>
                  <Text fontFamily='medium' size='small' color='textSubtle'>
                    To get started, connect a debit card.
                  </Text>
                </Subtitle>
                <Cards>{renderDebitCards()}</Cards>
              </>
            )}
          </Collapse>
          <Collapse in={isOpen} animateOpacity unmountOnExit>
            <ConnectForm>
              <VgsCollectForm debitDirect={debitDirect} onClose={handleClose} />
            </ConnectForm>
          </Collapse>
          {!isOpen && !debitDirect && (
            <ConnectButton>
              <ButtonSecondary active={false} onClick={onOpen} fullWidth>
                Connect New Debit Card
              </ButtonSecondary>
            </ConnectButton>
          )}
        </>
      )}
    </Composition>
  )
}

export default ConnectDebitCardModule
