import React from 'react'
import { Box } from 'atomic-layout'

import { Text } from '../../atoms'

interface Props {
  loading?: boolean
  logo?: string
  name?: string
}

const AuthHeader: React.FC<Props> = ({ name }) => {
  return (
    <Box>
      {name && (
        <Box flex paddingHorizontal={20} marginBottom={20}>
          <Text
            fontFamily='medium'
            size='large'
            display='inline'
            textAlign='center'
            style={{ margin: '0 auto' }}
          >
            <span>
              <b>{name}</b> partners with <b>Astra</b> for faster payments
            </span>
          </Text>
        </Box>
      )}
    </Box>
  )
}

export default AuthHeader
