import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  unwrapResult,
} from '@reduxjs/toolkit'
import toast from 'react-hot-toast'
import apiRequest from '../../utils/api'
import {
  extractOAuthClients,
  removeOAuthClientsFromArr,
} from '../../utils/oauthClients'
import { setOAuthClients } from '../clients/clientsSlice'

const transfersAdapter = createEntityAdapter({
  sortComparer: (a, b) => new Date(b.date) - new Date(a.date),
})

export const transfersSelectors = transfersAdapter.getSelectors(
  state => state.transfers,
)

export const getTransfers = createAsyncThunk(
  'transfers/getAll',
  async (empty, { dispatch }) => {
    const { transfers = [] } = await dispatch(
      apiRequest({
        method: 'get',
        route: 'transfers',
      }),
    ).then(unwrapResult)

    const clients = extractOAuthClients(transfers)

    const editedTransfers = removeOAuthClientsFromArr(transfers)

    dispatch(setOAuthClients(clients))

    return editedTransfers
  },
)

export const getTransfer = createAsyncThunk(
  'transfer/getById',
  async ({ id }, { dispatch }) => {
    const { astra_transfer: transfer = {} } = await dispatch(
      apiRequest({
        method: 'get',
        route: `transfers/${id}`,
      }),
    ).then(unwrapResult)

    const clients = extractOAuthClients([transfer])

    const editedTransfers = removeOAuthClientsFromArr([transfer])

    dispatch(setOAuthClients(clients))

    return editedTransfers[0]
  },
)

export const cancelTransfer = createAsyncThunk(
  'transfer/cancel',
  async ({ id }, { dispatch }) => {
    await dispatch(
      apiRequest({
        method: 'post',
        route: `transfers/${id}/cancel`,
        data: {},
      }),
    ).then(unwrapResult)
    toast.success('Your transfer has been cancelled.')
    dispatch(getTransfer({ id }))
  },
)

const transfersSlice = createSlice({
  name: 'transfers',
  initialState: transfersAdapter.getInitialState({
    loading: false,
    isCancelling: false,
  }),
  extraReducers: {
    [getTransfers.pending]: state => {
      state.loading = true
    },
    [getTransfers.fulfilled]: (state, action) => {
      state.loading = false
      if (action.payload) {
        transfersAdapter.setAll(state, action.payload)
      }
    },
    [getTransfers.rejected]: state => {
      state.loading = false
    },
    [getTransfer.pending]: state => {
      state.loading = true
    },
    [getTransfer.fulfilled]: (state, action) => {
      state.loading = false
      if (action.payload) {
        transfersAdapter.upsertOne(state, action.payload)
      }
    },
    [getTransfer.rejected]: state => {
      state.loading = false
    },
    [cancelTransfer.pending]: state => {
      state.isCancelling = true
    },
    [cancelTransfer.fulfilled]: state => {
      state.isCancelling = false
    },
    [cancelTransfer.rejected]: state => {
      state.isCancelling = false
    },
  },
})

export const selectTransfersLoading = state => state.transfers.loading
export const selectIsCancellingTransfer = state => state.transfers.isCancelling

export default transfersSlice.reducer
