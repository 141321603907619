import styled from 'styled-components'
import { Box } from 'atomic-layout'

export default styled(Box)`
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 4px;
  margin-bottom: 25px;
  padding: 12px 18px 18px;
  width: 100%;
`
