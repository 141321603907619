import { DependencyList, EffectCallback, useEffect, useRef } from 'react'
import isEqual from 'lodash/isEqual'

/**
 * Same as useEffect but does a deep comparison.
 *
 * The purpose of this hook is to provide a deep comparison of dependencies, which is particularly
 * useful when working with complex objects or arrays as dependencies. You can use this just as you
 * would set up a vanilla `useEffect`.
 */
const useDeepEffect = (
  callback: EffectCallback,
  dependencies: DependencyList,
): void => {
  const dependenciesRef = useRef(dependencies)

  useEffect(() => {
    if (!isEqual(dependenciesRef.current, dependencies)) {
      // Execute the function
      callback()

      // Update the dependencies
      dependenciesRef.current = dependencies
    }
  }, [callback, dependencies])
}

export default useDeepEffect
