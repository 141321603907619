import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { navigate } from '@reach/router'
import { Box, Composition } from 'atomic-layout'
import styled from 'styled-components'

import AccordionContent from 'src/components/AccordionContent'
import AccordionIcon from 'src/components/AccordionIcon'
import Count from 'src/components/Count'
import DebitCardRow from './DebitCardRow'
import Modal from 'src/components/Modal'
import VgsCollectForm from 'src/features/accounts/VgsCollectForm'
import { Button, Container, IonIcon, Link, Text } from 'src/atoms'
import { selectAddressesLoading } from './addressesSlice'
import { cardsSelectors, selectCardsLoading } from './cardsSlice'
import { selectUser } from 'src/features/auth/authSlice'
import { User, UserType } from 'src/features/auth/types'

const areasMobile = `
  header
  list
  connectForm
  footer
`
const listSize = 4

const AddDebitCardButton = styled(Button)`
  background: ${({ theme }) => theme.colors.background};
  border: 0;
  padding: 0;
`

const DebitCardsOverview: React.FC = () => {
  const debitCards = useSelector(cardsSelectors.selectAll)
  const addressesLoading = useSelector(selectAddressesLoading)
  const cardsLoading = useSelector(selectCardsLoading)
  const loading = addressesLoading || cardsLoading

  const user = useSelector(selectUser) as User
  const isBusinessUser = user.userType === UserType.BUSINESS

  const [showConnectForm, setShowConnectForm] = useState(false)
  const [isOpen, setIsOpen] = useState(true)

  useEffect(() => {
    setIsOpen(debitCards.length !== 0)
  }, [debitCards])

  return (
    <Composition areas={areasMobile} as={Container} paddingHorizontal={0}>
      {({ Header, ConnectForm, List, Footer }) => (
        <>
          <Header flex alignItems='center' paddingHorizontal={18}>
            <Text
              cursor='pointer'
              fontFamily='heavy'
              size='large'
              onClick={() => navigate('/cards')}
            >
              Debit Cards
            </Text>
            <Box marginHorizontal={10}>
              <Count
                count={debitCards.length}
                onClick={() => navigate('/cards')}
                loading={loading}
              />
            </Box>
            <AddDebitCardButton onClick={() => setShowConnectForm(true)}>
              <IonIcon name='add-circle' color='accent' size={30} />
            </AddDebitCardButton>
            <Box
              flex
              flexGrow={'1'}
              justifyContent='flex-end'
              onClick={() => setIsOpen(!isOpen)}
            >
              <AccordionIcon isOpen={isOpen} />
            </Box>
          </Header>
          <ConnectForm>
            <Modal
              ariaLabel={
                isBusinessUser
                  ? 'Connect Business Debit Card'
                  : 'Connect Debit Card'
              }
              isOpen={showConnectForm}
              close={() => setShowConnectForm(false)}
            >
              <VgsCollectForm
                onClose={() => setShowConnectForm(false)}
                debitDirect={false}
              />
            </Modal>
          </ConnectForm>
          <AccordionContent isOpen={isOpen}>
            <>
              <List marginTop={20} marginBottom={10}>
                {debitCards &&
                  debitCards
                    .slice(0, listSize)
                    .map((card: any) => (
                      <DebitCardRow
                        key={card.id}
                        card={card}
                        isDisabled={false}
                      />
                    ))}
              </List>
              {debitCards && debitCards.length > listSize && (
                <Footer flex alignItems='center' justifyContent='center'>
                  <Link to='/cards' size='small'>
                    More
                  </Link>
                </Footer>
              )}
            </>
          </AccordionContent>
        </>
      )}
    </Composition>
  )
}

export default DebitCardsOverview
