import React from 'react'
import { Control, Controller, FieldError } from 'react-hook-form'
import { MaskedInput } from 'src/atoms'

interface SocialSecurityFieldProps {
  id: string
  name: string
  requireFullNumber: boolean
  defaultValue: string
  error?: FieldError
  control: Control<any, any>
}

/**
 * A functional React component which renders an input field utilizes for SSN numbers
 * This input field uses the `MaskedInput` component for privacy measures.
 */
const SocialSecurityField: React.FC<SocialSecurityFieldProps> = ({
  id,
  name,
  requireFullNumber,
  defaultValue,
  error,
  control,
}) => (
  <Controller
    control={control}
    name={name}
    defaultValue={defaultValue}
    rules={{
      validate: {
        ssnRequired: value => {
          const formattedValue = value.replace(/[$-\s]/g, '')
          const validLength = requireFullNumber ? 9 : 4
          return formattedValue.length < validLength
            ? `${validLength} digits required`
            : undefined
        },
      },
    }}
    render={({ field: { onChange, onBlur, value } }) => (
      <MaskedInput
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        name={name}
        id={id}
        type='text'
        format={requireFullNumber ? '###-##-####' : '####'}
        placeholder={requireFullNumber ? 'SSN' : 'Last 4 digits of SSN'}
        bordercolor={error && 'error'}
      />
    )}
  />
)

export default SocialSecurityField
