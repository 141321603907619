import React from 'react'

const AccordionIcon: React.FC<{ isOpen: boolean }> = ({ isOpen }) => (
  <ion-icon
    name='chevron-down-outline'
    style={{
      cursor: 'pointer',
      fontSize: 20,
      transform: isOpen ? 'rotate(180deg)' : '',
    }}
  />
)

export default AccordionIcon
