import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Composition } from 'atomic-layout'
import {
  getClients,
  selectOAuthClientsLoading,
  clientsSelectors,
} from './clientsSlice'
import { Container, Text } from '../../atoms'
import Count from '../../components/Count'
import Spinner from '../../components/Spinner'
import ClientAppRow from './ClientAppRow'

const areasMobile = `
  header
  list
`

const ClientApps = () => {
  const dispatch = useDispatch()
  const clientApps = useSelector(clientsSelectors.selectAll)
  const loading = useSelector(selectOAuthClientsLoading)

  useEffect(() => {
    dispatch(getClients())
  }, [dispatch])

  return (
    <Composition areas={areasMobile} as={Container} paddingHorizontal={0}>
      {Areas => (
        <>
          <Areas.Header flex alignItems='center' paddingHorizontal={18}>
            <Text fontFamily='heavy' size='large'>
              Apps
            </Text>
            <Box marginLeft={10}>
              <Count count={clientApps.length} loading={loading} />
            </Box>
          </Areas.Header>
          {loading ? (
            <Box
              flex
              alignItems='center'
              justifyContent='center'
              paddingVertical={50}
            >
              <Spinner />
            </Box>
          ) : (
            <>
              <Areas.List marginTop={20} marginBottom={10}>
                {clientApps &&
                  clientApps.map(clientApp => (
                    <ClientAppRow key={clientApp.id} clientApp={clientApp} />
                  ))}
              </Areas.List>
            </>
          )}
        </>
      )}
    </Composition>
  )
}

export default ClientApps
