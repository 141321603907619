import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Box } from 'atomic-layout'
import { unwrapResult } from '@reduxjs/toolkit'
import { navigate } from '@reach/router'

import {
  editRoutine,
  getRoutines,
  selectIsRemovingRoutine,
  selectRoutinesLoading,
  removeRoutine,
  routinesSelectors,
} from './routinesSlice'
import { accountsSelectors, getAccounts } from '../accounts/accountsSlice'
import {
  getInstitutions,
  institutionsSelectors,
} from '../accounts/institutionsSlice'
import { clientsSelectors } from '../clients/clientsSlice'

import ClientAppModule from '../clients/ClientAppModule'
import DropdownMenu from '../../components/DropdownMenu'
import RoutineStatus from '../../components/RoutineStatus'
import NotFound from '../../pages/NotFound'

import { Container, InstitutionLogo, Link, Text } from '../../atoms'
import {
  convertISOToFullDate,
  dollarFormat,
  getDayOfMonth,
  getDayOfWeek,
} from '../../utils/format'

const Row = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`

const Block = styled(Box)`
  flex: 0 0 50%;
  border-right: ${props =>
    props.divider ? `1px solid ${props.theme.colors.border}` : 0};
  padding: 12px 14px;
`

const RoutineProse = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
  text-align: center;
`

const ProseBlock = styled(Box)`
  margin: 5px 0;
`

const RoutineDetail = ({ id }) => {
  const dispatch = useDispatch()
  const routine = useSelector(state => routinesSelectors.selectById(state, id))
  const loading = useSelector(selectRoutinesLoading)
  const isRemoving = useSelector(selectIsRemovingRoutine)
  const sourceInstitution = useSelector(state => {
    if (!routine) return null
    return institutionsSelectors.selectById(state, routine.source_inst_item_id)
  })
  const sourceAccount = useSelector(state => {
    if (!routine) return null
    return accountsSelectors.selectById(state, routine.source)
  })
  const destinationInstitution = useSelector(state => {
    if (!routine) return null
    return institutionsSelectors.selectById(
      state,
      routine.destination_inst_item_id,
    )
  })
  const destinationAccount = useSelector(state => {
    if (!routine) return null
    return accountsSelectors.selectById(state, routine.destination)
  })
  const spendingAccount = useSelector(state => {
    if (!routine) return null
    return accountsSelectors.selectById(state, routine.spending)
  })
  const oAuthClient = useSelector(state => {
    if (!routine) return null
    return clientsSelectors.selectById(state, routine.oauth_client_id)
  })

  useEffect(() => {
    dispatch(getRoutines())
    dispatch(getInstitutions())
    dispatch(getAccounts())
  }, [dispatch])

  if (loading) return null

  if (!routine) return <NotFound />

  const getFrequency = frequency => {
    if (frequency === 'monthly') return 'Month'
    if (frequency === 'bi-weekly') return 'Two-weeks'
    if (frequency === 'daily') return 'Day'
    return 'Week'
  }

  const renderAccounts = () => {
    if (!sourceAccount && !destinationAccount) return null

    return (
      <Row flex>
        <Block>
          <Text color='textSubtle' size='xsmall'>
            Source Account
          </Text>
          {sourceAccount ? (
            <Link to={`/accounts/${sourceAccount.id}`}>
              <Box flex alignItems='center' marginTop={3}>
                {sourceInstitution && (
                  <Box marginRight={8}>
                    <InstitutionLogo
                      logo={sourceInstitution.logo}
                      name={sourceInstitution.name}
                    />
                  </Box>
                )}
                <Box>
                  <Text fontFamily='medium' lineHeight='1.1em'>
                    {routine.source_inst}
                  </Text>
                  <Text color='textSubtle' lineHeight='1.1em' size='xsmall'>
                    {sourceAccount.number}
                  </Text>
                </Box>
              </Box>
            </Link>
          ) : (
            <Text fontFamily='medium' size='small'>
              Not Found
            </Text>
          )}
        </Block>
        <Block>
          <Text color='textSubtle' size='xsmall'>
            Destination Account
          </Text>
          {destinationAccount ? (
            <Link to={`/accounts/${destinationAccount.id}`}>
              <Box flex alignItems='center' marginTop={3}>
                {destinationInstitution && (
                  <Box marginRight={8}>
                    <InstitutionLogo
                      logo={destinationInstitution.logo}
                      name={destinationInstitution.name}
                    />
                  </Box>
                )}
                <Box>
                  <Text fontFamily='medium' lineHeight='1.1em'>
                    {routine.destination_inst}
                  </Text>
                  <Text color='textSubtle' lineHeight='1.1em' size='xsmall'>
                    {destinationAccount.number}
                  </Text>
                </Box>
              </Box>
            </Link>
          ) : (
            <Text fontFamily='medium' size='small'>
              Not Found
            </Text>
          )}
        </Block>
      </Row>
    )
  }

  const renderRoutineProse = () => {
    if (!sourceAccount || !destinationAccount) return null

    switch (routine.type) {
      case 'one-time':
        return (
          <>
            <ProseBlock>
              <Text>
                Starting on{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {convertISOToFullDate(routine.start_date)}
                </Text>
                ,
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                transfer{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {dollarFormat(routine.amount)}
                </Text>
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                from{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {routine.source_inst} - {sourceAccount.number}
                </Text>
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                to{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {routine.destination_inst} - {destinationAccount.number}
                </Text>
              </Text>
            </ProseBlock>
          </>
        )
      case 'recurring':
        return (
          <>
            <ProseBlock>
              <Text>
                Starting on{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {convertISOToFullDate(routine.start_date)}
                </Text>
                ,
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                every{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {getFrequency(routine.frequency)}
                </Text>{' '}
                on{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {routine.frequency === 'monthly'
                    ? `the ${getDayOfMonth(routine.start_date)}`
                    : getDayOfWeek(routine.start_date)}
                </Text>
                ,
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                transfer{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {dollarFormat(routine.amount)}
                </Text>
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                from{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {routine.source_inst} - {sourceAccount.number}
                </Text>
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                to{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {routine.destination_inst} - {destinationAccount.number}
                </Text>
              </Text>
            </ProseBlock>
          </>
        )
      case 'sweep':
        return (
          <>
            <ProseBlock>
              <Text>
                Starting on{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {convertISOToFullDate(routine.start_date)}
                </Text>
                ,
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                every{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {getFrequency(routine.frequency)}
                </Text>{' '}
                on{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {routine.frequency === 'monthly'
                    ? `the ${getDayOfMonth(routine.start_date)}`
                    : getDayOfWeek(routine.start_date)}
                </Text>
                ,
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>transfer all funds</Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                over{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {dollarFormat(routine.min_balance)}
                </Text>
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                from{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {routine.source_inst} - {sourceAccount.number}
                </Text>
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                to{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {routine.destination_inst} - {destinationAccount.number}
                </Text>
              </Text>
            </ProseBlock>
          </>
        )
      case 'round-up':
        return (
          <>
            <ProseBlock>
              <Text>
                Starting on{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {convertISOToFullDate(routine.start_date)}
                </Text>
                ,
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text textAlign='center'>
                round up spending on{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {routine.spending_inst} - {spendingAccount.number}
                </Text>{' '}
                to the nearest dollar and
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                every{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  week
                </Text>{' '}
                on{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {getDayOfWeek(routine.start_date)}
                </Text>
                ,
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>transfer the sum of those round ups</Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                from{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {routine.source_inst} - {sourceAccount.number}
                </Text>
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                to{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {routine.destination_inst} - {destinationAccount.number}
                </Text>
              </Text>
            </ProseBlock>
          </>
        )
      case 'percentage-based':
        return (
          <>
            <ProseBlock>
              <Text>
                Starting on{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {convertISOToFullDate(routine.start_date)}
                </Text>
                ,
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                for deposits over{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {dollarFormat(routine.minimum_transaction_threshold)}
                </Text>
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                into{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {routine.source_inst} - {sourceAccount.number}
                </Text>
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                transfer{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {routine.percent_of_transaction}%
                </Text>
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>of the deposit</Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                to{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {routine.destination_inst} - {destinationAccount.number}
                </Text>
              </Text>
            </ProseBlock>
          </>
        )
      case 'percentage-balance':
        return (
          <>
            <ProseBlock>
              <Text>
                Starting on{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {convertISOToFullDate(routine.start_date)}
                </Text>
                ,
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                every{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {getFrequency(routine.frequency)}
                </Text>{' '}
                on{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {routine.frequency === 'monthly'
                    ? `the ${getDayOfMonth(routine.start_date)}`
                    : getDayOfWeek(routine.start_date)}
                </Text>
                ,
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                transfer{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {routine.percent_of_balance}%
                </Text>
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>of the balance</Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                from{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {routine.source_inst} - {sourceAccount.number}
                </Text>
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                to{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {routine.destination_inst} - {destinationAccount.number}
                </Text>
              </Text>
            </ProseBlock>
          </>
        )
      case 'fifty-two-week':
        return (
          <>
            <ProseBlock>
              <Text>For 52 weeks,</Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                starting on{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {convertISOToFullDate(routine.start_date)}
                </Text>
                ,
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                every week on{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {getDayOfWeek(routine.start_date)}
                </Text>
                ,
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                transfer{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {dollarFormat(routine.amount_increment)}
                </Text>{' '}
                week 1,
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                then{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {dollarFormat(routine.amount_increment * 2)}
                </Text>{' '}
                week 2, and so on,
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                from{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {routine.source_inst} - {sourceAccount.number}
                </Text>
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                to{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {routine.destination_inst} - {destinationAccount.number}
                </Text>
              </Text>
            </ProseBlock>
          </>
        )
      case 'refill':
        return (
          <>
            <ProseBlock>
              <Text>
                Starting on{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {convertISOToFullDate(routine.start_date)}
                </Text>
                ,
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>when the balance on</Text>
            </ProseBlock>
            <ProseBlock>
              <Text color='primary' display='inline' fontFamily='heavy'>
                {routine.destination_inst} - {destinationAccount.number}
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                goes below{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {dollarFormat(routine.threshold)}
                </Text>
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                transfer in{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {dollarFormat(routine.amount)}
                </Text>
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                from{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {routine.source_inst} - {sourceAccount.number}
                </Text>
              </Text>
            </ProseBlock>
          </>
        )
      case 'threshold-notification':
        return (
          <>
            <ProseBlock>
              <Text>
                Starting on{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {convertISOToFullDate(routine.start_date)}
                </Text>
                ,
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                when my{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {routine.source_inst} - {sourceAccount.number}
                </Text>{' '}
                balance
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                {sourceAccount.type === 'loan' ||
                sourceAccount.type === 'credit'
                  ? 'is above'
                  : 'is below'}{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {dollarFormat(routine.threshold)}
                </Text>
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                send me a{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {routine.delivery_method === 'sms' ? 'SMS' : 'Push'}
                </Text>{' '}
                notification
              </Text>
            </ProseBlock>
          </>
        )
      case 'balance-notification':
        return (
          <>
            <ProseBlock>
              <Text>
                Starting on{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {convertISOToFullDate(routine.start_date)}
                </Text>
                ,
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                send my{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {routine.source_inst} - {sourceAccount.number}
                </Text>{' '}
                balance
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                every{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {getFrequency(routine.frequency)}
                </Text>
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                via{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {routine.delivery_method === 'sms' ? 'SMS' : 'Push'}
                </Text>{' '}
                notification
              </Text>
            </ProseBlock>
          </>
        )
      case 'income-notification':
        return (
          <>
            <ProseBlock>
              <Text>
                Starting on{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {convertISOToFullDate(routine.start_date)}
                </Text>
                ,
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                when a deposit over{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {dollarFormat(routine.minimum_transaction_threshold)}
                </Text>{' '}
                posts to
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text color='primary' display='inline' fontFamily='heavy'>
                {routine.source_inst} - {sourceAccount.number}
              </Text>
            </ProseBlock>
            <ProseBlock>
              <Text>
                send me a{' '}
                <Text color='primary' display='inline' fontFamily='heavy'>
                  {routine.delivery_method === 'sms' ? 'SMS' : 'Push'}
                </Text>{' '}
                notification
              </Text>
            </ProseBlock>
          </>
        )
      default:
        return null
    }
  }

  return (
    <Box width='100%'>
      <Box paddingHorizontal={10}>
        <Box flex alignItems='center'>
          <Box flexGrow={1}>
            <Text fontFamily='heavy' size='large' textAlign='left'>
              {routine.name}
            </Text>
          </Box>
          <DropdownMenu
            button={
              <ion-icon name='ellipsis-horizontal' style={{ fontSize: 25 }} />
            }
          >
            <DropdownMenu.Item
              onClick={() => {
                const editedRoutine = {
                  ...routine,
                  active: !routine.active,
                }
                dispatch(editRoutine({ routine: editedRoutine }))
              }}
              name={routine.active ? 'Pause' : 'Activate'}
            />
            <DropdownMenu.Item
              onClick={() => {
                dispatch(removeRoutine({ id }))
                  .then(unwrapResult)
                  .then(() => {
                    navigate('/routines')
                  })
              }}
              name={isRemoving ? 'Removing...' : 'Remove'}
              isDangerous
            />
          </DropdownMenu>
        </Box>
        <Box>
          <RoutineStatus status={routine.status} />
        </Box>
      </Box>
      <Container padding={0} marginTop={20}>
        {(oAuthClient || routine) && (
          <Row>
            <Block
              flex
              alignItems='center'
              justifyContent='space-between'
              paddingVertical={5}
            >
              <Text color='textSubtle' size='xsmall'>
                {oAuthClient ? oAuthClient.name : ''}
              </Text>
              <Text color='textSubtle' size='xsmall'>
                {routine ? routine.friendly_name : ''}
              </Text>
            </Block>
          </Row>
        )}
        {renderAccounts()}
        <RoutineProse>{renderRoutineProse()}</RoutineProse>
      </Container>
      <ClientAppModule clientApp={oAuthClient} />
    </Box>
  )
}

export default RoutineDetail
