import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { navigate } from '@reach/router'
import { Box, Composition } from 'atomic-layout'
import { selectOAuthClientsLoading, clientsSelectors } from './clientsSlice'
import { Container, Link, Text } from '../../atoms'
import AccordionContent from '../../components/AccordionContent'
import AccordionIcon from '../../components/AccordionIcon'
import Count from '../../components/Count'
import ClientAppRow from './ClientAppRow'

const areasMobile = `
  header
  list
  footer
`
const listSize = 4

const ClientAppsOverview = () => {
  const clientApps = useSelector(clientsSelectors.selectAll)
  const loading = useSelector(selectOAuthClientsLoading)
  const [isOpen, setIsOpen] = useState(true)

  useEffect(() => {
    setIsOpen(clientApps.length !== 0)
  }, [clientApps])

  if (!clientApps || clientApps.length === 0) return null

  return (
    <Composition areas={areasMobile} as={Container} paddingHorizontal={0}>
      {Areas => (
        <>
          <Areas.Header flex alignItems='center' paddingHorizontal={18}>
            <Text
              cursor='pointer'
              fontFamily='heavy'
              size='large'
              onClick={() => navigate('/apps')}
            >
              Apps
            </Text>
            <Box marginLeft={10}>
              <Count
                count={clientApps.length}
                onClick={() => navigate('/apps')}
                loading={loading}
              />
            </Box>
            <Box
              flex
              flexGrow={1}
              justifyContent='flex-end'
              onClick={() => setIsOpen(!isOpen)}
            >
              <AccordionIcon isOpen={isOpen} />
            </Box>
          </Areas.Header>
          <AccordionContent isOpen={isOpen}>
            <>
              <Areas.List marginTop={20} marginBottom={10}>
                {clientApps &&
                  clientApps
                    .slice(0, listSize)
                    .map(clientApp => (
                      <ClientAppRow
                        key={clientApp.client_id}
                        clientApp={clientApp}
                      />
                    ))}
              </Areas.List>
              {clientApps && clientApps.length > listSize && (
                <Areas.Footer flex alignItems='center' justifyContent='center'>
                  <Link to='/apps' size='small'>
                    More
                  </Link>
                </Areas.Footer>
              )}
            </>
          </AccordionContent>
        </>
      )}
    </Composition>
  )
}

export default ClientAppsOverview
