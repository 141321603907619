import React from 'react'
import styled from 'styled-components'
import { Flex } from '@chakra-ui/react'
import { IonIcon, Text } from '../atoms'

const Circle = styled(Flex)`
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  min-width: 20px;
`

interface IconBulletPointProps {
  title: string
  subtitle: string
  name: string
}

const IconBulletPoint: React.FC<IconBulletPointProps> = ({
  title,
  subtitle,
  name = 'checkmark-outline',
}) => (
  <Flex alignItems='center'>
    <Circle>
      <IonIcon name={name} color='primary' size={30} />
    </Circle>
    <Flex marginLeft={5} flexDirection='column'>
      <Text fontFamily='medium'>{title}</Text>
      <Text size='small'>{subtitle}</Text>
    </Flex>
  </Flex>
)

export default IconBulletPoint
