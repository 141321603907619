import React from 'react'
import { useSelector } from 'react-redux'
import { Composition } from 'atomic-layout'

import PhoneNumberDisplay from '../../components/PhoneNumberDisplay'
import { Container, Text } from '../../atoms'
import { selectUser } from './authSlice'

const areasMobile = `
  title phone
`

const AuthenticatedUser: React.FC = () => {
  const user = useSelector(selectUser)

  return (
    <Composition
      areas={areasMobile}
      as={Container}
      gap={3}
      width='100%'
      alignItems='center'
      justifyContent='space-between'
    >
      {Areas => (
        <>
          <Areas.Title>
            <Text fontFamily='heavy' size='large'>
              Hi, {user.firstName || 'there'}!
            </Text>
          </Areas.Title>
          <Areas.Phone>
            <PhoneNumberDisplay phoneNumber={user.phone} />
          </Areas.Phone>
        </>
      )}
    </Composition>
  )
}

export default AuthenticatedUser
