import React from 'react'
import styled from 'styled-components'
import { Box } from 'atomic-layout'
import Spinner from './Spinner'

const Container = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const FullScreenSpinner: React.FC = () => (
  <Container flex alignItems='center' justifyContent='center'>
    <Spinner size='medium' />
  </Container>
)

export default FullScreenSpinner
