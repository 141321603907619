import styled from 'styled-components'
import { Link } from '@reach/router'
import { astraTheme } from '../styles/theme'
import { ReactNode } from 'react'

interface LinkProps {
  children?: ReactNode
  href?: string
  color?: keyof typeof astraTheme.colors
  disabled?: boolean
  fontFamily?: keyof typeof astraTheme.fonts
  size?: keyof typeof astraTheme.text
}

export const InternalLink = styled(Link)<LinkProps>`
  color: ${props => props.theme.colors[props.color || 'primary']};
  cursor: pointer;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  font-family: ${props => props.theme.fonts[props.fontFamily || 'medium']};
  font-size: ${props => props.theme.text[props.size || 'medium'].size};
  line-height: ${props => props.theme.text[props.size || 'medium'].height};
  text-align: left;
  text-decoration: none;
`

export const ExternalLink = styled.a<LinkProps>`
  color: ${props => props.theme.colors[props.color || 'primary']};
  cursor: pointer;
  font-family: ${props => props.theme.fonts[props.fontFamily || 'medium']};
  font-size: ${props => props.theme.text[props.size || 'medium'].size};
  line-height: ${props => props.theme.text[props.size || 'medium'].height};
  text-decoration: underline;
`
