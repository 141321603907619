import React from 'react'
import { useSelector } from 'react-redux'
import { Composition } from 'atomic-layout'
import { useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'

import DateOfBirthField from 'src/components/DateOfBirthField'
import SocialSecurityField from 'src/components/SocialSecurityField'
import { Button, Container, Input, Text } from 'src/atoms'
import { selectUser } from 'src/features/auth/authSlice'
import { selectVerifyPersonal } from 'src/features/verify/verifySlice'
import { convertDateFromISO, convertISOToShortDate } from 'src/utils/format'

interface VerifyPersonalInfoProps {
  submit: any
  completed: boolean
  edit: () => void
}

const areasMobile = `
  title
  subtitle
  form
`

const formAreasMobile = `
  legalFirstName
  legalLastName
  dateOfBirth
  socialSecurityNumber
  continue
`

const completedAreasMobile = `
  legalName edit
  dateOfBirth edit
  socialSecurityNumber edit
`

const VerifyPersonalInfo: React.FC<VerifyPersonalInfoProps> = ({
  submit,
  completed,
  edit,
}) => {
  const {
    handleSubmit,
    formState: { errors, isValid },
    register,
    control,
  } = useForm({ mode: 'onChange' })
  const { dateOfBirth, legalFirstName, legalLastName, ssn } = useSelector(
    selectVerifyPersonal,
  )
  const user = useSelector(selectUser)
  const dateOfBirthFormatted = dateOfBirth
    ? convertISOToShortDate(dateOfBirth)
    : ''
  const defaultDateOfBirth = dateOfBirth ? convertDateFromISO(dateOfBirth) : ''

  if (completed) {
    return (
      <Composition
        areas={completedAreasMobile}
        as={Container}
        gap={3}
        alignItems='center'
        justifyContent='space-between'
      >
        {Areas => (
          <>
            <Areas.LegalName>
              <Text fontFamily='medium' color='textSubtle'>
                {legalFirstName} {legalLastName}
              </Text>
            </Areas.LegalName>
            <Areas.DateOfBirth>
              <Text fontFamily='medium' color='textSubtle'>
                {dateOfBirthFormatted}
              </Text>
            </Areas.DateOfBirth>
            {ssn && (
              <Areas.SocialSecurityNumber>
                <NumberFormat
                  value={user.status === 'retry' ? ssn.substring(5, 9) : ssn}
                  displayType='text'
                  format={user.status === 'retry' ? '•••-••-####' : '####'}
                  renderText={value => (
                    <Text fontFamily='medium' color='textSubtle'>
                      {value}
                    </Text>
                  )}
                />
              </Areas.SocialSecurityNumber>
            )}
            <Areas.Edit>
              <Text
                onClick={edit}
                color='primary'
                cursor='pointer'
                fontFamily='medium'
              >
                Edit
              </Text>
            </Areas.Edit>
          </>
        )}
      </Composition>
    )
  }

  return (
    <Composition areas={areasMobile} as={Container}>
      {Areas => (
        <>
          <Areas.Title marginBottom={3}>
            <Text fontFamily='heavy' size='large'>
              Verify your Profile
            </Text>
          </Areas.Title>
          <Areas.Subtitle marginBottom={15}>
            <Text fontFamily='medium' size='small' color='textSubtle'>
              {user.status === 'retry'
                ? 'Re-enter your personal details.'
                : 'Enter your personal details.'}
            </Text>
          </Areas.Subtitle>
          <Areas.Form>
            <form
              onSubmit={e => {
                e.preventDefault()
                handleSubmit(submit)(e)
              }}
            >
              <Composition areas={formAreasMobile} gap={10}>
                {FormAreas => (
                  <>
                    <FormAreas.LegalFirstName>
                      <Input
                        id='legalFirstName'
                        type='text'
                        placeholder='Legal First Name'
                        defaultValue={legalFirstName || ''}
                        bordercolor={errors.legalFirstName && 'error'}
                        {...register('legalFirstName', { required: true })}
                      />
                    </FormAreas.LegalFirstName>
                    <FormAreas.LegalLastName>
                      <Input
                        id='legalLastName'
                        type='text'
                        placeholder='Legal Last Name'
                        defaultValue={legalLastName || ''}
                        bordercolor={errors.legalLastName && 'error'}
                        {...register('legalLastName', { required: true })}
                      />
                    </FormAreas.LegalLastName>
                    <FormAreas.DateOfBirth>
                      <DateOfBirthField
                        id='dateOfBirth'
                        defaultValue={defaultDateOfBirth}
                        error={errors.dateOfBirth as any}
                        control={control}
                        {...register('dateOfBirth', { required: true })}
                      />
                    </FormAreas.DateOfBirth>
                    <FormAreas.SocialSecurityNumber>
                      <SocialSecurityField
                        id='ssn'
                        requireFullNumber={user.status === 'retry'}
                        defaultValue={ssn || ''}
                        error={errors.ssn as any}
                        control={control}
                        {...register('ssn', { required: true })}
                      />
                    </FormAreas.SocialSecurityNumber>
                    <FormAreas.Continue>
                      <Button type='submit' disabled={!isValid} fullWidth>
                        Continue
                      </Button>
                    </FormAreas.Continue>
                  </>
                )}
              </Composition>
            </form>
          </Areas.Form>
        </>
      )}
    </Composition>
  )
}

export default VerifyPersonalInfo
