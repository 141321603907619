import React from 'react'
import { useSelector } from 'react-redux'
import { Box, Composition } from 'atomic-layout'
import { useLocation } from '@reach/router'
import * as queryString from 'query-string'

import {
  selectClient,
  selectIsLoggingInWithCustomToken,
  selectIsSendingAuthCode,
  selectUser,
} from 'src/features/auth/authSlice'

import useLoginForm from 'src/features/auth/useLoginForm'

import { Button, Container, Text } from 'src/atoms'
import AuthCodeField from 'src/components/AuthCodeField'
import AuthHeader from 'src/features/auth/AuthHeader'
import IconBulletPoint from 'src/components/IconBulletPoint'
import PhoneNumberDisplay from 'src/components/PhoneNumberDisplay'
import PhoneNumberField from 'src/components/PhoneNumberField'
import Spinner from 'src/components/Spinner'
import { clearPhoneNumberFormatting } from 'src/utils/format'
import FullScreenSpinner from 'src/components/FullScreenSpinner'

const areasMobile = `
  header
  content
  phone
  actions
`

const LoginModule: React.FC = () => {
  const location = useLocation()
  const { phone, phone_read_only: phoneReadOnly } = queryString.parse(
    location.search,
  )
  const isSendingAuthCode = useSelector(selectIsSendingAuthCode)

  const {
    authConfirmationResult,
    errors,
    form: { control, register, watch },
    isSubmitDisabled,
    onSubmitPhoneNumber,
    onSubmitAuthCode,
  } = useLoginForm()

  const isLoggingInWithCustomToken = useSelector(
    selectIsLoggingInWithCustomToken,
  )
  const client = useSelector(selectClient)
  const user = useSelector(selectUser)
  const isPhoneValid = phone?.length === 10
  const phoneFormFieldValue = watch('phoneNumber')

  if (isLoggingInWithCustomToken) {
    return <FullScreenSpinner />
  }

  return (
    <Composition
      areas={areasMobile}
      as={Container}
      paddingTop={30}
      data-testid='login'
    >
      {({ Header, Content, Phone, Actions }) => (
        <>
          {client?.name && (
            <Header marginBottom={20}>
              <AuthHeader name={client.name} />
            </Header>
          )}
          <Content
            as={Composition}
            gap={20}
            paddingHorizontal={10}
            marginBottom={30}
          >
            <IconBulletPoint
              title='Profile Verification'
              subtitle='Let’s confirm your identity with a one-time code to your mobile number.'
              name='finger-print-outline'
            />
          </Content>
          <Phone marginBottom={20}>
            {authConfirmationResult ? (
              <>
                <Text fontFamily='medium' size='small' color='textSubtle'>
                  Enter the code sent to your mobile number.
                </Text>
                <PhoneNumberDisplay phoneNumber={user.phone} size='small' />
              </>
            ) : (
              <PhoneNumberField
                id='phoneNumber'
                placeholder='Phone Number'
                readOnly={isPhoneValid && phoneReadOnly === 'true'}
                error={errors.phoneNumber as any}
                control={control}
                {...register('phoneNumber', { required: true })}
              />
            )}
          </Phone>
          <Actions>
            {authConfirmationResult ? (
              <>
                <AuthCodeField
                  name='authCode'
                  control={control}
                  onSubmit={onSubmitAuthCode}
                  readOnly={isSendingAuthCode}
                />
                {isSendingAuthCode && (
                  <Box flex justifyContent='center' marginTop={15}>
                    <Spinner size='small' />
                  </Box>
                )}
              </>
            ) : (
              <Button
                id='auth-button'
                type='submit'
                disabled={isSendingAuthCode || isSubmitDisabled}
                fullWidth
                onClick={async e => {
                  e.preventDefault()

                  await onSubmitPhoneNumber(
                    clearPhoneNumberFormatting(phoneFormFieldValue),
                  )
                }}
              >
                {isSendingAuthCode ? 'Sending...' : 'Continue'}
              </Button>
            )}
          </Actions>
        </>
      )}
    </Composition>
  )
}

export default LoginModule
