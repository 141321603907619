import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
import { RootStateOrAny } from 'react-redux'

export const ASTRA_SDK_NAMESPACE = 'ASTRA_SDK_'

enum ACTION_TYPES {
  opened = 'OPENED',
  initialized = 'INITIALIZED',
  closed = 'CLOSED',
  error = 'ERROR',
  linkOpened = 'LINK_OPENED',
  linkClosed = 'LINK_CLOSED',
  authorized = 'AUTHORIZED',
}

interface SdkMessage {
  type: string
  data?: { [key: string]: any } | string
}

interface AuthorizationMessage {
  code: string
  oAuthState?: string
}

interface ErrorMessage {
  error: Error
}

const sdkMessagingAdapter = createEntityAdapter()

export const sendMessage = createAsyncThunk(
  'sdkMessaging/sendMessage',
  async ({ type, data = {} }: SdkMessage, { getState }) => {
    const {
      sdkMessaging: { iframeOrigin },
    } = getState() as { sdkMessaging: RootStateOrAny }

    if (iframeOrigin) {
      const action = `${ASTRA_SDK_NAMESPACE}${type}`

      window.parent.postMessage(JSON.stringify({ action, data }), iframeOrigin)

      return type
    }
  },
)

export const sendAuthorizedMessage = createAsyncThunk(
  'sdkMessaging/sendAuthorized',
  async ({ code, oAuthState }: AuthorizationMessage, { dispatch }) => {
    dispatch(
      sendMessage({
        type: ACTION_TYPES.authorized,
        data: { code, oAuthState },
      }),
    )
  },
)

export const sendClosedMessage = createAsyncThunk(
  'sdkMessaging/sendClosed',
  async (empty, { dispatch }) => {
    dispatch(sendMessage({ type: ACTION_TYPES.closed }))
  },
)

export const sendErrorMessage = createAsyncThunk(
  'sdkMessaging/sendError',
  async ({ error }: ErrorMessage, { dispatch }) => {
    dispatch(
      sendMessage({
        type: ACTION_TYPES.error,
        data: error.toString(),
      }),
    )
  },
)

export const sendInitializedMessage = createAsyncThunk(
  'sdkMessaging/sendInitialized',
  async (empty, { dispatch }) => {
    dispatch(sendMessage({ type: ACTION_TYPES.initialized }))
  },
)

export const sendLinkClosedMessage = createAsyncThunk(
  'sdkMessaging/sendLinkClosed',
  async (empty, { dispatch }) => {
    dispatch(sendMessage({ type: ACTION_TYPES.linkClosed }))
  },
)

export const sendLinkOpenedMessage = createAsyncThunk(
  'sdkMessaging/sendLinkOpened',
  async (empty, { dispatch }) => {
    dispatch(sendMessage({ type: ACTION_TYPES.linkOpened }))
  },
)

export const addSdkListener = createAsyncThunk(
  'sdkMessaging/addListener',
  async (empty, { dispatch }) => {
    window.addEventListener('message', function sdkMessagesHandler(event) {
      if (typeof event.data === 'string') {
        try {
          const { action } = JSON.parse(event.data)

          if (
            typeof action === 'string' &&
            action.includes(ASTRA_SDK_NAMESPACE)
          ) {
            const actionType = action.split(ASTRA_SDK_NAMESPACE)[1]

            switch (actionType) {
              case ACTION_TYPES.closed: {
                dispatch(sendClosedMessage())
                this.removeEventListener('message', sdkMessagesHandler)
                break
              }
              case ACTION_TYPES.opened: {
                dispatch(setIframeOrigin(event.origin))
                dispatch(sendInitializedMessage())
                break
              }
              default:
                break
            }
          }
        } catch (error) {}
      }
    })
  },
)

const sdkMessagingSlice = createSlice({
  name: 'sdkMessaging',
  initialState: sdkMessagingAdapter.getInitialState({
    iframeOrigin: null,
  }),
  reducers: {
    setIframeOrigin: (state, action) => {
      if (action.payload) {
        state.iframeOrigin = action.payload
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(sendMessage.fulfilled, (state, action) => {
      if (action.payload === ACTION_TYPES.closed) state.iframeOrigin = null
    })
  },
})

export const { setIframeOrigin } = sdkMessagingSlice.actions

export const selectIframeOrigin = (state: RootStateOrAny): string =>
  state.sdkMessaging.iframeOrigin

export default sdkMessagingSlice.reducer
