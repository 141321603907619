import React from 'react'
import { Composition } from 'atomic-layout'
import { Control } from 'react-hook-form'

import stateOptions from 'src/utils/stateOptions'

import { Input } from 'src/atoms'
import SelectField from 'src/components/SelectField'
import ZipcodeField from 'src/features/verify/business/BusinessAddress/ZipcodeField'
import { Address } from 'src/features/verify/types'

enum AddressFormKeys {
  ADDRESS_1 = 'address1',
  ADDRESS_2 = 'address2',
  CITY = 'city',
  STATE = 'state',
  ZIP = 'zip',
}

interface AddressFormProps {
  defaultValues: Address
  register: any
  control: Control<any, any>
  errors: any
}

const formAreasMobile = `
  address1
  address2
  city
  stateAndZip
`

/**
 * A React.FC which renders the `AddressForm` component.
 *
 * This is a generic address form to be used in parent forms where address is needed.
 */
const AddressForm: React.FC<AddressFormProps> = ({
  defaultValues,
  register,
  control,
  errors,
}) => (
  <Composition areas={formAreasMobile} gap={10}>
    {FormAreas => (
      <>
        <FormAreas.Address1>
          <Input
            id={AddressFormKeys.ADDRESS_1}
            type='text'
            placeholder='Street Address'
            defaultValue={defaultValues[AddressFormKeys.ADDRESS_1]}
            bordercolor={errors[AddressFormKeys.ADDRESS_1] && 'error'}
            {...register(AddressFormKeys.ADDRESS_1, {
              required: true,
            })}
          />
        </FormAreas.Address1>
        <FormAreas.Address2>
          <Input
            id={AddressFormKeys.ADDRESS_2}
            type='text'
            placeholder='Apartment, unit, etc. (Optional)'
            defaultValue={defaultValues[AddressFormKeys.ADDRESS_2]}
            bordercolor={errors[AddressFormKeys.ADDRESS_2] && 'error'}
            {...register(AddressFormKeys.ADDRESS_2, {
              required: false,
            })}
          />
        </FormAreas.Address2>
        <FormAreas.City>
          <Input
            id={AddressFormKeys.CITY}
            type='text'
            placeholder='City'
            defaultValue={defaultValues[AddressFormKeys.CITY]}
            bordercolor={errors[AddressFormKeys.CITY] && 'error'}
            {...register(AddressFormKeys.CITY, {
              required: true,
            })}
          />
        </FormAreas.City>
        <FormAreas.StateAndZip>
          <Composition areas='state zip' gap={10}>
            {SubAreas => (
              <>
                <SubAreas.State>
                  <SelectField
                    id={AddressFormKeys.STATE}
                    control={control}
                    defaultValue={defaultValues[AddressFormKeys.STATE]}
                    error={errors[AddressFormKeys.STATE]}
                    options={stateOptions}
                    {...register(AddressFormKeys.STATE, {
                      validate: {
                        stateRequired: (value: string) => {
                          if (value === '0') return 'required'
                          return undefined
                        },
                      },
                    })}
                    style={{ width: '10rem' }}
                  />
                </SubAreas.State>
                <SubAreas.Zip>
                  <ZipcodeField
                    id={AddressFormKeys.ZIP}
                    control={control}
                    placeholder='Zip Code'
                    defaultValue={defaultValues[AddressFormKeys.ZIP] as string}
                    error={errors[AddressFormKeys.ZIP]}
                    {...register(AddressFormKeys.ZIP, {
                      required: true,
                    })}
                  />
                </SubAreas.Zip>
              </>
            )}
          </Composition>
        </FormAreas.StateAndZip>
      </>
    )}
  </Composition>
)

export default AddressForm
