import { dollarFormat } from './format'

// show current balance for credit cards
// show available balance for all other account types
export const getAccountPrimaryBalance = account => {
  if (
    (account.available_balance || account.available_balance === 0) &&
    account.subtype !== 'credit card'
  ) {
    return dollarFormat(account.available_balance)
  }
  if (account.current_balance || account.current_balance === 0) {
    return dollarFormat(account.current_balance)
  }
  return ' – '
}
