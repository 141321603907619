import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Composition } from 'atomic-layout'
import { useForm } from 'react-hook-form'

import BeneficialOwner from './BeneficialOwner'
import CheckboxField from 'src/components/CheckboxField'
import { Button, ButtonSecondary, Container, Text } from 'src/atoms'
import {
  beneficialOwnersSelectors,
  selectBusinessAdmin,
  verifyBusiness,
  finishVerifyBusinessProcess,
} from 'src/features/verify/verifySlice'

import { useAppDispatch } from 'src/redux/store'

const BeneficialOwners: React.FC = () => {
  const dispatch = useAppDispatch()
  const {
    register,
    formState: { isValid },
  } = useForm({ mode: 'onChange' })
  const [readyToSubmit, setReadyToSubmit] = useState(false)
  const [requireBeneficialOwners, setRequireBeneficialOwners] = useState<
    'true' | 'false' | 'unanswered'
  >('unanswered')
  const [showAddForm, setShowAddForm] = useState(false)
  const beneficialOwners = useSelector(
    beneficialOwnersSelectors.selectAll,
  ) as any

  const { firstName, lastName } = useSelector(selectBusinessAdmin)

  useEffect(() => {
    const beneficialOwnerExists =
      beneficialOwners && beneficialOwners.length > 0
    setReadyToSubmit(!!beneficialOwnerExists)
    if (beneficialOwnerExists) setRequireBeneficialOwners('true')
  }, [beneficialOwners])

  const onSubmit = () => {
    dispatch(verifyBusiness())
    dispatch(finishVerifyBusinessProcess())
  }

  const renderQuestion = () => (
    <Composition
      areas={`
        title
        subtitle
        buttons
      `}
      as={Container}
    >
      {Areas => (
        <>
          <Areas.Title marginBottom={3}>
            <Text fontFamily='heavy' size='large'>
              Beneficial Owners
            </Text>
          </Areas.Title>
          <Areas.Subtitle marginBottom={15}>
            <Text fontFamily='medium' size='small' color='textSubtle'>
              Do any individuals own 25% or more of your business?
            </Text>
          </Areas.Subtitle>
          <Areas.Buttons flex gap={10}>
            <ButtonSecondary
              onClick={() => {
                setRequireBeneficialOwners('true')
                setReadyToSubmit(false)
              }}
              active={requireBeneficialOwners === 'true'}
              size='small'
              fullWidth
            >
              Yes
            </ButtonSecondary>
            <ButtonSecondary
              onClick={() => {
                setRequireBeneficialOwners('false')
                setReadyToSubmit(true)
              }}
              active={requireBeneficialOwners === 'false'}
              size='small'
              fullWidth
            >
              No
            </ButtonSecondary>
          </Areas.Buttons>
        </>
      )}
    </Composition>
  )

  const renderBeneficialOwners = () => {
    if (beneficialOwners && beneficialOwners.length > 0) {
      return (
        <>
          {beneficialOwners.map((beneficialOwner: any) => (
            <BeneficialOwner
              key={beneficialOwner.id}
              beneficialOwner={beneficialOwner}
            />
          ))}
          {showAddForm && (
            <BeneficialOwner onAdd={() => setShowAddForm(false)} />
          )}
          <Box width='100%' marginBottom={25}>
            <ButtonSecondary
              active
              onClick={() => setShowAddForm(true)}
              fullWidth
            >
              Add Another Beneficial Owner
            </ButtonSecondary>
          </Box>
        </>
      )
    }

    return <BeneficialOwner />
  }

  const renderSubmit = () => {
    return (
      <Composition
        areas={`
          title
          form
        `}
        as={Container}
        gap={10}
      >
        {Areas => (
          <>
            <Areas.Title marginBottom={3}>
              <Text fontFamily='heavy' size='large'>
                Certify
              </Text>
            </Areas.Title>
            <Areas.Form>
              <Composition
                areas={`
                    checkbox
                    button
                  `}
                gap={10}
              >
                {FormAreas => (
                  <>
                    <FormAreas.Checkbox>
                      <CheckboxField
                        id='certify'
                        {...register('certify', { required: true })}
                      >
                        <Text size='xsmall'>
                          I, {firstName} {lastName}, hereby certify, to the best
                          of my knowledge, that the information provided above
                          is complete and correct.
                        </Text>
                      </CheckboxField>
                    </FormAreas.Checkbox>
                    <FormAreas.Button>
                      <Button
                        type='button'
                        disabled={!isValid}
                        fullWidth
                        onClick={onSubmit}
                      >
                        Submit
                      </Button>
                    </FormAreas.Button>
                  </>
                )}
              </Composition>
            </Areas.Form>
          </>
        )}
      </Composition>
    )
  }

  return (
    <>
      {renderQuestion()}
      {requireBeneficialOwners === 'true' && renderBeneficialOwners()}
      {readyToSubmit && renderSubmit()}
    </>
  )
}

export default BeneficialOwners
