import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { navigate } from '@reach/router'
import { Box, Composition } from 'atomic-layout'
import { selectRoutinesLoading, routinesSelectors } from './routinesSlice'
import { Container, Link, Text } from '../../atoms'
import AccordionContent from '../../components/AccordionContent'
import AccordionIcon from '../../components/AccordionIcon'
import Count from '../../components/Count'
import RoutineRow from './RoutineRow'

const areasMobile = `
  header
  list
  footer
`
const listSize = 4

const RoutinesOverview = () => {
  const routines = useSelector(routinesSelectors.selectAll)
  const loading = useSelector(selectRoutinesLoading)
  const [isOpen, setIsOpen] = useState(true)

  useEffect(() => {
    setIsOpen(routines.length !== 0)
  }, [routines])

  return (
    <Composition areas={areasMobile} as={Container} paddingHorizontal={0}>
      {Areas => (
        <>
          <Areas.Header flex alignItems='center' paddingHorizontal={18}>
            <Text
              cursor='pointer'
              fontFamily='heavy'
              size='large'
              onClick={() => navigate('/routines')}
            >
              Routines
            </Text>
            <Box marginLeft={10}>
              <Count
                count={routines.length}
                onClick={() => navigate('/routines')}
                loading={loading}
              />
            </Box>
            <Box
              flex
              flexGrow={1}
              justifyContent='flex-end'
              onClick={() => setIsOpen(!isOpen)}
            >
              <AccordionIcon isOpen={isOpen} />
            </Box>
          </Areas.Header>
          <AccordionContent isOpen={isOpen}>
            <>
              <Areas.List marginTop={20} marginBottom={10}>
                {routines &&
                  routines
                    .slice(0, listSize)
                    .map(routine => (
                      <RoutineRow key={routine.id} routine={routine} />
                    ))}
              </Areas.List>
              {routines && routines.length > listSize && (
                <Areas.Footer flex alignItems='center' justifyContent='center'>
                  <Link to='/routines' size='small'>
                    More
                  </Link>
                </Areas.Footer>
              )}
            </>
          </AccordionContent>
        </>
      )}
    </Composition>
  )
}

export default RoutinesOverview
