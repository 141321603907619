import styled, { css } from 'styled-components'
import { astraTheme, CustomThemeType } from '../styles/theme'

interface SizeProps {
  size?: keyof typeof astraTheme.button
  theme: CustomThemeType
}
const sizeStyle = ({ size = 'medium', theme }: SizeProps) => {
  const data = theme.button[size]
  const text = theme.text[data.text as keyof typeof astraTheme.text]

  return css`
    padding: ${data.pad.vertical} ${data.pad.horizontal};
    font-size: ${text.size};
    line-height: ${text.height};
  `
}

interface ButtonProps {
  backgroundColor?: keyof typeof astraTheme.colors
  color?: keyof typeof astraTheme.colors
  display?: string
  fullWidth?: boolean
  size?: keyof typeof astraTheme.button
}
const Button = styled.button<ButtonProps>`
  background-color: ${props =>
    props.theme.colors[props.backgroundColor || 'primary']};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 4px;
  color: ${props => props.theme.colors[props.color || 'textInverse']};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  display: ${props => props.display || 'block'};
  font-family: ${({ theme }) => theme.fonts.medium};
  outline: 0;
  opacity: ${props => (props.disabled ? '.6' : '1')};
  text-align: center;
  text-decoration: none;
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  ${props => sizeStyle(props)}

  &:hover {
    opacity: ${props => (props.disabled ? '.6' : '.9')};
  }
`

export default Button
