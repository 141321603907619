import React from 'react'
import styled from 'styled-components'
import { Box } from 'atomic-layout'

import { Icon } from '../atoms'
import { getRoutineIconData } from '../utils/routines'

const Circle = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.accent};
  border-radius: 22px;
  height: 44px;
  width: 44px;
`

const RoutineIcon: React.FC<{ routineType: string }> = ({ routineType }) => {
  const { name, size } = getRoutineIconData(routineType)
  return (
    <Circle>
      <Icon color='textInverse' name={name} size={size} />
    </Circle>
  )
}

export default RoutineIcon
