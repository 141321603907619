import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Composition } from 'atomic-layout'
import {
  accountsSelectors,
  getAccounts,
  selectAccountsLoading,
} from './accountsSlice'
import { getInstitutions } from './institutionsSlice'
import { Container, Text } from 'src/atoms'
import AccountRow from './AccountRow'
import Count from 'src/components/Count'
import Spinner from 'src/components/Spinner'

const areasMobile = `
  header
  list
`

const Accounts: React.FC = () => {
  const dispatch = useDispatch()
  const accounts = useSelector(accountsSelectors.selectAll)
  const loading = useSelector(selectAccountsLoading)

  useEffect(() => {
    dispatch(getInstitutions())
    dispatch(getAccounts())
  }, [dispatch])

  return (
    <Composition areas={areasMobile} as={Container} paddingHorizontal={0}>
      {Areas => (
        <>
          <Areas.Header flex alignItems='center' paddingHorizontal={18}>
            <Text fontFamily='heavy' size='large'>
              Accounts
            </Text>
            <Box marginHorizontal={10}>
              <Count count={accounts.length} loading={loading} />
            </Box>
          </Areas.Header>
          {loading ? (
            <Box
              flex
              alignItems='center'
              justifyContent='center'
              paddingVertical={50}
            >
              <Spinner />
            </Box>
          ) : (
            <Areas.List marginTop={20} marginBottom={10}>
              {accounts &&
                accounts.map(account => (
                  <AccountRow key={account.id} account={account} />
                ))}
            </Areas.List>
          )}
        </>
      )}
    </Composition>
  )
}

export default Accounts
