import { createAsyncThunk } from '@reduxjs/toolkit'
import * as qs from 'query-string'
import isEmpty from 'lodash/isEmpty'
import toast from 'react-hot-toast'

const ASTRA_API_URL = process.env.REACT_APP_ASTRA_API_URL
export const CLIENT_ID_HEADER = 'X-Client-ID'
export const USER_ID_HEADER = 'X-User-ID'

// TODO: define api data parameters for each api call
interface ParameterProps {
  [key: string]: unknown
}

interface APIRequestParams {
  method: string
  route: string
  contentType?: string
  sessionToken?: string
  suppressError?: boolean
  data?: ParameterProps
  userId?: string
}

const getQueryString = (params: ParameterProps) => {
  const esc = encodeURIComponent
  return Object.keys(params)
    .map(k => `${esc(k)}=${esc(params[k] as string)}`)
    .join('&')
}

const request = (
  token: string,
  clientId: string,
  userId: string,
  params: APIRequestParams,
) => {
  interface Options {
    method: string
    headers: {
      Accept: string
      'Content-Type': string
      Authorization: string
      [CLIENT_ID_HEADER]: string
      [USER_ID_HEADER]: string
    }
    body?: string
  }
  const buildHeaders = () => ({
    Accept: 'application/json',
    'Content-Type': params.contentType || 'application/json',
    Authorization: `${
      params.sessionToken ? `Bearer ${params.sessionToken}` : `Token ${token}`
    }`,
    [CLIENT_ID_HEADER]: clientId,
    [USER_ID_HEADER]: userId || params.userId || '',
  })

  const buildQueryString = (data: any) => {
    const definedParams = { ...data }
    Object.keys(definedParams).forEach(k => {
      if (definedParams[k] === undefined) {
        delete definedParams[k]
      }
    })
    return !isEmpty(definedParams) ? `?${getQueryString(definedParams)}` : ''
  }

  const buildOptions = (method: string, headers: any, data?: any) => {
    const options: Options = { method, headers }
    if (method !== 'GET' && method !== 'DELETE' && data) {
      options.body =
        params.contentType === 'application/x-www-form-urlencoded'
          ? qs.stringify(data)
          : JSON.stringify(data)
    }
    return options
  }

  const url = `${ASTRA_API_URL}/${params.route}${
    ['GET', 'DELETE'].includes(params.method.toUpperCase())
      ? buildQueryString(params.data)
      : ''
  }`
  const options = buildOptions(
    params.method.toUpperCase(),
    buildHeaders(),
    params.data,
  )

  return new Promise((resolve, reject) => {
    return fetch(url, options)
      .then(res =>
        res.json().then(data => ({
          data,
          ok: res.ok,
        })),
      )
      .then(({ data, ok }) => {
        if (!ok) {
          reject(data)
        } else {
          resolve(data)
        }
      })
      .catch(reject)
  })
}

export const upload = ({
  url,
  body,
}: {
  url: string
  body: ReadableStream<Uint8Array>
}): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    return fetch(url, {
      method: 'PUT',
      body,
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    })
      .then(data => {
        resolve(data)
      })
      .catch(reject)
  })
}

const ignoredErrors = [
  'User not signed up',
  'User not signed up.',
  'Login failed due to an error in your account. Please contact astra support',
]

export default createAsyncThunk(
  'apiRequest',
  async (
    { suppressError = false, ...params }: APIRequestParams,
    { getState, rejectWithValue },
  ) => {
    interface AuthState {
      firebaseToken: string
      client: {
        clientId: string
      }
      user: {
        userId: string
      }
    }

    const state = getState() as { auth: AuthState }
    const {
      auth: {
        firebaseToken,
        client: { clientId = '' },
        user: { userId = '' },
      },
    } = state

    try {
      const response = await request(firebaseToken, clientId, userId, params)
      return response
    } catch (e) {
      const error = e as any
      const errorMessage = error.description || error.message

      if (
        !suppressError &&
        errorMessage &&
        !ignoredErrors.includes(errorMessage)
      ) {
        toast.error(errorMessage)
      }

      throw rejectWithValue(error)
    }
  },
)
