import React from 'react'
import { Control, Controller, FieldError } from 'react-hook-form'
import { MaskedInput } from 'src/atoms'
import { clearPhoneNumberFormatting } from 'src/utils/format'

const validatePhoneNumber = (phoneNumber: string): string | undefined => {
  const clearedPhoneNumber = clearPhoneNumberFormatting(phoneNumber)
  return clearedPhoneNumber.length < 11 ? 'required' : undefined
}

interface Props {
  id: string
  name: string
  placeholder: string
  error?: FieldError
  readOnly?: boolean
  control: Control<any, any>
  isOptional?: boolean
}

const PhoneNumberField = React.forwardRef<HTMLInputElement, Props>(
  ({ id, name, placeholder, error, readOnly, control, isOptional }) => (
    <Controller
      control={control}
      name={name}
      rules={
        isOptional
          ? { required: false }
          : {
              validate: {
                phoneRequired: validatePhoneNumber,
              },
            }
      }
      render={({ field: { onChange, onBlur, value } }) => (
        <MaskedInput
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          name={name}
          id={id}
          placeholder={placeholder}
          type='tel'
          format='+1 (###) ###-####'
          mask=' '
          bordercolor={error && 'error'}
          readOnly={readOnly}
        />
      )}
    />
  ),
)

PhoneNumberField.displayName = 'PhoneNumberField'

export default PhoneNumberField
