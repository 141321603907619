import React from 'react'

import ConnectDebitCardModule from './ConnectDebitCardModule'
import ContinueButton from './ContinueButton'

const ConnectDebitCard = () => (
  <>
    <ConnectDebitCardModule />
    <ContinueButton />
  </>
)

export default ConnectDebitCard
