import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Box } from 'atomic-layout'

import TransferStatus from '../../components/TransferStatus'
import { Link, Text } from '../../atoms'
import { dollarFormat, convertISOToShortDate } from '../../utils/format'
import { clientsSelectors } from '../clients/clientsSlice'

const Container = styled(Box)`
  padding: 15px 18px;
  &:hover {
    background: ${({ theme }) => theme.colors.backgroundTinted};
  }
`

const ClientLogo = styled.img`
  border-radius: 22px;
  height: 44px;
  width: 44px;
`

const TransferRow = ({ transfer }) => {
  const oAuthClient =
    useSelector(state =>
      clientsSelectors.selectById(state, transfer.oauth_client_id),
    ) || {}

  return (
    <Link to={`/transfers/${transfer.id}`}>
      <Container flex alignItems='center'>
        <ClientLogo
          src={transfer.logo_url}
          alt={`${oAuthClient.name || 'client'} logo`}
        />
        <Box flexGrow={1} marginLeft={12}>
          <Text fontFamily='medium' size='small'>
            {transfer.action_name}
          </Text>
          <TransferStatus status={transfer.status} />
        </Box>
        <Box>
          <Text fontFamily='medium' size='small' textAlign='right'>
            {dollarFormat(transfer.amount)}
          </Text>
          <Text color='textSubtle' size='xsmall' textAlign='right'>
            {convertISOToShortDate(transfer.date)}
          </Text>
        </Box>
      </Container>
    </Link>
  )
}

export default TransferRow
