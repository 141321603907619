import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { navigate } from '@reach/router'
import { Box, Composition } from 'atomic-layout'
import { selectTransfersLoading, transfersSelectors } from './transfersSlice'
import { Container, Link, Text } from '../../atoms'
import AccordionContent from '../../components/AccordionContent'
import AccordionIcon from '../../components/AccordionIcon'
import Count from '../../components/Count'
import TransferRow from './TransferRow'

const areasMobile = `
  header
  list
  footer
`
const listSize = 4

const TransfersOverview = () => {
  const transfers = useSelector(transfersSelectors.selectAll)
  const loading = useSelector(selectTransfersLoading)
  const [isOpen, setIsOpen] = useState(true)

  useEffect(() => {
    setIsOpen(transfers.length !== 0)
  }, [transfers])

  return (
    <Composition areas={areasMobile} as={Container} paddingHorizontal={0}>
      {Areas => (
        <>
          <Areas.Header flex alignItems='center' paddingHorizontal={18}>
            <Text
              cursor='pointer'
              fontFamily='heavy'
              size='large'
              onClick={() => navigate('/transfers')}
            >
              Transfers
            </Text>
            <Box marginLeft={10}>
              <Count
                count={transfers.length}
                onClick={() => navigate('/transfers')}
                loading={loading}
              />
            </Box>
            <Box
              flex
              flexGrow={1}
              justifyContent='flex-end'
              onClick={() => setIsOpen(!isOpen)}
            >
              <AccordionIcon isOpen={isOpen} />
            </Box>
          </Areas.Header>
          <AccordionContent isOpen={isOpen}>
            <>
              <Areas.List marginTop={20} marginBottom={10}>
                {transfers &&
                  transfers
                    .slice(0, listSize)
                    .map(transfer => (
                      <TransferRow key={transfer.id} transfer={transfer} />
                    ))}
              </Areas.List>
              {transfers && transfers.length > listSize && (
                <Areas.Footer flex alignItems='center' justifyContent='center'>
                  <Link to='/transfers' size='small'>
                    More
                  </Link>
                </Areas.Footer>
              )}
            </>
          </AccordionContent>
        </>
      )}
    </Composition>
  )
}

export default TransfersOverview
