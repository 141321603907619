import Bugsnag from '@bugsnag/js'
import toast from 'react-hot-toast'
import { FirebaseError } from 'firebase/app'
import { Environment } from 'src/features/auth/types'

export default (error: Error | FirebaseError | unknown): void => {
  if (error instanceof Error) {
    return Bugsnag.notify(error)
  }
}

enum FirebaseAuthErrorType {
  INVALID_PHONE_NUMBER = 'auth/invalid-phone-number',
  INVALID_VERIFICATION_CODE = 'auth/invalid-verification-code',
  MISSING_PHONE_NUMBER = 'auth/missing-phone-number',
  QUOTA_EXCEEDED = 'auth/quota-exceeded',
  SESSION_EXPIRED = 'auth/session-expired',
  TOO_MANY_REQUESTS = 'auth/too-many-requests',
  UNKNOWN_ERROR = 'auth/unknown-error',
}

/**
 * A function that logs the Firebase auth error and triggers an error toast for user notification
 * @param error - a firebase generated error
 */
export const handleFirebaseAuthError = (error: FirebaseError): void => {
  if (process.env.REACT_APP_RELEASE_STAGE === Environment.DEVELOPMENT) {
    console.error(error)
  }

  // @TODO(worstestes): currently only supports auth errors
  switch (error.code) {
    case FirebaseAuthErrorType.INVALID_PHONE_NUMBER:
      toast.error('Please enter a valid phone number.')
      break
    case FirebaseAuthErrorType.MISSING_PHONE_NUMBER:
      toast.error('Please enter your phone number.')
      break
    case FirebaseAuthErrorType.QUOTA_EXCEEDED:
      toast.error(
        'You have exceeded the maximum number of verification attempts. Please try again later.',
      )
      break
    case FirebaseAuthErrorType.SESSION_EXPIRED:
      toast.error(
        'Your verification code has expired. Please request a new one.',
      )
      break
    case FirebaseAuthErrorType.INVALID_VERIFICATION_CODE:
      toast.error(
        'The verification code entered is incorrect. Please try again.',
      )
      break
    case FirebaseAuthErrorType.TOO_MANY_REQUESTS:
      toast.error('Too many attempts. Please try again later.')
      break
    default:
      toast.error('An unexpected error occurred. Please try again later.')
      break
  }
}

/**
 * Leaves a breadcrumb in Bugsnag. Breadcrumbs are captured and left in the device's diagnostic data,
 * allowing you to see the steps a user took before encountering a bug or crash.
 */
export const leaveBugsnagBreadcrumb = ({
  message,
  metadata,
}: {
  message: string
  metadata?: Record<string, any>
}): void => {
  return Bugsnag.leaveBreadcrumb(message, metadata)
}

/**
 * Sets user information in Bugsnag. This user data is included in all error reports sent by the client.
 * When set, this data will also be searchable and visible on the Bugsnag dashboard.
 */
export const setBugsnagClientUser = ({
  id,
  email,
  name,
}: {
  id: string
  email: string
  name: string
}): void => {
  return Bugsnag.setUser(id, email, name)
}
