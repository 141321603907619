export enum UserType {
  BUSINESS = 'business',
  PERSONAL = 'personal',
}

export enum AuthFlow {
  SIGN_UP = 'signup',
  LOGIN = 'login',
}

export enum Environment {
  DEVELOPMENT = 'development',
  PRODUCTION = 'production',
}

export interface OAuthClient {
  app_link: string
  client_id: string
  debit_enabled: boolean
  description: string
  from_email: string
  from_name: string
  hex_color: string
  id: string
  logo_url: string
  name: string
  oauth_logo_url: string
  processor_token_enabled: boolean
  square_logo_url: string
  support_url: string
  webhook_url: string
}

export interface OAuthClientPublic {
  debit_enabled: boolean
  description: string
  is_api_only: boolean
  is_sandbox_phone_numbers_enabled: boolean
  kyb_provider: string
  kyc_provider: string
  name: string
  oauth_logo_url: string
  processor_token_enabled: boolean
  support_email: string
  support_url: string
}

export interface Client {
  clientId: string
  debitEnabled: boolean
  isApiOnly: boolean
  isSandboxPhoneNumbersEnabled: boolean
  loading: boolean
  logo: string
  name: string
  processorTokenEnabled: boolean
  supportEmail: string
  supportUrl: string
  kybProvider: string
  kycProvider: string
}

export enum DocumentStatus {
  APPROVED = 'approved',
  NEEDED = 'needed',
  PROCESSED = 'processed',
  REJECTED = 'rejected',
  UNVERIFIED = 'unverified',
  UPLOADED = 'uploaded',
}

export enum UserKycType {
  RECEIVE_ONLY = 'receive-only',
  UNVERIFIED = 'unverified',
  VERIFIED = 'verified',
}

export enum KycProvider {
  PERSONA = 'persona',
  SELF = 'self',
  NONE = '',
}

export enum VerificationStatus {
  APPROVED = 'approved',
  DOCUMENT = 'document',
  MANUAL_REVIEW = 'manual_review',
  PENDING = 'pending',
  REJECTED = 'rejected',
  RETRY = 'retry',
  UNVERIFIED = 'unverified',
}

export enum UserStatus {
  APPROVED = 'approved',
  DOCUMENT = 'document',
  PENDING = 'pending',
  REJECTED = 'rejected',
  RETRY = 'retry',
  SUSPENDED = 'suspended',
  UNVERIFIED = 'unverified',
}

export interface User {
  email: string
  dateOfBirth: string
  documentStatus: string
  firstName: string
  lastName: string
  inquiryId?: string
  inquirySessionToken?: string
  phone: string
  kycProvider: KycProvider
  kycType: UserKycType
  needsBeneficialOwnerCertification: boolean
  status: UserStatus
  userId: string
  userType: string
  verificationStatus: VerificationStatus
}
