import styled from 'styled-components'
import { astraTheme } from '../styles/theme'

interface TextProps {
  color?: keyof typeof astraTheme.colors
  cursor?: string
  display?: string
  fontFamily?: keyof typeof astraTheme.fonts
  fontWeight?: string
  lineHeight?: string
  onClick?: () => void
  padding?: string
  size?: keyof typeof astraTheme.text
  textAlign?: string
}

const Text = styled.span<TextProps>`
  color: ${props =>
    props.color ? props.theme.colors[props.color] : props.theme.colors.text};
  cursor: ${props => props.cursor || 'inherit'};
  display: ${props => props.display || 'block'};
  font-family: ${props =>
    props.fontFamily
      ? props.theme.fonts[props.fontFamily]
      : props.theme.fonts.book};
  font-size: ${props => props.theme.text[props.size || 'medium'].size};
  font-weight: ${props => props.fontWeight || 'normal'};
  line-height: ${props =>
    props.size
      ? props.theme.text[props.size].height
      : props.lineHeight || '1.5em'};
  ${props => (props.padding ? `padding: ${props.padding}` : '')};
  text-align: ${props => props.textAlign || 'left'};
`

export default Text
