import styled, { css } from 'styled-components'
import { astraTheme, CustomThemeType } from '../styles/theme'

interface SizeProps {
  size?: keyof typeof astraTheme.spinner
  theme: CustomThemeType
}
const sizeStyle = ({ size = 'medium', theme }: SizeProps) => {
  const spinnerSize = theme.spinner[size]
  return css`
    width: ${spinnerSize};
    height: ${spinnerSize};
  `
}

const genericStyles = css`
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  background: ${({ theme }) => theme.colors.primary};
  animation: cp-morph-animate 1.4s linear infinite;

  @keyframes cp-morph-animate {
    0% {
      transform: rotate(0) scale(1);
      border-radius: 0;
    }
    25%,
    75% {
      transform: rotate(180deg) scale(0.4);
      border-radius: 50%;
    }
    100% {
      transform: rotate(360deg) scale(1);
      border-radius: 0;
    }
  }
`

const Spinner = styled.div<{ size?: keyof typeof astraTheme.spinner }>`
  ${genericStyles}
  ${props => sizeStyle(props)}
`

export default Spinner
