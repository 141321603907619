import React from 'react'
import { Composition } from 'atomic-layout'
import NumberFormat from 'react-number-format'
import { isEmpty } from 'lodash'

import DateOfBirthField from 'src/components/DateOfBirthField'
import SocialSecurityField from 'src/components/SocialSecurityField'
import { Button, Container, Input, Text } from 'src/atoms'
import { setRemoveBeneficialOwner } from 'src/features/verify/verifySlice'
import useBeneficialOwnerForm from 'src/features/verify/business/BeneficialOwners/useBeneficialOwnerForm'
import { BeneficialOwnerFormKeys } from 'src/features/verify/business/BeneficialOwners/useBeneficialOwnerForm'
import AddressForm from 'src/features/verify/AddressForm'
import { BeneficialOwner as BeneficialOwnerType } from 'src/features/verify/types'
import { useAppDispatch } from 'src/redux/store'
import { convertISOToShortDate } from 'src/utils/format'

const areasMobile = `
  title
  subtitle
  form
`

const formAreasMobile = `
  firstName
  lastName
  email
  dateOfBirth
  socialSecurityNumber
  addressSection
  add
`

interface BeneficialOwnerProps {
  beneficialOwner?: BeneficialOwnerType & { id: string }
  onAdd?: () => void
}

const BeneficialOwner: React.FC<BeneficialOwnerProps> = ({
  beneficialOwner = {},
  onAdd,
}) => {
  const dispatch = useAppDispatch()

  const {
    defaultValues,
    errors,
    form,
    isKybDelegated,
    isSubmitDisabled,
    onSubmit,
  } = useBeneficialOwnerForm()

  const {
    id,
    firstName,
    lastName,
    dateOfBirth,
    email,
    ssn,
    address1,
    address2,
    city,
    state,
    zip,
  } = beneficialOwner as BeneficialOwnerType & { id: string }

  const dateOfBirthFormatted = dateOfBirth
    ? convertISOToShortDate(dateOfBirth)
    : ''

  const completedAreasMobile =
    dateOfBirth && ssn && !isKybDelegated
      ? `
    name
    dateOfBirth
    socialSecurityNumber
    address1
    address2
    cityStateAndZip
  `
      : `
    name
    address1
    address2
    cityStateAndZip
  `

  const remove = () => {
    dispatch(setRemoveBeneficialOwner(id))
  }

  if (!isEmpty(beneficialOwner)) {
    return (
      <Composition
        areas={completedAreasMobile}
        as={Container}
        gap={3}
        alignItems='center'
        justifyContent='space-between'
      >
        {Areas => (
          <>
            <Areas.Name
              colEnd='none'
              marginBottom={3}
              flex
              alignItems='center'
              justifyContent='space-between'
            >
              <Text fontFamily='heavy' size='large'>
                {firstName} {lastName}
              </Text>
              <Text
                onClick={remove}
                color='primary'
                cursor='pointer'
                fontFamily='medium'
              >
                Remove
              </Text>
            </Areas.Name>
            <Areas.Email>
              <Text fontFamily='medium' color='textSubtle'>
                {email}
              </Text>
            </Areas.Email>
            {dateOfBirthFormatted && (
              <Areas.DateOfBirth>
                <Text fontFamily='medium' color='textSubtle'>
                  {dateOfBirthFormatted}
                </Text>
              </Areas.DateOfBirth>
            )}
            {ssn && (
              <Areas.SocialSecurityNumber>
                <NumberFormat
                  value={ssn}
                  displayType='text'
                  format='•••-••-####'
                  renderText={value => (
                    <Text fontFamily='medium' color='textSubtle'>
                      {value}
                    </Text>
                  )}
                />
              </Areas.SocialSecurityNumber>
            )}
            <Areas.Address1>
              <Text fontFamily='medium' color='textSubtle'>
                {address1}
              </Text>
            </Areas.Address1>
            {address2 && (
              <Areas.Address2>
                <Text fontFamily='medium' color='textSubtle'>
                  {address2}
                </Text>
              </Areas.Address2>
            )}
            <Areas.CityStateAndZip>
              <Text fontFamily='medium' color='textSubtle'>
                {city}, {state} {zip}
              </Text>
            </Areas.CityStateAndZip>
          </>
        )}
      </Composition>
    )
  }

  return (
    <Composition areas={areasMobile} as={Container}>
      {Areas => (
        <>
          <Areas.Title marginBottom={3}>
            <Text fontFamily='heavy' size='large'>
              Add Beneficial Owner
            </Text>
          </Areas.Title>
          <Areas.Subtitle marginBottom={15}>
            <Text fontFamily='medium' size='small' color='textSubtle'>
              Enter beneficial owner details.
            </Text>
          </Areas.Subtitle>
          <Areas.Form>
            <Composition areas={formAreasMobile} gap={10}>
              {FormAreas => (
                <>
                  <FormAreas.FirstName>
                    <Input
                      id={BeneficialOwnerFormKeys.FIRST_NAME}
                      type='text'
                      placeholder='First Name'
                      defaultValue={
                        defaultValues[BeneficialOwnerFormKeys.FIRST_NAME]
                      }
                      bordercolor={
                        errors[BeneficialOwnerFormKeys.FIRST_NAME] && 'error'
                      }
                      {...form.register(BeneficialOwnerFormKeys.FIRST_NAME, {
                        required: true,
                      })}
                    />
                  </FormAreas.FirstName>
                  <FormAreas.LastName>
                    <Input
                      id={BeneficialOwnerFormKeys.LAST_NAME}
                      type='text'
                      placeholder='Last Name'
                      defaultValue={
                        defaultValues[BeneficialOwnerFormKeys.LAST_NAME]
                      }
                      bordercolor={
                        errors[BeneficialOwnerFormKeys.LAST_NAME] && 'error'
                      }
                      {...form.register(BeneficialOwnerFormKeys.LAST_NAME, {
                        required: true,
                      })}
                    />
                  </FormAreas.LastName>
                  <FormAreas.Email>
                    <Input
                      id={BeneficialOwnerFormKeys.EMAIL}
                      type='text'
                      placeholder='Email Address'
                      defaultValue={
                        defaultValues[BeneficialOwnerFormKeys.EMAIL]
                      }
                      bordercolor={
                        errors[BeneficialOwnerFormKeys.EMAIL] && 'error'
                      }
                      {...form.register(BeneficialOwnerFormKeys.EMAIL, {
                        required: true,
                      })}
                    />
                  </FormAreas.Email>
                  {isKybDelegated ? null : (
                    <>
                      <FormAreas.DateOfBirth>
                        <DateOfBirthField
                          control={form.control}
                          defaultValue={
                            defaultValues[
                              BeneficialOwnerFormKeys.DATE_OF_BIRTH
                            ] as string
                          }
                          error={errors[BeneficialOwnerFormKeys.DATE_OF_BIRTH]}
                          id={BeneficialOwnerFormKeys.DATE_OF_BIRTH}
                          {...form.register(
                            BeneficialOwnerFormKeys.DATE_OF_BIRTH,
                            {
                              required: true,
                            },
                          )}
                        />
                      </FormAreas.DateOfBirth>
                      <FormAreas.SocialSecurityNumber>
                        <SocialSecurityField
                          id={BeneficialOwnerFormKeys.SSN}
                          control={form.control}
                          defaultValue={
                            defaultValues[BeneficialOwnerFormKeys.SSN] as string
                          }
                          error={errors[BeneficialOwnerFormKeys.SSN]}
                          requireFullNumber={false}
                          {...form.register(BeneficialOwnerFormKeys.SSN, {
                            required: true,
                          })}
                        />
                      </FormAreas.SocialSecurityNumber>
                    </>
                  )}
                  <FormAreas.AddressSection>
                    <AddressForm
                      control={form.control}
                      defaultValues={{
                        address1: defaultValues.address1 as string,
                        address2: defaultValues.address2 as string,
                        city: defaultValues.city as string,
                        state: defaultValues.state as string,
                        zip: defaultValues.zip as string,
                      }}
                      errors={errors}
                      register={form.register}
                    />
                  </FormAreas.AddressSection>
                  <FormAreas.Add>
                    <Button
                      disabled={isSubmitDisabled}
                      fullWidth
                      onClick={() => {
                        onSubmit()
                        if (onAdd) {
                          onAdd()
                        }
                      }}
                      type='button'
                    >
                      Add Beneficial Owner
                    </Button>
                  </FormAreas.Add>
                </>
              )}
            </Composition>
          </Areas.Form>
        </>
      )}
    </Composition>
  )
}

export default BeneficialOwner
