import styled from 'styled-components'

export default styled.div<{ firstItem: boolean }>`
  background-color: ${({ theme }) => theme.colors.background};
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.border};
  border-top-width: ${props => (props.firstItem ? '0' : '1px')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0px;
`
