import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Box } from 'atomic-layout'

import {
  cancelTransfer,
  getTransfer,
  selectIsCancellingTransfer,
  selectTransfersLoading,
  transfersSelectors,
} from './transfersSlice'
import {
  getInstitutions,
  institutionsSelectors,
} from '../accounts/institutionsSlice'
import { accountsSelectors, getAccounts } from '../accounts/accountsSlice'
import { getRoutines, routinesSelectors } from '../routines/routinesSlice'
import { clientsSelectors } from '../clients/clientsSlice'

import ClientAppModule from '../clients/ClientAppModule'
import DropdownMenu from '../../components/DropdownMenu'
import FullScreenSpinner from '../../components/FullScreenSpinner'
import NotFound from '../../pages/NotFound'
import TransferStatus from '../../components/TransferStatus'

import { Container, InstitutionLogo, Link, Text } from '../../atoms'
import { convertISOToMediumDate, dollarFormat } from '../../utils/format'

const Row = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`

const Block = styled(Box)`
  flex: 0 0 50%;
  border-right: ${props =>
    props.divider ? `1px solid ${props.theme.colors.border}` : 0};
  padding: 12px 14px;
`

const TransferDetail = ({ id }) => {
  const dispatch = useDispatch()
  const transfer = useSelector(state =>
    transfersSelectors.selectById(state, id),
  )
  const loading = useSelector(selectTransfersLoading)
  const isCancelling = useSelector(selectIsCancellingTransfer)
  const sourceInstitution = useSelector(state => {
    if (!transfer) return null
    return institutionsSelectors.selectById(state, transfer.source_inst_item_id)
  })
  const sourceAccount = useSelector(state => {
    if (!transfer) return null
    return accountsSelectors.selectById(state, transfer.source)
  })
  const destinationInstitution = useSelector(state => {
    if (!transfer) return null
    return institutionsSelectors.selectById(
      state,
      transfer.destination_inst_item_id,
    )
  })
  const destinationAccount = useSelector(state => {
    if (!transfer) return null
    return accountsSelectors.selectById(state, transfer.destination)
  })
  const routine = useSelector(state => {
    if (!transfer) return null
    return routinesSelectors.selectById(state, transfer.action_id)
  })
  const oAuthClient = useSelector(state => {
    if (!transfer) return null
    return clientsSelectors.selectById(state, transfer.oauth_client_id)
  })

  useEffect(() => {
    dispatch(getTransfer({ id }))
  }, [dispatch, id])

  useEffect(() => {
    dispatch(getInstitutions())
    dispatch(getAccounts())
    dispatch(getRoutines())
  }, [dispatch])

  const renderAccounts = () => {
    if (!sourceAccount && !destinationAccount) return null

    return (
      <Row flex>
        <Block>
          <Text color='textSubtle' size='xsmall'>
            Source Account
          </Text>
          {sourceAccount ? (
            <Link to={`/accounts/${sourceAccount.id}`}>
              <Box flex alignItems='center' marginTop={3}>
                {sourceInstitution && (
                  <Box marginRight={8}>
                    <InstitutionLogo
                      logo={sourceInstitution.logo}
                      name={sourceInstitution.name}
                    />
                  </Box>
                )}
                <Box>
                  <Text fontFamily='medium' lineHeight='1.1em'>
                    {transfer.source_inst}
                  </Text>
                  <Text color='textSubtle' lineHeight='1.1em' size='xsmall'>
                    {sourceAccount.number}
                  </Text>
                </Box>
              </Box>
            </Link>
          ) : (
            <Text fontFamily='medium' size='small'>
              Not Found
            </Text>
          )}
        </Block>
        <Block>
          <Text color='textSubtle' size='xsmall'>
            Destination Account
          </Text>
          {destinationAccount ? (
            <Link to={`/accounts/${destinationAccount.id}`}>
              <Box flex alignItems='center' marginTop={3}>
                {destinationInstitution && (
                  <Box marginRight={8}>
                    <InstitutionLogo
                      logo={destinationInstitution.logo}
                      name={destinationInstitution.name}
                    />
                  </Box>
                )}
                <Box>
                  <Text fontFamily='medium' lineHeight='1.1em'>
                    {transfer.destination_inst}
                  </Text>
                  <Text color='textSubtle' lineHeight='1.1em' size='xsmall'>
                    {destinationAccount.number}
                  </Text>
                </Box>
              </Box>
            </Link>
          ) : (
            <Text fontFamily='medium' size='small'>
              Not Found
            </Text>
          )}
        </Block>
      </Row>
    )
  }

  if (loading) {
    return <FullScreenSpinner />
  }

  if (!transfer) {
    return <NotFound />
  }

  return (
    <Box width='100%'>
      <Box paddingHorizontal={10}>
        <Box flex alignItems='center'>
          <Box flexGrow={1}>
            <Text fontFamily='heavy' size='large' textAlign='left'>
              {transfer.action_name}
            </Text>
          </Box>
          <DropdownMenu
            button={
              <ion-icon name='ellipsis-horizontal' style={{ fontSize: 25 }} />
            }
          >
            <DropdownMenu.Item
              onClick={() => dispatch(cancelTransfer({ id }))}
              name={isCancelling ? 'Cancelling...' : 'Cancel Transfer'}
              isDangerous
            />
          </DropdownMenu>
        </Box>
        <Box>
          <TransferStatus status={transfer.status} />
        </Box>
      </Box>
      <Container padding={0} marginTop={20}>
        {(oAuthClient || routine) && (
          <Row>
            <Block
              flex
              alignItems='center'
              justifyContent='space-between'
              paddingVertical={5}
            >
              <Text color='textSubtle' size='xsmall'>
                {oAuthClient ? oAuthClient.name : ''}
              </Text>
              <Text color='textSubtle' size='xsmall'>
                {routine ? routine.friendly_name : ''}
              </Text>
            </Block>
          </Row>
        )}
        <Row flex>
          <Block divider>
            <Text color='textSubtle' size='xsmall'>
              Transfer Amount
            </Text>
            <Text fontFamily='heavy' lineHeight='24px' size='large'>
              {dollarFormat(transfer.amount)}
            </Text>
          </Block>
          <Block>
            <Text color='textSubtle' size='xsmall'>
              Estimated Clearing Date
            </Text>
            <Text fontFamily='heavy' lineHeight='24px' size='large'>
              {transfer.estimated_clearing_date
                ? convertISOToMediumDate(transfer.estimated_clearing_date)
                : '--'}
            </Text>
          </Block>
        </Row>
        {renderAccounts()}
        <Link to={`/routines/${transfer.action_id}`}>
          <Block flex alignItems='center' justifyContent='center'>
            <Text fontFamily='medium' size='xsmall'>
              Show Routine Details
            </Text>
          </Block>
        </Link>
      </Container>
      <ClientAppModule clientApp={oAuthClient} />
    </Box>
  )
}

export default TransferDetail
