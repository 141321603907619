import React from 'react'
import { useSelector } from 'react-redux'
import { navigate } from '@reach/router'
import styled, { css } from 'styled-components'

import { selectClient } from '../features/auth/authSlice'
import { selectIframeOrigin } from '../features/sdkMessaging/sdkMessagingSlice'
import logo from '../assets/images/logo-mark-with-text-black.svg'
import { astraTheme, CustomThemeType } from '../styles/theme'

interface SizeProps {
  size?: keyof typeof astraTheme.logo
  theme: CustomThemeType
}
const sizeStyle = ({ size = 'small', theme }: SizeProps) => {
  const { height } = theme.logo[size]
  return css`
    height: ${height};
  `
}

const Image = styled.img<{
  size?: keyof typeof astraTheme.logo
  clickable: boolean
}>`
  cursor: ${props => (props.clickable ? 'pointer' : 'default')};
  display: block;
  ${props => sizeStyle(props)}
`

interface Props {
  size?: keyof typeof astraTheme.logo
}
const Logo: React.FC<Props> = ({ size }) => {
  const iframeOrigin = useSelector(selectIframeOrigin)
  const client = useSelector(selectClient)
  const clickable = !iframeOrigin && !client.clientId

  return (
    <Image
      alt='Astra logo'
      src={logo}
      size={size}
      clickable={clickable}
      onClick={() => {
        if (clickable) navigate('/')
      }}
    />
  )
}

export default Logo
