import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  unwrapResult,
} from '@reduxjs/toolkit'
import toast from 'react-hot-toast'

import { sendErrorMessage } from 'src/features/sdkMessaging/sdkMessagingSlice'
import apiRequest from 'src/utils/api'
import { RootState } from 'src/redux/store'
import { Account } from 'src/features/accounts/types'

const accountsAdapter = createEntityAdapter<Account>()

export const getAccounts = createAsyncThunk<Account[], void, { dispatch: any }>(
  'accounts/getAll',
  async (_, { dispatch }) => {
    try {
      const { accounts = [] } = await dispatch(
        apiRequest({
          method: 'get',
          route: 'accounts',
        }),
      ).then(unwrapResult)
      const accountsFiltered = accounts.filter(
        (account: Account) => !account.hidden,
      )

      return accountsFiltered
    } catch (e) {
      const error = e as any
      dispatch(sendErrorMessage({ error }))

      throw error
    }
  },
)

interface RemoveAccountPayload {
  id: string
}

export const removeAccount = createAsyncThunk<
  void,
  RemoveAccountPayload,
  { dispatch: any }
>('accounts/removeOne', async ({ id }, { dispatch }) => {
  try {
    await dispatch(
      apiRequest({
        method: 'delete',
        route: `accounts/${id}`,
      }),
    ).then(unwrapResult)

    toast.success('Your account has been removed.')
    dispatch(getAccounts())
  } catch (e) {
    const error = e as any
    dispatch(sendErrorMessage({ error }))

    throw error
  }
})

const accountsSlice = createSlice({
  name: 'accounts',
  initialState: accountsAdapter.getInitialState<{
    loading: boolean
    isRemoving: boolean
  }>({
    loading: false,
    isRemoving: false,
  }),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAccounts.pending, state => {
        state.loading = true
      })
      .addCase(getAccounts.fulfilled, (state, action) => {
        if (action.payload) {
          accountsAdapter.setAll(state, action.payload)
        }
        state.loading = false
      })
      .addCase(getAccounts.rejected, state => {
        state.loading = false
      })
      .addCase(removeAccount.pending, state => {
        state.isRemoving = true
      })
      .addCase(removeAccount.fulfilled, state => {
        state.isRemoving = false
      })

      .addCase(removeAccount.rejected, state => {
        state.isRemoving = false
      })
  },
})

export const accountsSelectors = accountsAdapter.getSelectors(
  (state: RootState) => state.accounts,
)
export const selectAccountsLoading = (state: RootState): boolean =>
  state.accounts.loading
export const selectIsRemovingAccount = (state: RootState): boolean =>
  state.accounts.isRemoving

export default accountsSlice.reducer
