import { DateTime } from 'luxon'

const getNumberSuffix = num => {
  const th = 'th'
  const rd = 'rd'
  const nd = 'nd'
  const st = 'st'

  if (num === 11 || num === 12 || num === 13) return th

  const lastDigit = num.toString().slice(-1)

  switch (lastDigit) {
    case '1':
      return st
    case '2':
      return nd
    case '3':
      return rd
    default:
      return th
  }
}

/**
 * a function utilized to structure a phone number into a standard format: '+1 (###) ###-####'.
 *
 * @param {string} phone - The phone number string
 * @returns {string} - Formatted phone number or an empty string if phone is not provided.
 */
export const formatPhoneNumber = phone => {
  if (!phone) {
    return ''
  }

  const phoneNumber = phone.replace(/^\+1/, '')

  const areaCode = phoneNumber.slice(0, 3)
  const localPrefix = phoneNumber.slice(3, 6)
  const lineNumber = phoneNumber.slice(6)

  return `+1 (${areaCode}) ${localPrefix}-${lineNumber}`
}

// takes any phone number and returns in +12345678910
export const clearPhoneNumberFormatting = phoneNumber => {
  return phoneNumber.replace(/[^+0-9]/g, '')
}

export const convertDateToISO = date => {
  return DateTime.fromFormat(date, 'MM/dd/yyyy').toISODate()
}

export const convertDateFromISO = date => {
  return DateTime.fromISO(date).toFormat('MM/dd/yyyy')
}

export const convertISOToShortDate = date => {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT)
}

export const convertISOToMediumDate = date => {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED)
}

export const convertISOToFullDate = date => {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATE_FULL)
}

export const getDayOfMonth = date => {
  const { day } = DateTime.fromISO(date)
  const suffix = getNumberSuffix(day)
  return `${day}${suffix}`
}

export const getDayOfWeek = date => {
  return DateTime.fromISO(date).weekdayLong
}

export const dollarFormat = amount => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount)
}
