import React from 'react'
import startCase from 'lodash/startCase'
import { Text } from '../atoms'

const RoutineStatus: React.FC<{ status: string }> = ({ status }) => {
  const getColor = () => {
    if (status === 'active') {
      return 'primary'
    }
    if (
      status === 'inactive' ||
      status === 'canceled' ||
      status === 'completed'
    ) {
      return 'textSubtle'
    }
    return 'error'
  }

  if (!status) return null

  return (
    <Text color={getColor()} size='xsmall'>
      {startCase(status.replace(/_/g, ' '))}
    </Text>
  )
}

export default RoutineStatus
