export const vgsStateOptions = [
  { value: 'AL', text: 'AL' },
  { value: 'AK', text: 'AK' },
  { value: 'AZ', text: 'AZ' },
  { value: 'AR', text: 'AR' },
  { value: 'CA', text: 'CA' },
  { value: 'CO', text: 'CO' },
  { value: 'CT', text: 'CT' },
  { value: 'DC', text: 'DC' },
  { value: 'DE', text: 'DE' },
  { value: 'FL', text: 'FL' },
  { value: 'GA', text: 'GA' },
  { value: 'HI', text: 'HI' },
  { value: 'ID', text: 'ID' },
  { value: 'IL', text: 'IL' },
  { value: 'IN', text: 'IN' },
  { value: 'IA', text: 'IA' },
  { value: 'KS', text: 'KS' },
  { value: 'KY', text: 'KY' },
  { value: 'LA', text: 'LA' },
  { value: 'ME', text: 'ME' },
  { value: 'MD', text: 'MD' },
  { value: 'MA', text: 'MA' },
  { value: 'MI', text: 'MI' },
  { value: 'MN', text: 'MN' },
  { value: 'MS', text: 'MS' },
  { value: 'MO', text: 'MO' },
  { value: 'MT', text: 'MT' },
  { value: 'NE', text: 'NE' },
  { value: 'NV', text: 'NV' },
  { value: 'NH', text: 'NH' },
  { value: 'NJ', text: 'NJ' },
  { value: 'NM', text: 'NM' },
  { value: 'NY', text: 'NY' },
  { value: 'NC', text: 'NC' },
  { value: 'ND', text: 'ND' },
  { value: 'OH', text: 'OH' },
  { value: 'OK', text: 'OK' },
  { value: 'OR', text: 'OR' },
  { value: 'PA', text: 'PA' },
  { value: 'RI', text: 'RI' },
  { value: 'SC', text: 'SC' },
  { value: 'SD', text: 'SD' },
  { value: 'TN', text: 'TN' },
  { value: 'TX', text: 'TX' },
  { value: 'UT', text: 'UT' },
  { value: 'VT', text: 'VT' },
  { value: 'VA', text: 'VA' },
  { value: 'WA', text: 'WA' },
  { value: 'WV', text: 'WV' },
  { value: 'WI', text: 'WI' },
  { value: 'WY', text: 'WY' },
]

export default [
  { label: 'Select State...', value: '0' },
  { label: 'AL', value: 'AL' },
  { label: 'AK', value: 'AK' },
  { label: 'AZ', value: 'AZ' },
  { label: 'AR', value: 'AR' },
  { label: 'CA', value: 'CA' },
  { label: 'CO', value: 'CO' },
  { label: 'CT', value: 'CT' },
  { label: 'DC', value: 'DC' },
  { label: 'DE', value: 'DE' },
  { label: 'FL', value: 'FL' },
  { label: 'GA', value: 'GA' },
  { label: 'HI', value: 'HI' },
  { label: 'ID', value: 'ID' },
  { label: 'IL', value: 'IL' },
  { label: 'IN', value: 'IN' },
  { label: 'IA', value: 'IA' },
  { label: 'KS', value: 'KS' },
  { label: 'KY', value: 'KY' },
  { label: 'LA', value: 'LA' },
  { label: 'ME', value: 'ME' },
  { label: 'MD', value: 'MD' },
  { label: 'MA', value: 'MA' },
  { label: 'MI', value: 'MI' },
  { label: 'MN', value: 'MN' },
  { label: 'MS', value: 'MS' },
  { label: 'MO', value: 'MO' },
  { label: 'MT', value: 'MT' },
  { label: 'NE', value: 'NE' },
  { label: 'NV', value: 'NV' },
  { label: 'NH', value: 'NH' },
  { label: 'NJ', value: 'NJ' },
  { label: 'NM', value: 'NM' },
  { label: 'NY', value: 'NY' },
  { label: 'NC', value: 'NC' },
  { label: 'ND', value: 'ND' },
  { label: 'OH', value: 'OH' },
  { label: 'OK', value: 'OK' },
  { label: 'OR', value: 'OR' },
  { label: 'PA', value: 'PA' },
  { label: 'RI', value: 'RI' },
  { label: 'SC', value: 'SC' },
  { label: 'SD', value: 'SD' },
  { label: 'TN', value: 'TN' },
  { label: 'TX', value: 'TX' },
  { label: 'UT', value: 'UT' },
  { label: 'VT', value: 'VT' },
  { label: 'VA', value: 'VA' },
  { label: 'WA', value: 'WA' },
  { label: 'WV', value: 'WV' },
  { label: 'WI', value: 'WI' },
  { label: 'WY', value: 'WY' },
  { label: 'AS', value: 'AS' },
  { label: 'FM', value: 'FM' },
  { label: 'GU', value: 'GU' },
  { label: 'MH', value: 'MH' },
  { label: 'MP', value: 'MP' },
  { label: 'PW', value: 'PW' },
  { label: 'PR', value: 'PR' },
  { label: 'VI', value: 'VI' },
]
