import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { WindowLocation } from '@reach/router'
import * as queryString from 'query-string'
import { Composition } from 'atomic-layout'
import { useForm } from 'react-hook-form'

import { useAppDispatch } from 'src/redux/store'
import AuthHeader from 'src/features/auth/AuthHeader'
import IconBulletPoint from 'src/components/IconBulletPoint'
import TermsOfService from 'src/features/auth/TermsOfService'
import { Button, Container } from 'src/atoms'
import {
  authorize,
  selectClient,
  selectHasAuthorized,
  selectIsLoggedIn,
  setHasAuthorized,
} from 'src/features/auth/authSlice'
import FullScreenSpinner from 'src/components/FullScreenSpinner'

const areasMobile = `
  header
  content
  legal
  actions
`

interface AuthorizeProps {
  location?: WindowLocation
}

const Authorize: React.FC<AuthorizeProps> = ({ location }) => {
  const dispatch = useAppDispatch()

  const client = useSelector(selectClient)
  const hasAuthorized = useSelector(selectHasAuthorized)
  const isLoggedIn = useSelector(selectIsLoggedIn)

  const {
    handleSubmit,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      legal: false,
    },
    mode: 'onChange',
  })

  useEffect(() => {
    // `location` tells us that this was returned by PrivateRoute
    // and is thus the final destination
    if (location && hasAuthorized) {
      const {
        client_id: clientId,
        redirect_uri: redirectUri,
        response_type: responseType,
        state,
      } = queryString.parse(location.search)

      dispatch(
        authorize({
          clientId: String(clientId),
          redirectUri: String(redirectUri),
          responseType: String(responseType),
          state: typeof state === 'string' ? state : '',
        }),
      )
    }
  }, [dispatch, hasAuthorized, location])

  const onAuthorize = () => {
    dispatch(setHasAuthorized(true))
  }

  if (hasAuthorized) {
    return <FullScreenSpinner />
  }

  return (
    <Composition areas={areasMobile} as={Container} paddingTop={30}>
      {({ Header, Content, Legal, Actions }) => (
        <>
          {client.name && (
            <Header marginBottom={20}>
              <AuthHeader name={client.name} />
            </Header>
          )}
          <Content
            as={Composition}
            gap={20}
            paddingHorizontal={10}
            marginBottom={30}
          >
            <IconBulletPoint
              title='Fast'
              subtitle='Astra accelerates settlement so your payments process as fast as possible.'
              name='rocket-outline'
            />
            <IconBulletPoint
              title='Secure'
              subtitle='Astra keeps your financial data safe with identity verification and bank-level security.'
              name='shield-checkmark-outline'
            />
          </Content>
          <Legal paddingHorizontal={10} marginBottom={20}>
            <hr
              style={{ width: '50%', margin: '0 auto', marginBottom: '20px' }}
            />
            <TermsOfService isLoggedIn={isLoggedIn} />
          </Legal>
          <Actions>
            <Button
              onClick={async e => {
                e.preventDefault()
                await handleSubmit(onAuthorize)(e)
              }}
              disabled={!isValid}
              fullWidth
            >
              {isLoggedIn ? 'Continue' : 'Authorize'}
            </Button>
          </Actions>
        </>
      )}
    </Composition>
  )
}

export default Authorize
