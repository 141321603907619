import styled from 'styled-components'
import { astraTheme } from '../styles/theme'

const InputLabel = styled.label<{ color?: keyof typeof astraTheme.colors }>`
  color: ${props => props.theme.colors[props.color || 'text']};
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 1rem;
  line-height: 1.5em;
`

export default InputLabel
