import { useEffect, useState } from 'react'
import {
  DeepRequired,
  FieldErrorsImpl,
  UseFormReturn,
  ValidationMode,
} from 'react-hook-form'
import * as queryString from 'query-string'
import * as Yup from 'yup'
import { useLocation } from '@reach/router'

import { phoneValiation, useFormHandler } from 'src/hooks/useFormHandler'
import { clearPhoneNumberFormatting, formatPhoneNumber } from 'src/utils/format'
import {
  attemptSignInWithCustomToken,
  clientSandboxLogin,
  selectClient,
  setIsSendingAuthCode,
  setPhoneNumber,
} from 'src/features/auth/authSlice'
import { useAppDispatch } from 'src/redux/store'
import { resetRecaptcha, signInWithPhoneNumber } from 'src/utils/firebase'
import { FirebaseError } from 'firebase/app'
import {
  handleFirebaseAuthError,
  leaveBugsnagBreadcrumb,
} from 'src/utils/logError'
import toast from 'react-hot-toast'
import { ConfirmationResult } from 'firebase/auth'
import { useSelector } from 'react-redux'
import { Environment } from 'src/features/auth/types'
import { unwrapResult } from '@reduxjs/toolkit'

export enum LoginFormKeys {
  PHONE_NUMBER = 'phoneNumber',
}

export interface LoginFormSchema {
  [LoginFormKeys.PHONE_NUMBER]: string
}

const requiredFields: Record<keyof LoginFormSchema, boolean> = {
  [LoginFormKeys.PHONE_NUMBER]: true,
}

const loginFormDefaultValues: LoginFormSchema = {
  [LoginFormKeys.PHONE_NUMBER]: '',
}

const validationSchema: Yup.SchemaOf<LoginFormSchema> = Yup.object().shape({
  [LoginFormKeys.PHONE_NUMBER]: phoneValiation,
})

const dontClearErrorsOnSuccess = false

// This option allows you to configure the validation strategy before a user submits the form (onSubmit event).
const formValidationMode: keyof ValidationMode = 'onChange'

/**
 * This hook is used to handle the process of the user filling out the `Login` form:
 *
 * * sets initial value of the form field
 * * handles submission of the form, this can include a POST request for the final form submission
 * * exports the `formHandler` (the form API, submit, event handling, etc.) and the `isSubmitDisabled` value
 *   which is used to determine whether the submit button is active.
 * */
export default function useLoginForm(): LoginFormReturnType {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const client = useSelector(selectClient)
  const {
    business_profile_id: businessProfileId,
    phone,
    user_id: userId,
    user_intent_id: userIntentId,
  } = queryString.parse(location.search)

  const [authConfirmationResult, setAuthConfirmationResult] = useState<
    ConfirmationResult
  >()

  // we set `defaultValues` somewhat asynchronously in the `useEffect` hook below
  // once these are set, another `useEffect` hook in `useFormHandler` hook will use
  // `reset` to reset the form anytime the default values change
  // defaultValues are needed in order to properly handle validation issues
  const [defaultValues, setDefaultValues] = useState<LoginFormSchema>({
    ...loginFormDefaultValues,
  })

  // TODO(worstestes - 12/3/23): determine how we designate a 'new' form, meaning the user hasn't completed this form before
  const isNewForm = true

  // SET INITIAL/DEFAULT FORM INPUT VALUES
  useEffect(() => {
    if (phone) {
      const phoneNumber = phone as string

      const formattedPhoneNumber = formatPhoneNumber(phoneNumber)

      setDefaultValues({
        ...defaultValues,
        [LoginFormKeys.PHONE_NUMBER]: formattedPhoneNumber,
      })
      formHandler.form.setValue(
        LoginFormKeys.PHONE_NUMBER,
        formattedPhoneNumber,
        {
          shouldValidate: true,
        },
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone])

  const onSubmitPhoneNumber = async (phone: string) => {
    if (!client.clientId) {
      toast.error('Invalid configuration.')
      return null
    }

    if (
      process.env.REACT_APP_RELEASE_STAGE !== Environment.PRODUCTION &&
      client.isSandboxPhoneNumbersEnabled
    ) {
      const business_profile_id = businessProfileId as string
      const user_id = userId as string
      const user_intent_id = userIntentId as string

      const customFirebaseToken = await dispatch(
        clientSandboxLogin({
          clientId: client.clientId,
          businessProfileId: business_profile_id,
          phone,
          userId: user_id,
          userIntentId: user_intent_id,
        }),
      ).then(unwrapResult)

      if (customFirebaseToken) {
        return dispatch(
          attemptSignInWithCustomToken({ token: customFirebaseToken }),
        )
      }
    }

    dispatch(setIsSendingAuthCode(true))

    const appVerifier = window.recaptchaVerifier
    const phoneNumber = clearPhoneNumberFormatting(
      formHandler.form.getValues()[LoginFormKeys.PHONE_NUMBER],
    )

    dispatch(setPhoneNumber(phoneNumber))

    leaveBugsnagBreadcrumb({
      message: 'Signing in with phone number',
      metadata: {
        recaptchaVerifier: Boolean(appVerifier),
      },
    })

    try {
      const confirmationResultData = await signInWithPhoneNumber(
        phoneNumber,
        appVerifier,
      )

      setAuthConfirmationResult(confirmationResultData)
      dispatch(setIsSendingAuthCode(false))
    } catch (error) {
      if (error instanceof FirebaseError) {
        handleFirebaseAuthError(error)
      } else if (error instanceof Error) {
        toast.error(error.message)
      }

      await resetRecaptcha()
      dispatch(setIsSendingAuthCode(false))
    }
  }

  const onSubmitAuthCode = async (authCode: string) => {
    leaveBugsnagBreadcrumb({ message: 'Confirming auth code' })
    dispatch(setIsSendingAuthCode(true))

    if (authConfirmationResult) {
      authConfirmationResult.confirm(authCode).catch(error => {
        handleFirebaseAuthError(error)
        dispatch(setIsSendingAuthCode(false))
      })
    }
  }

  const formHandler = useFormHandler<LoginFormSchema>(
    async () => {
      return null
    },
    defaultValues,
    validationSchema,
    dontClearErrorsOnSuccess,
    formValidationMode,
  )

  const { isValid, errors } = formHandler.form.formState

  return {
    ...formHandler,
    authConfirmationResult,
    defaultValues: formHandler.form.formState.defaultValues as LoginFormSchema,
    errors,
    isNewForm,
    isSubmitDisabled: !isValid,
    onSubmitPhoneNumber,
    onSubmitAuthCode,
    requiredFields,
  }
}

export type LoginFormReturnType = {
  authConfirmationResult?: ConfirmationResult
  defaultValues: LoginFormSchema
  errors: FieldErrorsImpl<DeepRequired<LoginFormSchema>>
  form: UseFormReturn<LoginFormSchema, any>
  isNewForm: boolean
  isSubmitDisabled: boolean
  onSubmitPhoneNumber: (phone: string) => any
  onSubmitAuthCode: (authCode: string) => Promise<void>
  requiredFields: Record<string, boolean>
}
