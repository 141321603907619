import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from 'atomic-layout'

import {
  clientsSelectors,
  getClients,
  selectOAuthClientsLoading,
} from './clientsSlice'
import ClientAppModule from './ClientAppModule'
import FullScreenSpinner from '../../components/FullScreenSpinner'
import NotFound from '../../pages/NotFound'

const ClientAppDetail = ({ id }) => {
  const dispatch = useDispatch()
  const clientApp = useSelector(state => clientsSelectors.selectById(state, id))
  const loading = useSelector(selectOAuthClientsLoading)

  useEffect(() => {
    dispatch(getClients())
  }, [dispatch])

  if (loading) {
    return <FullScreenSpinner />
  }

  if (!clientApp) {
    return <NotFound />
  }

  return (
    <Box width='100%'>
      <ClientAppModule clientApp={clientApp} />
    </Box>
  )
}

export default ClientAppDetail
