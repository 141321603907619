import styled, { css } from 'styled-components'
import Button from './Button'

const highlighted = css`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.textInverse};
  opacity: 1;
`

export default styled(Button)<{ active: boolean }>`
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primary};

  &:hover {
    ${highlighted}
  }

  ${props => props.active && highlighted}
`
