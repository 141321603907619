import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Composition } from 'atomic-layout'

import { selectUser } from 'src/features/auth/authSlice'
import {
  selectIsSubmitting,
  selectBusinessController,
} from 'src/features/verify/verifySlice'

import useBusinessControllerForm, {
  BusinessControllerFormKeys,
} from 'src/features/verify/business/BusinessController/useBusinessControllerForm'

import { Button, Container, Input, Text } from 'src/atoms'
import DateOfBirthField from 'src/components/DateOfBirthField'
import SocialSecurityField from 'src/components/SocialSecurityField'
import AddressForm from 'src/features/verify/AddressForm'

const areasMobile = `
  title
  subtitle
  form
`

const completedAreasMobile = `
  name edit
  title edit
  address1 edit
  address2 edit
  cityStateAndZip edit
`

interface VerifyBusinessControllerProps {
  isReadOnly: boolean
}

const BusinessController: React.FC<VerifyBusinessControllerProps> = ({
  isReadOnly,
}) => {
  const {
    controllerTitle,
    defaultValues,
    errors,
    form,
    isSubmitDisabled,
    isKybDelegated,
    onEdit,
    onSubmit,
  } = useBusinessControllerForm()

  const {
    firstName,
    lastName,
    email,
    title,
    address1,
    address2,
    city,
    state,
    zip,
  } = useSelector(selectBusinessController)
  const isSubmitting = useSelector(selectIsSubmitting)
  const user = useSelector(selectUser)

  const [formAreasMobile, setFormAreasMobile] = useState(`
  firstName
  lastName
  email
  title
  dateOfBirth
  socialSecurityNumber
  addressSection
  continue
`)

  useEffect(() => {
    if (isKybDelegated) {
      setFormAreasMobile(`
    firstName
    lastName
    email
    title
    addressSection
    continue
  `)
    }
  }, [isKybDelegated])

  if (isReadOnly) {
    return (
      <Composition
        areas={completedAreasMobile}
        as={Container}
        gap={3}
        alignItems='center'
        justifyContent='space-between'
      >
        {Areas => (
          <>
            <Areas.Name>
              <Text fontFamily='medium' color='textSubtle'>
                {firstName} {lastName}
              </Text>
            </Areas.Name>
            <Areas.Email>
              <Text fontFamily='medium' color='textSubtle'>
                {email}
              </Text>
            </Areas.Email>
            <Areas.Title>
              <Text fontFamily='medium' color='textSubtle'>
                {title}
              </Text>
            </Areas.Title>
            <Areas.Address1>
              <Text fontFamily='medium' color='textSubtle'>
                {address1}
              </Text>
            </Areas.Address1>
            {address2 && (
              <Areas.Address2>
                <Text fontFamily='medium' color='textSubtle'>
                  {address2}
                </Text>
              </Areas.Address2>
            )}
            <Areas.CityStateAndZip>
              <Composition areas='city state zip' gap={10}>
                {SubAreas => (
                  <>
                    <SubAreas.City>
                      <Text fontFamily='medium' color='textSubtle'>
                        {city}
                      </Text>
                    </SubAreas.City>
                    <SubAreas.State>
                      <Text fontFamily='medium' color='textSubtle'>
                        {state}
                      </Text>
                    </SubAreas.State>
                    <SubAreas.Zip>
                      <Text fontFamily='medium' color='textSubtle'>
                        {zip}
                      </Text>
                    </SubAreas.Zip>
                  </>
                )}
              </Composition>
            </Areas.CityStateAndZip>
            <Areas.Edit>
              <Text
                onClick={onEdit}
                color='primary'
                cursor='pointer'
                fontFamily='medium'
              >
                Edit
              </Text>
            </Areas.Edit>
          </>
        )}
      </Composition>
    )
  }

  return (
    <Composition areas={areasMobile} as={Container}>
      {Areas => (
        <>
          <Areas.Title marginBottom={3}>
            <Text fontFamily='heavy' size='large'>
              Verify {controllerTitle}
            </Text>
          </Areas.Title>
          <Areas.Subtitle marginBottom={15}>
            <Text fontFamily='medium' size='small' color='textSubtle'>
              Enter your {controllerTitle.toLocaleLowerCase()}&apos;s details.
            </Text>
          </Areas.Subtitle>
          <Areas.Form>
            <form onSubmit={onSubmit}>
              <Composition areas={formAreasMobile} gap={10}>
                {FormAreas => (
                  <>
                    <FormAreas.FirstName>
                      <Input
                        id={BusinessControllerFormKeys.FIRST_NAME}
                        type='text'
                        placeholder='First Name'
                        defaultValue={
                          defaultValues[BusinessControllerFormKeys.FIRST_NAME]
                        }
                        bordercolor={
                          errors[BusinessControllerFormKeys.FIRST_NAME] &&
                          'error'
                        }
                        {...form.register(
                          BusinessControllerFormKeys.FIRST_NAME,
                          {
                            required: true,
                          },
                        )}
                      />
                    </FormAreas.FirstName>
                    <FormAreas.LastName>
                      <Input
                        id={BusinessControllerFormKeys.LAST_NAME}
                        type='text'
                        placeholder='Last Name'
                        defaultValue={
                          defaultValues[BusinessControllerFormKeys.LAST_NAME]
                        }
                        bordercolor={
                          errors[BusinessControllerFormKeys.LAST_NAME] &&
                          'error'
                        }
                        {...form.register(
                          BusinessControllerFormKeys.LAST_NAME,
                          {
                            required: true,
                          },
                        )}
                      />
                    </FormAreas.LastName>
                    <FormAreas.Email>
                      <Input
                        id={BusinessControllerFormKeys.EMAIL}
                        type='text'
                        placeholder='Email Address'
                        defaultValue={
                          defaultValues[BusinessControllerFormKeys.EMAIL]
                        }
                        bordercolor={
                          errors[BusinessControllerFormKeys.EMAIL] && 'error'
                        }
                        {...form.register(BusinessControllerFormKeys.EMAIL, {
                          required: true,
                        })}
                      />
                    </FormAreas.Email>
                    <FormAreas.Title>
                      <Input
                        id={BusinessControllerFormKeys.TITLE}
                        type='text'
                        placeholder='Title'
                        defaultValue={
                          defaultValues[BusinessControllerFormKeys.TITLE]
                        }
                        bordercolor={
                          errors[BusinessControllerFormKeys.TITLE] && 'error'
                        }
                        {...form.register(BusinessControllerFormKeys.TITLE, {
                          required: true,
                        })}
                      />
                    </FormAreas.Title>
                    {isKybDelegated ? null : (
                      <>
                        <FormAreas.DateOfBirth>
                          <DateOfBirthField
                            control={form.control}
                            defaultValue={
                              defaultValues[
                                BusinessControllerFormKeys.DATE_OF_BIRTH
                              ] as string
                            }
                            error={
                              errors[BusinessControllerFormKeys.DATE_OF_BIRTH]
                            }
                            id={BusinessControllerFormKeys.DATE_OF_BIRTH}
                            {...form.register(
                              BusinessControllerFormKeys.DATE_OF_BIRTH,
                              {
                                required: true,
                              },
                            )}
                          />
                        </FormAreas.DateOfBirth>
                        <FormAreas.SocialSecurityNumber>
                          <SocialSecurityField
                            control={form.control}
                            id={BusinessControllerFormKeys.SSN}
                            name={BusinessControllerFormKeys.SSN}
                            requireFullNumber={user.status === 'retry'}
                            defaultValue={
                              defaultValues[
                                BusinessControllerFormKeys.SSN
                              ] as any
                            }
                            error={errors[BusinessControllerFormKeys.SSN]}
                          />
                        </FormAreas.SocialSecurityNumber>
                      </>
                    )}
                    <FormAreas.AddressSection>
                      <AddressForm
                        control={form.control}
                        defaultValues={defaultValues}
                        errors={errors}
                        register={form.register}
                      />
                    </FormAreas.AddressSection>
                    <FormAreas.Continue>
                      <Button
                        type='submit'
                        disabled={isSubmitDisabled}
                        fullWidth
                      >
                        {isSubmitting ? 'Sending...' : 'Continue'}
                      </Button>
                    </FormAreas.Continue>
                  </>
                )}
              </Composition>
            </form>
          </Areas.Form>
        </>
      )}
    </Composition>
  )
}

export default BusinessController
