import { navigate } from '@reach/router'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from 'src/features/auth/authSlice'
import { UserType } from 'src/features/auth/types'
import {
  selectIframeOrigin,
  sendClosedMessage,
} from 'src/features/sdkMessaging/sdkMessagingSlice'

import BusinessAddress from 'src/features/verify/business/BusinessAddress'
import BusinessAdmin from 'src/features/verify/business/BusinessAdmin'
import BusinessController from 'src/features/verify/business/BusinessController'
import BusinessInfo from 'src/features/verify/business/BusinessInfo'
import {
  selectBusinessFlowStep,
  BusinessFlowStepIndex,
} from 'src/features/verify/verifySlice'
import { useAppDispatch } from 'src/redux/store'
import * as queryString from 'query-string'

interface VerifyBusinessProps {
  location: Location
}

const VerifyBusiness: React.FC<VerifyBusinessProps> = ({ location }) => {
  const dispatch = useAppDispatch()
  const businessFlowStep = useSelector(selectBusinessFlowStep)
  const user = useSelector(selectUser)
  const iframeOrigin = useSelector(selectIframeOrigin)

  const {
    continue_to: continueTo,
    redirect_uri: redirectUri,
    return_to: returnTo,
  } = queryString.parse(location.search)

  useEffect(() => {
    if (user.userType !== UserType.BUSINESS) {
      if (iframeOrigin) {
        dispatch(sendClosedMessage())
      } else {
        const navigateTo = (continueTo ||
          returnTo ||
          redirectUri ||
          '/') as string
        navigate(navigateTo)
      }
    }
  }, [continueTo, dispatch, iframeOrigin, redirectUri, returnTo, user.userType])

  return (
    <>
      <BusinessInfo
        isReadOnly={businessFlowStep !== BusinessFlowStepIndex.INFO}
      />
      {businessFlowStep > BusinessFlowStepIndex.INFO && (
        <BusinessAddress
          isReadOnly={businessFlowStep !== BusinessFlowStepIndex.ADDRESS}
        />
      )}

      {businessFlowStep > BusinessFlowStepIndex.ADDRESS && (
        <BusinessAdmin
          isReadOnly={businessFlowStep !== BusinessFlowStepIndex.ADMIN}
        />
      )}

      {businessFlowStep > BusinessFlowStepIndex.ADMIN && (
        <BusinessController
          isReadOnly={businessFlowStep !== BusinessFlowStepIndex.CONTROLLER}
        />
      )}
    </>
  )
}

export default VerifyBusiness
