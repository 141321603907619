import { FormEvent, useEffect, useState } from 'react'
import {
  DeepRequired,
  FieldErrorsImpl,
  UseFormReturn,
  ValidationMode,
} from 'react-hook-form'
import { useSelector } from 'react-redux'

import * as Yup from 'yup'
import {
  dateOfBirthValidation,
  emailValidation,
  useFormHandler,
  zipCodeValidation,
} from 'src/hooks/useFormHandler'
import { useAppDispatch } from 'src/redux/store'
import {
  BusinessFlowStepIndex,
  setAddBeneficialOwner,
  setBusinessFlowStep,
  selectIsKybDelegated,
} from 'src/features/verify/verifySlice'

export enum BeneficialOwnerFormKeys {
  ADDRESS_1 = 'address1',
  ADDRESS_2 = 'address2',
  CITY = 'city',
  DATE_OF_BIRTH = 'dateOfBirth',
  EMAIL = 'email',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  SSN = 'ssn',
  STATE = 'state',
  ZIP = 'zip',
}

export interface BeneficialOwnerFormSchema {
  [BeneficialOwnerFormKeys.ADDRESS_1]: string
  [BeneficialOwnerFormKeys.ADDRESS_2]?: string
  [BeneficialOwnerFormKeys.CITY]: string
  [BeneficialOwnerFormKeys.DATE_OF_BIRTH]: string
  [BeneficialOwnerFormKeys.EMAIL]: string
  [BeneficialOwnerFormKeys.FIRST_NAME]: string
  [BeneficialOwnerFormKeys.LAST_NAME]: string
  [BeneficialOwnerFormKeys.SSN]: string
  [BeneficialOwnerFormKeys.STATE]: string
  [BeneficialOwnerFormKeys.ZIP]: string
}

const requiredFields: Record<keyof BeneficialOwnerFormSchema, boolean> = {
  [BeneficialOwnerFormKeys.ADDRESS_1]: true,
  [BeneficialOwnerFormKeys.ADDRESS_2]: false,
  [BeneficialOwnerFormKeys.CITY]: true,
  [BeneficialOwnerFormKeys.DATE_OF_BIRTH]: true,
  [BeneficialOwnerFormKeys.EMAIL]: true,
  [BeneficialOwnerFormKeys.FIRST_NAME]: true,
  [BeneficialOwnerFormKeys.LAST_NAME]: true,
  [BeneficialOwnerFormKeys.SSN]: true,
  [BeneficialOwnerFormKeys.STATE]: true,
  [BeneficialOwnerFormKeys.ZIP]: true,
}

export const businessAddressFormDefaultValues: BeneficialOwnerFormSchema = {
  [BeneficialOwnerFormKeys.ADDRESS_1]: '',
  [BeneficialOwnerFormKeys.ADDRESS_2]: '',
  [BeneficialOwnerFormKeys.CITY]: '',
  [BeneficialOwnerFormKeys.DATE_OF_BIRTH]: '',
  [BeneficialOwnerFormKeys.EMAIL]: '',
  [BeneficialOwnerFormKeys.FIRST_NAME]: '',
  [BeneficialOwnerFormKeys.LAST_NAME]: '',
  [BeneficialOwnerFormKeys.SSN]: '',
  [BeneficialOwnerFormKeys.STATE]: '',
  [BeneficialOwnerFormKeys.ZIP]: '',
}

const createValidationSchema = ({
  isKybDelegated,
}: {
  isKybDelegated: boolean
}): Yup.SchemaOf<BeneficialOwnerFormSchema> => {
  const schemaFields: Partial<Record<keyof BeneficialOwnerFormSchema, any>> = {
    [BeneficialOwnerFormKeys.ADDRESS_1]: Yup.string().required(),
    [BeneficialOwnerFormKeys.ADDRESS_2]: Yup.string(),
    [BeneficialOwnerFormKeys.CITY]: Yup.string().required(),
    [BeneficialOwnerFormKeys.FIRST_NAME]: Yup.string().required(),
    [BeneficialOwnerFormKeys.LAST_NAME]: Yup.string().required(),
    [BeneficialOwnerFormKeys.STATE]: Yup.string().required(),
    [BeneficialOwnerFormKeys.ZIP]: zipCodeValidation,
  }

  if (!isKybDelegated) {
    schemaFields[BeneficialOwnerFormKeys.DATE_OF_BIRTH] = dateOfBirthValidation
    schemaFields[BeneficialOwnerFormKeys.EMAIL] = emailValidation
    schemaFields[BeneficialOwnerFormKeys.SSN] = Yup.string()
      .required()
      .when((ssn: string, schema: any) => {
        return schema.test(
          'test SSN length',
          `The 'last four digits of the'
          Social Security Number is required.`,
          (text: string) => {
            const length = text.trim().replace(/-/g, '').length
            if (length < 4) {
              return false
            }

            return true
          },
        )
      })
  }

  return Yup.object().shape(schemaFields as any)
}

const dontClearErrorsOnSuccess = false

// This option allows you to configure the validation strategy before a user submits the form (onSubmit event).
const formValidationMode: keyof ValidationMode = 'onChange'

/**
 * This hook is used to handle the process of the user filling out the `BeneficialOwner` form:
 *
 * * sets initial value of the form field
 * * handles submission of the form, this can include a POST request for the final form submission
 * * exports the `formHandler` (the form API, submit, event handling, etc.) and the `isSubmitDisabled` value
 *   which is used to determine whether the submit button is active.
 * */
export default function useBeneficialOwnerForm(): BeneficialOwnerFormReturnType {
  const dispatch = useAppDispatch()
  const isKybDelegated = useSelector(selectIsKybDelegated)

  // we set `defaultValues` somewhat asynchronously in the `useEffect` hook below
  // once these are set, another `useEffect` hook in `useFormHandler` hook will use
  // `reset` to reset the form anytime the default values change
  // defaultValues are needed in order to properly handle validation issues
  const [defaultValues, setDefaultValues] = useState<BeneficialOwnerFormSchema>(
    {
      ...businessAddressFormDefaultValues,
    },
  )

  // TODO(worstestes - 12/3/23): determine how we designate a 'new' form, meaning the user hasn't completed this form before
  const isNewForm = true

  // SET INITIAL/DEFAULT FORM INPUT VALUES
  useEffect(() => {
    setDefaultValues({
      ...defaultValues,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = (data: BeneficialOwnerFormSchema): void => {
    dispatch(setAddBeneficialOwner(data))

    formHandler.form.reset(defaultValues)
  }

  const onEdit = () => {
    dispatch(setBusinessFlowStep(BusinessFlowStepIndex.ADDRESS))
  }

  const formHandler = useFormHandler<BeneficialOwnerFormSchema>(
    async data => {
      return onSubmit(data)
    },
    defaultValues,
    createValidationSchema({
      isKybDelegated: isKybDelegated,
    }) as any,
    dontClearErrorsOnSuccess,
    formValidationMode,
  )

  const { isValid, errors } = formHandler.form.formState

  return {
    ...formHandler,
    defaultValues: formHandler.form.formState
      .defaultValues as BeneficialOwnerFormSchema,
    errors,
    isKybDelegated: isKybDelegated,
    isNewForm,
    isSubmitDisabled: !isValid,
    onEdit,
    requiredFields,
  }
}

export type BeneficialOwnerFormReturnType = {
  defaultValues: BeneficialOwnerFormSchema
  errors: FieldErrorsImpl<DeepRequired<BeneficialOwnerFormSchema>>
  form: UseFormReturn<BeneficialOwnerFormSchema, any>
  isKybDelegated: boolean
  isNewForm: boolean
  isSubmitDisabled: boolean
  onEdit: () => void
  onSubmit: (e?: FormEvent<Element> | undefined) => Promise<void>
  requiredFields: Record<string, boolean>
}
