import React, { Component, useRef } from 'react'
import styled from 'styled-components'
import { Text } from '../atoms'
import useDetectOutsideClick from '../hooks/useDetectOutsideClick'

const Container = styled.div`
  position: relative;
  padding-left: 20px;
`

const ButtonContainer = styled.div`
  cursor: pointer;
`

const Menu = styled.div<{ isActive: boolean }>`
  background: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 5px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
  position: absolute;
  right: 0;
  opacity: ${props => (props.isActive ? 1 : 0)};
  transition: opacity 0.2s ease, visibility 0.2s;
  visibility: ${props => (props.isActive ? 'visible' : 'hidden')};
  width: 200px;
`

const MenuItemContainer = styled.div`
  cursor: pointer;
  padding: 8px;

  &:hover {
    background: ${({ theme }) => theme.colors.backgroundTinted};
  }

  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`

interface MenuItemProps {
  onClick: () => void
  name: string
  isDangerous: boolean
}
const MenuItem: React.FC<MenuItemProps> = ({ onClick, name, isDangerous }) => (
  <MenuItemContainer onClick={onClick}>
    <Text
      color={isDangerous ? 'error' : 'text'}
      fontFamily='medium'
      size='small'
    >
      {name}
    </Text>
  </MenuItemContainer>
)

interface MenuProps {
  Item: React.FC<MenuItemProps>
}
const DropdownMenu: React.FC<{ button: Component }> & MenuProps = ({
  button,
  children,
}) => {
  const dropdownRef = useRef(null)
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false)
  const onClick = () => setIsActive(!isActive)

  return (
    <Container>
      <ButtonContainer onClick={onClick}>{button}</ButtonContainer>
      <Menu ref={dropdownRef} isActive={isActive}>
        {React.Children.map(children, child => {
          return child
        })}
      </Menu>
    </Container>
  )
}

DropdownMenu.Item = MenuItem

export default DropdownMenu
