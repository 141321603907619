import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Composition } from 'atomic-layout'
import styled from 'styled-components'

import DebitCardRow from 'src/features/accounts/DebitCardRow'
import Count from 'src/components/Count'
import Modal from 'src/components/Modal'
import Spinner from 'src/components/Spinner'
import VgsCollectForm from 'src/features/accounts/VgsCollectForm'
import { Button, Container, IonIcon, Text } from 'src/atoms'
import {
  getAddresses,
  selectAddressesLoading,
} from 'src/features/accounts/addressesSlice'
import { selectClient, selectUser } from 'src/features/auth/authSlice'
import {
  cardsSelectors,
  getCards,
  selectCardsLoading,
} from 'src/features/accounts/cardsSlice'
import { User, UserType } from 'src/features/auth/types'

const areasMobile = `
  header
  connectForm
  list
`

const AddDebitCardButton = styled(Button)`
  background: ${({ theme }) => theme.colors.background};
  border: 0;
  padding: 0;
`

const DebitCards: React.FC = () => {
  const dispatch = useDispatch()

  const debitCards = useSelector(cardsSelectors.selectAll)
  const addressesLoading = useSelector(selectAddressesLoading)
  const cardsLoading = useSelector(selectCardsLoading)
  const loading = addressesLoading || cardsLoading
  const clientId = useSelector(selectClient).clientId

  const user = useSelector(selectUser) as User
  const isBusinessUser = user.userType === UserType.BUSINESS

  const [showConnectForm, setShowConnectForm] = useState(false)

  useEffect(() => {
    dispatch(getAddresses())
    dispatch(getCards({ clientId }))
  }, [clientId, dispatch])

  return (
    <Composition areas={areasMobile} as={Container} paddingHorizontal={0}>
      {({ Header, ConnectForm, List }) => (
        <>
          <Header flex alignItems='center' paddingHorizontal={18}>
            <Text fontFamily='heavy' size='large'>
              Debit Cards
            </Text>
            <Box marginHorizontal={10}>
              <Count count={debitCards.length} loading={loading} />
            </Box>
            <AddDebitCardButton onClick={() => setShowConnectForm(true)}>
              <IonIcon name='add-circle' color='accent' size={30} />
            </AddDebitCardButton>
          </Header>
          <ConnectForm>
            <Modal
              ariaLabel={
                isBusinessUser
                  ? 'Connect Business Debit Card'
                  : 'Connect Debit Card'
              }
              isOpen={showConnectForm}
              close={() => setShowConnectForm(false)}
            >
              <VgsCollectForm
                onClose={() => setShowConnectForm(false)}
                debitDirect={false}
              />
            </Modal>
          </ConnectForm>
          {loading ? (
            <Box
              flex
              alignItems='center'
              justifyContent='center'
              paddingVertical={50}
            >
              <Spinner />
            </Box>
          ) : (
            <List marginTop={20} marginBottom={10}>
              {debitCards &&
                debitCards.map((card: any) => (
                  <DebitCardRow key={card.id} card={card} isDisabled={false} />
                ))}
            </List>
          )}
        </>
      )}
    </Composition>
  )
}

export default DebitCards
