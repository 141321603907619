import React from 'react'
import { Router } from '@reach/router'
import { Box } from 'atomic-layout'
import styled from 'styled-components'

import Accounts from 'src/features/accounts/Accounts'
import AccountDetail from 'src/features/accounts/AccountDetail'
import Authorize from 'src/features/auth/Authorize'
import BeneficialOwners from 'src/features/verify/business/BeneficialOwners'
import ClientApps from 'src/features/clients/ClientApps'
import ClientAppDetail from 'src/features/clients/ClientAppDetail'
import ConnectAccounts from 'src/features/accounts/ConnectAccounts'
import ConnectDebitCard from 'src/features/accounts/ConnectDebitCard'
import Dashboard from 'src/pages/Dashboard'
import DebitCards from 'src/features/accounts/DebitCards'
import DebitCardDetail from 'src/features/accounts/DebitCardDetail'
import Login from 'src/features/auth/Login'
import NotFound from 'src/pages/NotFound'
import PrivateRoute from 'src/components/PrivateRoute'
import Rejected from 'src/features/auth/Rejected'
import Routines from 'src/features/routines/Routines'
import RoutineDetail from 'src/features/routines/RoutineDetail'
import Transfers from 'src/features/transfers/Transfers'
import TransferDetail from 'src/features/transfers/TransferDetail'
import VerifyBusiness from 'src/features/verify/business/VerifyBusiness'
import VerifyDocument from 'src/features/verify/VerifyDocument'
import VerifyPersonal from 'src/features/verify/personal/VerifyPersonal'

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 380px;
  margin: 0 auto auto;
  padding: 0 15px;
`

const AstraRouter = () => (
  <Container as={Router}>
    <PrivateRoute path='/' component={Dashboard} />
    <PrivateRoute path='/connect' component={ConnectAccounts} />
    <PrivateRoute path='/cards' component={DebitCards} />
    <PrivateRoute path='/cards/:id' component={DebitCardDetail} />
    <PrivateRoute path='/cards/connect' component={ConnectDebitCard} />
    <PrivateRoute path='/accounts' component={Accounts} />
    <PrivateRoute path='/accounts/:id' component={AccountDetail} />
    <PrivateRoute path='/transfers' component={Transfers} />
    <PrivateRoute path='/transfers/:id' component={TransferDetail} />
    <PrivateRoute path='/routines' component={Routines} />
    <PrivateRoute path='/routines/:id' component={RoutineDetail} />
    <PrivateRoute path='/apps' component={ClientApps} />
    <PrivateRoute path='/apps/:id' component={ClientAppDetail} />
    <PrivateRoute path='/verify/personal' component={VerifyPersonal} />
    <PrivateRoute path='/verify/business' component={VerifyBusiness} />
    <PrivateRoute
      path='/verify/beneficial-owners'
      component={BeneficialOwners}
    />
    <PrivateRoute path='/verify/document' component={VerifyDocument} />
    <Login path='/login' />
    <PrivateRoute path='/login/oauth/authorize' component={Authorize} />
    <Rejected path='/rejected' />
    <NotFound default />
  </Container>
)

export default AstraRouter
