import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Flex,
} from '@chakra-ui/react'

import { Button, IonIcon } from 'src/atoms'

import useInactivityTimeout from 'src/features/auth/InactivityModal/useInactivityTimeout'
import ConfirmedSignoutModal from 'src/features/auth/InactivityModal/ConfirmedSignoutModal'
import { useSelector } from 'react-redux'
import { selectIsLoggedIn } from 'src/features/auth/authSlice'
import { astraTheme } from 'src/styles/theme'

const InactivityModal: React.FC<any> = () => {
  const {
    inactivityModalCountdown,
    isConfirmedSignoutModalVisible,
    isInactivityModalVisible,
    onDismiss,
    onHandleSignout,
  } = useInactivityTimeout()

  const isLoggedIn = useSelector(selectIsLoggedIn)

  if (!isLoggedIn && isConfirmedSignoutModalVisible) {
    return <ConfirmedSignoutModal onDismiss={onDismiss} />
  }

  return (
    <Modal isOpen={isInactivityModalVisible} size='lg' onClose={() => null}>
      <ModalOverlay />
      <ModalContent style={{ margin: 'auto', padding: 10 }}>
        <ModalHeader fontSize={20} fontWeight='bold'>
          <Flex alignItems='center'>
            <IonIcon
              size={25}
              name='time-outline'
              style={{ color: astraTheme.colors.info, marginRight: 5 }}
            />
            Session Timeout Warning
          </Flex>
        </ModalHeader>
        <ModalBody>
          You&apos;ve been inactive for a while. To keep your information safe,
          we will log you out of your account in {inactivityModalCountdown}{' '}
          seconds. Do you want to continue your session?
        </ModalBody>
        <ModalFooter>
          <Button onClick={onDismiss} title='Continue' style={{ width: 100 }}>
            Continue
          </Button>
          <Button
            backgroundColor='border'
            color='text'
            onClick={onHandleSignout}
            title='Cancel'
            style={{
              marginLeft: '12px',
              borderColor: astraTheme.colors.border,
              width: 100,
            }}
          >
            Signout
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default InactivityModal
