import _ from 'lodash'

// get oauth_client from each item in the array and return a unique list
export const extractOAuthClients = arr => {
  return _.chain(arr)
    .filter(x => x.oauth_client)
    .uniqBy('client_id')
    .map(x => x.oauth_client)
    .value()
}

// remove oauth_client from each item in the array
export const removeOAuthClientsFromArr = arr => {
  return arr.map(x => {
    if (x.oauth_client) {
      x.oauth_client_id = x.oauth_client.client_id
      delete x.oauth_client
    }
    return x
  })
}
