import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Flex } from '@chakra-ui/react'

import Logo from './Logo'
import PhoneNumberDisplay from './PhoneNumberDisplay'
import { Text } from '../atoms'
import {
  logout,
  selectFirebaseToken,
  selectUser,
} from '../features/auth/authSlice'

const Footer: React.FC = () => {
  const dispatch = useDispatch()

  const firebaseToken = useSelector(selectFirebaseToken)
  const user = useSelector(selectUser)

  return (
    <Flex direction='column' align='center' as='footer' paddingY='20px'>
      {user?.phone || firebaseToken ? (
        <Box marginTop={1}>
          {user?.phone && (
            <Flex justifyContent='center'>
              <PhoneNumberDisplay phoneNumber={user.phone} size='xsmall' />
            </Flex>
          )}
          {firebaseToken && (
            <Flex justifyContent='center'>
              <Text
                color='primary'
                fontFamily='light'
                lineHeight='.8em'
                size='xsmall'
              >
                Not you?&nbsp;
              </Text>
              <Text
                color='primary'
                cursor='pointer'
                fontFamily='medium'
                lineHeight='.8em'
                size='xsmall'
                onClick={() => dispatch(logout({ shouldRedirect: true }))}
              >
                {<u>Sign out</u>}
              </Text>
            </Flex>
          )}
        </Box>
      ) : (
        <Logo />
      )}
    </Flex>
  )
}

export default Footer
