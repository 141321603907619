import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import styled from 'styled-components'
import { Box } from 'atomic-layout'
import { navigate } from '@reach/router'

import FullScreenSpinner from '../../components/FullScreenSpinner'
import NotFound from '../../pages/NotFound'
import DropdownMenu from '../../components/DropdownMenu'
import { Container, Text } from '../../atoms'
import { selectClient } from '../auth/authSlice'
import {
  cardsSelectors,
  getCards,
  removeCard,
  selectCardsLoading,
  selectIsRemovingCard,
} from './cardsSlice'

const Block = styled(Box)`
  flex: 0 0 50%;
  border-right: ${props =>
    props.divider ? `1px solid ${props.theme.colors.border}` : 0};
  padding: 12px 14px;
`

const DebitCardDetail = ({ id }) => {
  const dispatch = useDispatch()
  const card = useSelector(state => cardsSelectors.selectById(state, id))
  const cardsLoading = useSelector(selectCardsLoading)
  const isRemoving = useSelector(selectIsRemovingCard)
  const clientId = useSelector(selectClient).clientId

  useEffect(() => {
    if (!card) dispatch(getCards({ clientId }))
  }, [card, clientId, dispatch])

  if (!card && cardsLoading) {
    return <FullScreenSpinner />
  }

  if (!card) {
    return <NotFound />
  }

  return (
    <Box width='100%'>
      <Box paddingHorizontal={10}>
        <Box flex alignItems='center'>
          <Box flexGrow={1}>
            <Text fontFamily='heavy' size='large' textAlign='left'>
              {card.card_company}
            </Text>
          </Box>
          <DropdownMenu
            button={
              <ion-icon name='ellipsis-horizontal' style={{ fontSize: 25 }} />
            }
          >
            <DropdownMenu.Item
              onClick={() => {
                dispatch(removeCard({ id }))
                  .then(unwrapResult)
                  .then(() => {
                    navigate('/cards')
                  })
              }}
              name={isRemoving ? 'Removing...' : 'Remove'}
              isDangerous
            />
          </DropdownMenu>
        </Box>
      </Box>
      <Container padding={0} marginTop={20} marginBottom={35}>
        <Box flex>
          <Block divider>
            <Text color='textSubtle' size='xsmall'>
              Last 4 Digits
            </Text>
            <Text fontFamily='heavy' lineHeight='24px' size='large'>
              {card.last_four_digits}
            </Text>
          </Block>
          <Block>
            <Text color='textSubtle' size='xsmall'>
              Expiration Date
            </Text>
            <Text fontFamily='heavy' lineHeight='24px' size='large'>
              {card.expiration_date}
            </Text>
          </Block>
        </Box>
      </Container>
    </Box>
  )
}

export default DebitCardDetail
