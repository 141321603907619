export const getRoutineIconData = type => {
  switch (type) {
    case 'one-time':
      return {
        name: 'routine-one-time',
        size: 16,
      }
    case 'recurring':
      return {
        name: 'routine-recurring',
        size: 21,
      }
    case 'sweep':
      return {
        name: 'routine-sweep',
        size: 22,
      }
    case 'percentage-based':
      return {
        name: 'routine-percentage',
        size: 20,
      }
    case 'percentage-balance':
      return {
        name: 'percentage-balance',
        size: 24,
      }
    case 'refill':
      return {
        name: 'refill',
        size: 24,
      }
    case 'fifty-two-week':
      return {
        name: 'routine-fifty-two-week',
        size: 17,
      }
    case 'round-up':
      return {
        name: 'routine-round-up',
        size: 24,
      }
    case 'balance-notification':
      return {
        name: 'routine-balance-notification',
        size: 24,
      }
    case 'threshold-notification':
      return {
        name: 'routine-threshold-notification',
        size: 21,
      }
    case 'income-notification':
      return {
        name: 'routine-deposit-notification',
        size: 24,
      }
    default:
      return {
        name: 'routine-one-time',
        size: 16,
      }
  }
}
