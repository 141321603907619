import React, { useState } from 'react'
import styled from 'styled-components'
import { Box } from 'atomic-layout'

import { Link, Text } from 'src/atoms'
import { OAuthClient } from 'src/features/auth/types'

const Container = styled(Box)`
  padding: 15px 18px;
  &:hover {
    background: ${({ theme }) => theme.colors.backgroundTinted};
  }
`

const ClientLogo = styled.img`
  border-radius: 22px;
  height: 44px;
  width: 44px;
`

const FallbackIcon = styled(Box)`
  border-radius: 22px;
  height: 44px;
  width: 44px;
  background-color: #eee;
`

interface ClientAppRowProps {
  clientApp: OAuthClient
}

const ClientAppRow: React.FC<ClientAppRowProps> = ({ clientApp }) => {
  const [imageFailed, setImageFailed] = useState(false)

  const handleImageError = () => {
    setImageFailed(true)
  }

  return (
    <Link to={`/apps/${clientApp.client_id}`}>
      <Container flex alignItems='center'>
        {imageFailed ? (
          <FallbackIcon />
        ) : (
          <ClientLogo
            src={clientApp.oauth_logo_url}
            alt={`${clientApp.name} logo`}
            onError={handleImageError}
          />
        )}
        <Box marginLeft={12}>
          <Text fontFamily='medium' size='small'>
            {clientApp.name}
          </Text>
        </Box>
      </Container>
    </Link>
  )
}

export default ClientAppRow
