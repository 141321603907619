import styled, { css } from 'styled-components'
import NumberFormat from 'react-number-format'
import { astraTheme } from '../styles/theme'

const style = css<{ bordercolor?: keyof typeof astraTheme.colors }>`
  border: 1px solid
    ${props => props.theme.colors[props.bordercolor || 'border']};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.text};
  font-family: ${({ theme }) => theme.fonts.book};
  font-size: 1rem;
  padding: 0.75rem;
  width: 100%;
  max-width: 20rem;
`

export const Input = styled.input`
  ${style}
`

export const MaskedInput = styled(NumberFormat)`
  ${style}
`
