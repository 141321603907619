import { FormEvent, useEffect, useState } from 'react'
import {
  DeepRequired,
  FieldErrorsImpl,
  UseFormReturn,
  ValidationMode,
} from 'react-hook-form'
import { useSelector } from 'react-redux'

import * as Yup from 'yup'
import {
  dateOfBirthValidation,
  emailValidation,
  useFormHandler,
  zipCodeValidation,
} from 'src/hooks/useFormHandler'
import { useAppDispatch } from 'src/redux/store'
import { convertDateFromISO } from 'src/utils/format'
import {
  BusinessFlowStepIndex,
  selectBusinessAdmin,
  selectBusinessInfo,
  selectIsControllerRequired,
  setBusinessFlowStep,
  setBusinessAdmin,
  verifyBusiness,
  finishVerifyBusinessProcess,
  selectIsKybDelegated,
} from 'src/features/verify/verifySlice'
import { BusinessType } from 'src/features/verify/types'

export enum BusinessAdminFormKeys {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  DATE_OF_BIRTH = 'dateOfBirth',
  SSN = 'ssn',
  ADDRESS_1 = 'address1',
  ADDRESS_2 = 'address2',
  CITY = 'city',
  STATE = 'state',
  ZIP = 'zip',
}

export interface BusinessAdminFormSchema {
  [BusinessAdminFormKeys.FIRST_NAME]: string
  [BusinessAdminFormKeys.LAST_NAME]: string
  [BusinessAdminFormKeys.EMAIL]: string
  [BusinessAdminFormKeys.DATE_OF_BIRTH]?: string
  [BusinessAdminFormKeys.SSN]?: string
  [BusinessAdminFormKeys.ADDRESS_1]?: string
  [BusinessAdminFormKeys.ADDRESS_2]?: string
  [BusinessAdminFormKeys.CITY]?: string
  [BusinessAdminFormKeys.STATE]?: string
  [BusinessAdminFormKeys.ZIP]?: string
}

const businessAdminFormDefaultValues: BusinessAdminFormSchema = {
  [BusinessAdminFormKeys.FIRST_NAME]: '',
  [BusinessAdminFormKeys.LAST_NAME]: '',
  [BusinessAdminFormKeys.EMAIL]: '',
  [BusinessAdminFormKeys.DATE_OF_BIRTH]: '',
  [BusinessAdminFormKeys.SSN]: '',
  [BusinessAdminFormKeys.ADDRESS_1]: '',
  [BusinessAdminFormKeys.ADDRESS_2]: '',
  [BusinessAdminFormKeys.CITY]: '',
  [BusinessAdminFormKeys.STATE]: '',
  [BusinessAdminFormKeys.ZIP]: '',
}

const createFormConfig = ({
  businessType,
  isKybDelegated,
}: {
  businessType: BusinessType
  isKybDelegated: boolean
}) => {
  if (businessType === BusinessType.SOLE_PROPRIETORSHIP) {
    return {
      fieldVisibility: {
        [BusinessAdminFormKeys.FIRST_NAME]: true,
        [BusinessAdminFormKeys.LAST_NAME]: true,
        [BusinessAdminFormKeys.EMAIL]: true,
        [BusinessAdminFormKeys.DATE_OF_BIRTH]: isKybDelegated ? false : true,
        [BusinessAdminFormKeys.SSN]: isKybDelegated ? false : true,
        [BusinessAdminFormKeys.ADDRESS_1]: true,
        [BusinessAdminFormKeys.ADDRESS_2]: true,
        [BusinessAdminFormKeys.CITY]: true,
        [BusinessAdminFormKeys.STATE]: true,
        [BusinessAdminFormKeys.ZIP]: true,
      },
      requiredFields: {
        [BusinessAdminFormKeys.FIRST_NAME]: true,
        [BusinessAdminFormKeys.LAST_NAME]: true,
        [BusinessAdminFormKeys.EMAIL]: true,
        [BusinessAdminFormKeys.DATE_OF_BIRTH]: isKybDelegated ? false : true,
        [BusinessAdminFormKeys.SSN]: isKybDelegated ? false : true,
        [BusinessAdminFormKeys.ADDRESS_1]: true,
        [BusinessAdminFormKeys.ADDRESS_2]: false,
        [BusinessAdminFormKeys.CITY]: true,
        [BusinessAdminFormKeys.STATE]: true,
        [BusinessAdminFormKeys.ZIP]: true,
      },
    }
  }

  return {
    fieldVisibility: {
      [BusinessAdminFormKeys.FIRST_NAME]: true,
      [BusinessAdminFormKeys.LAST_NAME]: true,
      [BusinessAdminFormKeys.EMAIL]: true,
      [BusinessAdminFormKeys.DATE_OF_BIRTH]: false,
      [BusinessAdminFormKeys.SSN]: false,
      [BusinessAdminFormKeys.ADDRESS_1]: false,
      [BusinessAdminFormKeys.ADDRESS_2]: false,
      [BusinessAdminFormKeys.CITY]: false,
      [BusinessAdminFormKeys.STATE]: false,
      [BusinessAdminFormKeys.ZIP]: false,
    },
    requiredFields: {
      [BusinessAdminFormKeys.FIRST_NAME]: true,
      [BusinessAdminFormKeys.LAST_NAME]: true,
      [BusinessAdminFormKeys.EMAIL]: true,
      [BusinessAdminFormKeys.DATE_OF_BIRTH]: false,
      [BusinessAdminFormKeys.SSN]: false,
      [BusinessAdminFormKeys.ADDRESS_1]: false,
      [BusinessAdminFormKeys.ADDRESS_2]: false,
      [BusinessAdminFormKeys.CITY]: false,
      [BusinessAdminFormKeys.STATE]: false,
      [BusinessAdminFormKeys.ZIP]: false,
    },
  }
}

const createValidationSchema = ({
  businessType,
  isKybDelegated,
}: {
  businessType: BusinessType
  isKybDelegated: boolean
}): Yup.SchemaOf<BusinessAdminFormSchema> => {
  const schemaFields: Partial<Record<keyof BusinessAdminFormSchema, any>> = {
    [BusinessAdminFormKeys.FIRST_NAME]: Yup.string().required(),
    [BusinessAdminFormKeys.LAST_NAME]: Yup.string().required(),
    [BusinessAdminFormKeys.EMAIL]: emailValidation,
  }

  // address is only required for sole proprietorship businesses
  if (businessType === BusinessType.SOLE_PROPRIETORSHIP) {
    schemaFields[BusinessAdminFormKeys.ADDRESS_1] = Yup.string().required()
    schemaFields[BusinessAdminFormKeys.ADDRESS_2] = Yup.string()
    schemaFields[BusinessAdminFormKeys.CITY] = Yup.string().required()
    schemaFields[BusinessAdminFormKeys.STATE] = Yup.string().required()
    schemaFields[BusinessAdminFormKeys.ZIP] = zipCodeValidation

    // if client is not KYB Delegated, ensure DOB + SSN are required.
    if (!isKybDelegated) {
      schemaFields[BusinessAdminFormKeys.DATE_OF_BIRTH] = dateOfBirthValidation
      schemaFields[BusinessAdminFormKeys.SSN] = Yup.string()
        .required()
        .when((ssn: string, schema: any) => {
          return schema.test(
            'test SSN length',
            'The last four digits of the Social Security Number is required.',
            (text: string) => {
              const length = text.trim().length
              if (length < 4) {
                return false
              }
              return true
            },
          )
        })
    }
  }

  return Yup.object().shape(schemaFields as any)
}

const dontClearErrorsOnSuccess = false

// This option allows you to configure the validation strategy before a user submits the form (onSubmit event).
const formValidationMode: keyof ValidationMode = 'onChange'

export default function useBusinessAdminForm(): BusinessAdminFormReturnType {
  const dispatch = useAppDispatch()

  const businessInfo = useSelector(selectBusinessInfo)
  const businessAdmin = useSelector(selectBusinessAdmin)
  const isControllerRequired = useSelector(selectIsControllerRequired)
  const isKybDelegated = useSelector(selectIsKybDelegated)

  const selectedFormConfig = createFormConfig({
    businessType: businessInfo.businessType as BusinessType,
    isKybDelegated: isKybDelegated,
  })

  const validationSchema = createValidationSchema({
    businessType: businessInfo.businessType as BusinessType,
    isKybDelegated: isKybDelegated,
  }) as Yup.AnyObjectSchema

  const [defaultValues, setDefaultValues] = useState<BusinessAdminFormSchema>({
    ...businessAdminFormDefaultValues,
  })

  const isNewForm = true

  // SET INITIAL/DEFAULT FORM INPUT VALUES
  useEffect(() => {
    if (businessAdmin) {
      const defaultDOB = businessAdmin.dateOfBirth
        ? convertDateFromISO(businessAdmin.dateOfBirth)
        : ''

      setDefaultValues({
        ...defaultValues,
        ...businessAdmin,
        [BusinessAdminFormKeys.DATE_OF_BIRTH]: defaultDOB,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessAdmin])

  const onSubmit = (data: BusinessAdminFormSchema) => {
    dispatch(setBusinessAdmin(data))

    if (isControllerRequired) {
      dispatch(setBusinessFlowStep(BusinessFlowStepIndex.CONTROLLER))
    } else {
      dispatch(verifyBusiness())
      dispatch(finishVerifyBusinessProcess())
    }
  }

  const onEdit = () => {
    dispatch(setBusinessFlowStep(BusinessFlowStepIndex.ADMIN))
  }

  const formHandler = useFormHandler<BusinessAdminFormSchema>(
    async data => {
      return onSubmit(data)
    },
    defaultValues,
    validationSchema,
    dontClearErrorsOnSuccess,
    formValidationMode,
  )

  const { isValid, errors } = formHandler.form.formState

  return {
    ...formHandler,
    defaultValues: formHandler.form.formState
      .defaultValues as BusinessAdminFormSchema,
    errors,
    fieldVisibility: selectedFormConfig.fieldVisibility,
    isKybDelegated: isKybDelegated,
    isNewForm,
    isSubmitDisabled: !isValid,
    onEdit,
    requiredFields: selectedFormConfig.requiredFields,
  }
}

export type BusinessAdminFormReturnType = {
  defaultValues: BusinessAdminFormSchema
  errors: FieldErrorsImpl<DeepRequired<BusinessAdminFormSchema>>
  fieldVisibility: Partial<Record<keyof BusinessAdminFormSchema, boolean>>
  form: UseFormReturn<BusinessAdminFormSchema, any>
  isKybDelegated: boolean
  isNewForm: boolean
  isSubmitDisabled: boolean
  onEdit: () => void
  onSubmit: (e?: FormEvent<Element> | undefined) => Promise<void>
  requiredFields: Partial<Record<keyof BusinessAdminFormSchema, boolean>>
}
