/* global grecaptcha */
import { initializeApp } from 'firebase/app'
import * as firebase from 'firebase/auth'
import { ConfirmationResult, Unsubscribe, UserCredential } from 'firebase/auth'
import logError from './logError'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

const firebaseApp = initializeApp(firebaseConfig)
const auth = firebase.getAuth(firebaseApp)
auth.setPersistence(firebase.browserSessionPersistence)

export const onAuthStateChanged = (
  callback: (...args: any) => void,
): Unsubscribe => {
  return firebase.onAuthStateChanged(auth, callback)
}

export const signInWithCustomToken = async (
  customToken: string,
): Promise<UserCredential> => {
  return firebase.signInWithCustomToken(auth, customToken)
}

export const signInWithPhoneNumber = async (
  phoneNumber: string,
  appVerifier: firebase.ApplicationVerifier,
): Promise<ConfirmationResult> => {
  return firebase.signInWithPhoneNumber(auth, phoneNumber, appVerifier)
}

export const signOut = async (): Promise<void> => {
  firebase.signOut(auth)
}

export const resetRecaptcha = async (): Promise<void> => {
  try {
    if (
      typeof grecaptcha !== 'undefined' &&
      typeof window.recaptchaWidgetId !== 'undefined'
    ) {
      grecaptcha.reset(window.recaptchaWidgetId)
    } else if (
      typeof window.recaptchaVerifier !== 'undefined' &&
      typeof window.recaptchaWidgetId !== 'undefined'
    ) {
      window.recaptchaVerifier.recaptcha.reset(window.recaptchaWidgetId)
    } else if (typeof window.recaptchaVerifier !== 'undefined') {
      window.recaptchaWidgetId = await window.recaptchaVerifier.render()
    } else {
      window.recaptchaVerifier = new firebase.RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
        },
        auth,
      )

      window.recaptchaWidgetId = await window.recaptchaVerifier.render()
    }
  } catch (error) {
    logError(error)
  }
}

export default auth

export const hideRecaptcha = (): void => {
  const recaptchaDiv = document.getElementById('recaptcha-container')

  if (recaptchaDiv) {
    recaptchaDiv.style.visibility = 'hidden'
  }
}
