import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Box } from 'atomic-layout'

import RoutineIcon from '../../components/RoutineIcon'
import { institutionsSelectors } from '../accounts/institutionsSlice'
import { IonIcon, InstitutionLogo, Link, Text } from '../../atoms'

const Container = styled(Box)`
  padding: 15px 18px;
  &:hover {
    background: ${({ theme }) => theme.colors.backgroundTinted};
  }
`

const RoutineRow = ({ routine }) => {
  const sourceInstitution = useSelector(state =>
    institutionsSelectors.selectById(state, routine.source_inst_item_id),
  )
  const destinationInstitution = useSelector(state =>
    institutionsSelectors.selectById(state, routine.destination_inst_item_id),
  )

  return (
    <Link to={`/routines/${routine.id}`}>
      <Container flex alignItems='center'>
        <RoutineIcon routineType={routine.type} />
        <Box flexGrow={1} marginLeft={12}>
          <Text fontFamily='medium' size='small'>
            {routine.name}
          </Text>
          <Box flex alignItems='center'>
            <Text color='textSubtle' size='xsmall'>
              {routine.friendly_name}
            </Text>
          </Box>
        </Box>
        {sourceInstitution && destinationInstitution && (
          <Box flex alignItems='center'>
            <InstitutionLogo
              logo={sourceInstitution.logo}
              name={sourceInstitution.name}
            />
            <Box marginHorizontal={2}>
              <IonIcon name='arrow-forward-outline' color='border' size={20} />
            </Box>
            <InstitutionLogo
              logo={destinationInstitution.logo}
              name={destinationInstitution.name}
            />
          </Box>
        )}
      </Container>
    </Link>
  )
}

export default RoutineRow
