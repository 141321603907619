import React from 'react'
import { Control, Controller, FieldError } from 'react-hook-form'
import { MaskedInput } from 'src/atoms'

interface ZipcodeFieldProps {
  id: string
  name: string
  defaultValue: string
  placeholder: string
  isRequired?: boolean
  error?: FieldError
  control: Control<any, any>
}

const ZipcodeField: React.FC<ZipcodeFieldProps> = ({
  id,
  name,
  defaultValue,
  placeholder,
  isRequired,
  error,
  control,
}) => (
  <Controller
    control={control}
    name={name}
    defaultValue={defaultValue}
    rules={{
      validate: {
        zipRequired: value => {
          return isRequired && value.trim().length < 5
            ? '5 digits required'
            : undefined
        },
      },
    }}
    render={({ field: { onChange, onBlur, value } }) => (
      <MaskedInput
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        name={name}
        id={id}
        type='text'
        format='#####'
        placeholder={placeholder}
        bordercolor={error && 'error'}
      />
    )}
  />
)

export default ZipcodeField
