import React from 'react'
import { Box } from 'atomic-layout'
import styled from 'styled-components'
import { Text } from '../atoms'

const Circle = styled(Box)`
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  cursor: ${props => (props.onClick ? 'pointer' : 'auto')};
  height: 24px;
  min-width: 20px;
  padding: 0 5px;
`

type Props = {
  count: number
  loading: boolean
  onClick?: () => void
}

const Count: React.FC<Props> = ({ count, loading, onClick }: Props) => (
  <Circle flex alignItems='center' justifyContent='center' onClick={onClick}>
    <Text color='accent'>{loading ? '-' : String(count)}</Text>
  </Circle>
)

export default Count
