import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Box } from 'atomic-layout'

import Logo from './Logo'
import { Text } from '../atoms'
import { selectClient } from '../features/auth/authSlice'

const Header: React.FC = () => {
  const { loading, logo, name } = useSelector(selectClient)
  const [imageFailed, setImageFailed] = useState(false)

  const handleImageError = () => {
    setImageFailed(true)
  }

  const renderHeader = () => {
    if (loading) return null

    if (logo && !imageFailed) {
      return (
        <img
          src={logo}
          alt={`${name} logo`}
          style={{ height: 65 }}
          onError={handleImageError}
        />
      )
    }

    if (name) {
      return (
        <Text
          fontFamily='heavy'
          size='xxlarge'
          padding='0 15px'
          textAlign='center'
        >
          {name}
        </Text>
      )
    }

    return <Logo size='medium' />
  }

  return (
    <Box
      flex
      justifyContent='center'
      paddingTop={40}
      paddingBottom={30}
      as='header'
      minHeight={65}
      width='100%'
    >
      {renderHeader()}
    </Box>
  )
}

export default Header
