import React from 'react'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import '@reach/dialog/styles.css'

interface Props {
  ariaLabel: string
  isOpen: boolean
  close: () => void
}

const Modal: React.FC<Props> = ({ ariaLabel, isOpen, close, children }) => (
  <>
    <DialogOverlay
      isOpen={isOpen}
      onDismiss={close}
      style={{ background: 'hsla(0, 0%, 0%, 0.8)' }}
    >
      <DialogContent
        aria-label={ariaLabel}
        style={{ borderRadius: 4, maxWidth: 380, width: '90vw' }}
      >
        {children}
      </DialogContent>
    </DialogOverlay>
  </>
)

export default Modal
