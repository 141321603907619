import React from 'react'
import NumberFormat from 'react-number-format'
import { Text } from '../atoms'
import { astraTheme } from '../styles/theme'

interface PhoneDisplayProps {
  phoneNumber: string
  size?: keyof typeof astraTheme.text
}

const PhoneNumberDisplay: React.FC<PhoneDisplayProps> = ({
  phoneNumber,
  size,
}) => {
  if (!phoneNumber) return null

  return (
    <NumberFormat
      value={phoneNumber.substring(8, 12)}
      displayType='text'
      format='(•••) ••• - ####'
      renderText={value => (
        <Text fontFamily='medium' size={size || 'medium'} color='textSubtle'>
          {value}
        </Text>
      )}
    />
  )
}

export default PhoneNumberDisplay
