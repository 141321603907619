import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Composition } from 'atomic-layout'

import DateOfBirthField from 'src/components/DateOfBirthField'
import SocialSecurityField from 'src/components/SocialSecurityField'
import { Button, Container, Input, Text } from 'src/atoms'
import {
  selectIsSubmitting,
  selectBusinessInfo,
  selectBusinessAdmin,
} from 'src/features/verify/verifySlice'
import { BusinessType } from 'src/features/verify/types'
import useBusinessAdminForm, {
  BusinessAdminFormKeys,
} from 'src/features/verify/business/BusinessAdmin/useBusinessAdminForm'
import AddressForm from 'src/features/verify/AddressForm'

interface VerifyBusinessAdminProps {
  isReadOnly: boolean
}

const BusinessAdmin: React.FC<VerifyBusinessAdminProps> = ({ isReadOnly }) => {
  const {
    defaultValues,
    errors,
    form,
    isKybDelegated,
    isSubmitDisabled,
    onEdit,
    onSubmit,
  } = useBusinessAdminForm()

  const isSubmitting = useSelector(selectIsSubmitting)
  const { businessType } = useSelector(selectBusinessInfo)
  const { firstName, lastName, email } = useSelector(selectBusinessAdmin)

  const areasMobile = `
  title
  subtitle
  form
`

  const [formAreasMobile, setFormAreasMobile] = useState(`
  firstName
  lastName
  email
  continue
`)

  useEffect(() => {
    if (businessType === BusinessType.SOLE_PROPRIETORSHIP) {
      if (isKybDelegated) {
        setFormAreasMobile(`
        firstName
        lastName
        email
        addressSection
        continue
      `)
      } else {
        setFormAreasMobile(`
        firstName
        lastName
        email
        dateOfBirth
        socialSecurityNumber
        addressSection
        continue
      `)
      }
    }
  }, [businessType, isKybDelegated])

  const completedAreasMobile = `
  name edit
  email edit
`

  if (isReadOnly) {
    return (
      <Composition
        areas={completedAreasMobile}
        as={Container}
        gap={3}
        alignItems='center'
        justifyContent='space-between'
      >
        {Areas => (
          <>
            <Areas.Name>
              <Text fontFamily='medium' color='textSubtle'>
                {firstName} {lastName}
              </Text>
            </Areas.Name>
            <Areas.Email>
              <Text fontFamily='medium' color='textSubtle'>
                {email}
              </Text>
            </Areas.Email>
            <Areas.Edit>
              <Text
                onClick={onEdit}
                color='primary'
                cursor='pointer'
                fontFamily='medium'
              >
                Edit
              </Text>
            </Areas.Edit>
          </>
        )}
      </Composition>
    )
  }

  return (
    <Composition areas={areasMobile} as={Container}>
      {Areas => (
        <>
          <Areas.Title marginBottom={3}>
            <Text fontFamily='heavy' size='large'>
              Verify{' '}
              {businessType === BusinessType.SOLE_PROPRIETORSHIP
                ? 'Business Owner'
                : 'Account Admin'}
            </Text>
          </Areas.Title>
          <Areas.Subtitle marginBottom={15}>
            <Text fontFamily='medium' size='small' color='textSubtle'>
              Enter details for your account admin.
            </Text>
          </Areas.Subtitle>
          <Areas.Form>
            <form onSubmit={onSubmit}>
              <Composition areas={formAreasMobile} gap={10}>
                {FormAreas => (
                  <>
                    <FormAreas.FirstName>
                      <Input
                        id={BusinessAdminFormKeys.FIRST_NAME}
                        type='text'
                        placeholder='First Name'
                        defaultValue={
                          defaultValues[BusinessAdminFormKeys.FIRST_NAME]
                        }
                        bordercolor={
                          errors[BusinessAdminFormKeys.FIRST_NAME] && 'error'
                        }
                        {...form.register(BusinessAdminFormKeys.FIRST_NAME, {
                          required: true,
                        })}
                      />
                    </FormAreas.FirstName>
                    <FormAreas.LastName>
                      <Input
                        id={BusinessAdminFormKeys.LAST_NAME}
                        type='text'
                        placeholder='Last Name'
                        defaultValue={
                          defaultValues[BusinessAdminFormKeys.LAST_NAME]
                        }
                        bordercolor={
                          errors[BusinessAdminFormKeys.LAST_NAME] && 'error'
                        }
                        {...form.register(BusinessAdminFormKeys.LAST_NAME, {
                          required: true,
                        })}
                      />
                    </FormAreas.LastName>
                    <FormAreas.Email>
                      <Input
                        id={BusinessAdminFormKeys.EMAIL}
                        type='text'
                        placeholder='Email Address'
                        defaultValue={
                          defaultValues[BusinessAdminFormKeys.EMAIL]
                        }
                        bordercolor={
                          errors[BusinessAdminFormKeys.EMAIL] && 'error'
                        }
                        {...form.register(BusinessAdminFormKeys.EMAIL, {
                          required: true,
                        })}
                      />
                    </FormAreas.Email>
                    {businessType === BusinessType.SOLE_PROPRIETORSHIP && (
                      <>
                        {isKybDelegated ? null : (
                          <>
                            <FormAreas.DateOfBirth>
                              <DateOfBirthField
                                control={form.control}
                                defaultValue={
                                  defaultValues[
                                    BusinessAdminFormKeys.DATE_OF_BIRTH
                                  ] as string
                                }
                                error={
                                  errors[BusinessAdminFormKeys.DATE_OF_BIRTH]
                                }
                                id={BusinessAdminFormKeys.DATE_OF_BIRTH}
                                {...form.register(
                                  BusinessAdminFormKeys.DATE_OF_BIRTH,
                                  {
                                    required:
                                      businessType ===
                                      BusinessType.SOLE_PROPRIETORSHIP,
                                  },
                                )}
                              />
                            </FormAreas.DateOfBirth>
                            <FormAreas.SocialSecurityNumber>
                              <SocialSecurityField
                                id={BusinessAdminFormKeys.SSN}
                                control={form.control}
                                defaultValue={
                                  defaultValues[
                                    BusinessAdminFormKeys.SSN
                                  ] as string
                                }
                                error={errors[BusinessAdminFormKeys.SSN]}
                                requireFullNumber={false}
                                {...form.register(BusinessAdminFormKeys.SSN, {
                                  required:
                                    businessType ===
                                    BusinessType.SOLE_PROPRIETORSHIP,
                                })}
                              />
                            </FormAreas.SocialSecurityNumber>
                          </>
                        )}

                        <FormAreas.AddressSection>
                          <AddressForm
                            control={form.control}
                            defaultValues={{
                              address1: defaultValues.address1 as string,
                              address2: defaultValues.address2 as string,
                              city: defaultValues.city as string,
                              state: defaultValues.state as string,
                              zip: defaultValues.zip as string,
                            }}
                            errors={errors}
                            register={form.register}
                          />
                        </FormAreas.AddressSection>
                      </>
                    )}
                    <FormAreas.Continue>
                      <Button
                        type='submit'
                        disabled={isSubmitDisabled}
                        fullWidth
                      >
                        {isSubmitting ? 'Sending...' : 'Continue'}
                      </Button>
                    </FormAreas.Continue>
                  </>
                )}
              </Composition>
            </form>
          </Areas.Form>
        </>
      )}
    </Composition>
  )
}

export default BusinessAdmin
