import React from 'react'
import styled, { css } from 'styled-components'
import { astraTheme, CustomThemeType } from '../styles/theme'
import Icon from './Icon'

interface SizeProp {
  size?: keyof typeof astraTheme.institutionLogo
}

interface SizeProps extends SizeProp {
  theme: CustomThemeType
}
interface InstitutionLogoProps {
  logo?: string
  name: string
  size?: keyof typeof astraTheme.institutionLogo
}

const sizeStyle = ({ size = 'medium', theme }: SizeProps) => {
  const data = theme.institutionLogo[size]
  return css`
    border-radius: ${data.radius};
    height: ${data.size};
    width: ${data.size};
  `
}

const InstitutionLogoImage = styled.img<SizeProp>`
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  display: block;
  ${props => sizeStyle(props)}
`

const FallBack = styled.div<SizeProp>`
  background: ${({ theme }) => theme.colors.backgroundTinted};
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => sizeStyle(props)}
`

const InstitutionLogo: React.FC<InstitutionLogoProps> = ({
  logo,
  name,
  size,
}) => {
  if (!logo) {
    let fallbackSize = 16
    if (size === 'large') {
      fallbackSize = 22
    } else if (size === 'small') {
      fallbackSize = 14
    }
    return (
      <FallBack size={size}>
        <Icon color='text' name='bank' size={fallbackSize} />
      </FallBack>
    )
  }

  return (
    <InstitutionLogoImage
      src={`data:image/png;base64,${logo}`}
      alt={`${name} Logo`}
      size={size}
    />
  )
}

export default InstitutionLogo
