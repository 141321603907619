/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Persona, { Client } from 'persona'
import { MutableRefObject } from 'react'

/**
 * Creates a new instance of the `Persona` client.
 *
 * NOTE: if this is called when the popup is closed, an entirely new inquiry will be created (as
 * opposed to if you were to call client.open()).
 *
 * @see refer to the [hosted flow integration docs](https://docs.withpersona.com/docs/hosted-flow) for more information + reference
 *
 * @param embeddedPersonaClientRef A mutable ref object that will hold the client instance.
 */
export const createClient = ({
  embeddedPersonaClientRef,
  inquiryId,
  sessionToken,
  onComplete,
  onCancel,
  onLoad,
  onReady,
  onError,
}: {
  embeddedPersonaClientRef: MutableRefObject<Client | null>
  inquiryId: string
  sessionToken?: string
  onComplete: () => void
  onCancel?: () => void
  onLoad: () => void
  onReady: () => void
  onError?: () => void
}): void => {
  const client = new Persona.Client({
    /**
     * Specify an inquiry ID to resume an existing inquiry.
     */
    inquiryId,

    /**
     * Required when resuming pending inquiries.
     */
    sessionToken,

    /**
     * The onLoad callback is called when the iframe finishes loading and is ready to be displayed. It does not take any arguments.
     */
    onLoad: () => {
      // NOTE: we could perhaps initiate some kind of spinner while this is loading
      onLoad()
    },

    /**
     * The onReady callback is called when the inquiry flow is ready for user interaction. It does not take any arguments.
     */
    onReady: () => {
      onReady()
      client.open()
    },

    /**
     * The onComplete callback is called when the inquiry has completed the inquiry flow and the individual clicks on the complete button to close the flow.
     */
    onComplete: ({ inquiryId, status }) => {
      onComplete()
    },

    /**
     * The onCancel callback is called when an individual cancels the inquiry flow before completion.
     */
    onCancel: () => {
      if (onCancel) {
        onCancel()
      }
    },

    /**
     * The onError callback is called in response to errors in the inquiry flow that prevent the inquiry flow from being usable. These generally occur on initial load.
     * onError is not fired for network errors (e.g. dropped requests from bad connections, blocked requests due to application security settings, etc.)
     */
    onError: (error: any) => {
      if (onError) {
        onError()
      }
    },

    /**
     * The onEvent callback is called at certain points in the Persona flow.
     * It takes two arguments, an eventName string and a metadata object.
     *
     * NOTE: Do not use data in events critical flows. onEvent is sometimes passed the
     * current state of the inquiry for convenience purposes. These values are not guaranteed
     * to be the latest values on the inquiry
     */
    onEvent: (name: any, meta: any) => {},
  })

  // assign the new client instance to the ref object.
  embeddedPersonaClientRef.current = client
}

/**
 * A list of Persona error codes
 * @see [onError](https://docs.withpersona.com/docs/embedded-flow-event-handling#onerror)
 */
export enum PersonaErrorCode {
  /**
   * An internal error occurred in the Persona web application. Please contact support.
   */
  APPLICATION_ERROR = 'application_error',

  /**
   * The persona client was initialized with invalid arguments.
   */
  INVALID_CONFIG = 'invalid_config',

  /**
   * An inquiry was resumed without a valid sessionToken. Retrieve one from the external API and pass it to the client.
   */
  UNAUTHENTICATED = 'unauthenticated',

  /**
   * An attempt was made to create an inquiry from an inactive template. Activate the template before attempting to create inquiries.
   */
  INACTIVE_TEMPLATE = 'inactive_template',

  /**
   * Catch-all error.
   */
  UNKNOWN = 'unknown',
}
