import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Box } from 'atomic-layout'
import capitalize from 'lodash/capitalize'

import { institutionsSelectors } from './institutionsSlice'
import { InstitutionLogo, Link, Text } from '../../atoms'
import { getAccountPrimaryBalance } from '../../utils/accounts'

const Container = styled(Box)`
  padding: 15px 18px;
  &:hover {
    background: ${({ theme }) => theme.colors.backgroundTinted};
  }
`

const AccountRow = ({ account }) => {
  const institution = useSelector(state => {
    return institutionsSelectors.selectById(state, account.institution_item_id)
  })
  return (
    <Link to={`/accounts/${account.id}`}>
      <Container flex alignItems='center'>
        {institution && (
          <InstitutionLogo
            logo={institution.logo}
            name={institution.name}
            size='large'
          />
        )}
        <Box flexGrow={1} marginLeft={12}>
          <Text fontFamily='medium' size='small'>
            {account.name}
          </Text>
          <Text color='textSubtle' size='xsmall'>
            {account.number}
          </Text>
        </Box>
        <Box>
          <Text fontFamily='medium' size='small' textAlign='right'>
            {getAccountPrimaryBalance(account)}
          </Text>
          {institution && (
            <Text
              color={institution.link_status === 'error' ? 'error' : 'primary'}
              size='xsmall'
              textAlign='right'
            >
              {capitalize(institution.link_status)}
            </Text>
          )}
        </Box>
      </Container>
    </Link>
  )
}

export default AccountRow
