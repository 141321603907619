import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { navigate } from '@reach/router'
import { Box, Composition } from 'atomic-layout'
import { accountsSelectors, selectAccountsLoading } from './accountsSlice'
import { Container, Link, Text } from 'src/atoms'
import AccordionContent from 'src/components/AccordionContent'
import AccordionIcon from 'src/components/AccordionIcon'
import Count from 'src/components/Count'
import AccountRow from './AccountRow'

const areasMobile = `
  header
  list
  footer
`
const listSize = 4

const AccountsOverview: React.FC = () => {
  const accounts = useSelector(accountsSelectors.selectAll)
  const loading = useSelector(selectAccountsLoading)
  const [isOpen, setIsOpen] = useState(true)

  useEffect(() => {
    setIsOpen(accounts.length !== 0)
  }, [accounts])

  return (
    <Composition areas={areasMobile} as={Container} paddingHorizontal={0}>
      {Areas => (
        <>
          <Areas.Header flex alignItems='center' paddingHorizontal={18}>
            <Text
              cursor='pointer'
              fontFamily='heavy'
              size='large'
              onClick={() => navigate('/accounts')}
            >
              Accounts
            </Text>
            <Box marginHorizontal={10}>
              <Count
                count={accounts.length}
                onClick={() => navigate('/accounts')}
                loading={loading}
              />
            </Box>

            <Box
              flex
              flexGrow={'1'}
              justifyContent='flex-end'
              onClick={() => setIsOpen(!isOpen)}
            >
              <AccordionIcon isOpen={isOpen} />
            </Box>
          </Areas.Header>
          <AccordionContent isOpen={isOpen}>
            <>
              <Areas.List marginTop={20} marginBottom={10}>
                {accounts &&
                  accounts
                    .slice(0, listSize)
                    .map(account => (
                      <AccountRow key={account.id} account={account} />
                    ))}
              </Areas.List>
              {accounts && accounts.length > listSize && (
                <Areas.Footer flex alignItems='center' justifyContent='center'>
                  <Link to='/accounts' size='small'>
                    More
                  </Link>
                </Areas.Footer>
              )}
            </>
          </AccordionContent>
        </>
      )}
    </Composition>
  )
}

export default AccountsOverview
