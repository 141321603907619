import React from 'react'

import { ExternalLink, Text } from '../../atoms'

interface TermsOfServiceProps {
  isLoggedIn: boolean
}

const TermsOfService: React.FC<TermsOfServiceProps> = ({ isLoggedIn }) => {
  return (
    <Text size='xsmall' color='textSubtle' textAlign={'center'}>
      {`${
        !isLoggedIn ? 'I' : 'By selecting "Continue" you'
      } agree to the Astra`}{' '}
      <ExternalLink
        href='https://astra.finance/terms'
        target='_blank'
        rel='noreferrer'
        color='textSubtle'
      >
        Terms of Service
      </ExternalLink>
      {` and `}
      <ExternalLink
        href='https://astra.finance/privacy/'
        target='_blank'
        rel='noreferrer'
        color='textSubtle'
      >
        Privacy Policy
      </ExternalLink>
      .
    </Text>
  )
}

export default TermsOfService
