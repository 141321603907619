import React from 'react'
import { Control, Controller, FieldError } from 'react-hook-form'
import { DateTime } from 'luxon'
import { MaskedInput } from 'src/atoms'

const validateDate = (date: string): string | undefined => {
  if (date.replace(/[^+0-9]/g, '').length < 8) {
    return 'required'
  }

  const dateObj = DateTime.fromFormat(date, 'MM/dd/yyyy')
  if (!dateObj.isValid) {
    return 'Date format is incorrect.'
  }

  const isOver18 = dateObj.diffNow('years').years < -18

  return isOver18 ? undefined : 'You must be 18 years old.'
}

interface DateOfBirthFieldProps {
  id: string
  name: string
  defaultValue: string
  error?: FieldError
  control: Control<any, any>
}

/**
 * A functional React component which renders an input field utilizes for DOB values
 * This input field uses the `MaskedInput` component for privacy measures.
 */
const DateOfBirthField: React.FC<DateOfBirthFieldProps> = ({
  id,
  name,
  defaultValue,
  error,
  control,
}) => (
  <Controller
    control={control}
    name={name}
    defaultValue={defaultValue}
    rules={{ validate: { dateRequired: validateDate } }}
    render={({ field: { onChange, onBlur, value } }) => (
      <MaskedInput
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        name={name}
        id={id}
        type='text'
        format='##/##/####'
        placeholder='Date of Birth'
        mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
        bordercolor={error && 'error'}
      />
    )}
  />
)

export default DateOfBirthField
