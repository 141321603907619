import React from 'react'
import capitalize from 'lodash/capitalize'
import { Text } from '../atoms'

const TransferStatus: React.FC<{ status: string }> = ({ status }) => {
  const getColor = () => {
    if (status === 'pending') {
      return 'primary'
    }
    if (
      status === 'canceled' ||
      status === 'completed' ||
      status === 'processed'
    ) {
      return 'textSubtle'
    }
    return 'error'
  }

  return (
    <Text color={getColor()} size='xsmall'>
      {capitalize(status)}
    </Text>
  )
}

export default TransferStatus
