import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import * as queryString from 'query-string'
import { navigate } from '@reach/router'
import { unwrapResult } from '@reduxjs/toolkit'

import VerifyPersonalInfo from 'src/features/verify/personal/VerifyPersonalInfo'
import VerifyPersonalAddress from 'src/features/verify/personal/VerifyPersonalAddress'
import { setVerifyPersonalData, verify } from 'src/features/verify/verifySlice'
import {
  selectIframeOrigin,
  sendClosedMessage,
} from 'src/features/sdkMessaging/sdkMessagingSlice'
import { useAppDispatch } from 'src/redux/store'

interface VerifyPersonalProps {
  location: Location
}

const VerifyPersonal: React.FC<VerifyPersonalProps> = ({ location }) => {
  const dispatch = useAppDispatch()

  const iframeOrigin = useSelector(selectIframeOrigin)

  const [step, setStep] = useState('personal')

  const {
    continue_to: continueTo,
    redirect_uri: redirectUri,
    return_to: returnTo,
  } = queryString.parse(location.search)

  const submitPersonal = (data: {
    dateOfBirth: string
    ssn: string
    legalFirstName: string
    legalLastName: string
  }) => {
    dispatch(setVerifyPersonalData(data))
    setStep('address')
  }

  const submitAddress = (data: {
    address1: string
    address2: string
    city: string
    state: string
    zip: string
  }) => {
    dispatch(verify(data))
      .then(unwrapResult)
      .then(() => {
        if (iframeOrigin) {
          dispatch(sendClosedMessage())
        } else {
          const navigateTo = (continueTo ||
            returnTo ||
            redirectUri ||
            '/') as string
          navigate(navigateTo)
        }
      })
  }

  return (
    <>
      <VerifyPersonalInfo
        submit={submitPersonal}
        completed={step === 'address'}
        edit={() => setStep('personal')}
      />
      {step === 'address' && <VerifyPersonalAddress submit={submitAddress} />}
    </>
  )
}

export default VerifyPersonal
