import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Composition } from 'atomic-layout'
import {
  getTransfers,
  selectTransfersLoading,
  transfersSelectors,
} from './transfersSlice'
import { Container, Text } from '../../atoms'
import Count from '../../components/Count'
import Spinner from '../../components/Spinner'
import TransferRow from './TransferRow'

const areasMobile = `
  header
  list
`

const Transfers = () => {
  const dispatch = useDispatch()
  const transfers = useSelector(transfersSelectors.selectAll)
  const loading = useSelector(selectTransfersLoading)

  useEffect(() => {
    dispatch(getTransfers())
  }, [dispatch])

  return (
    <Composition areas={areasMobile} as={Container} paddingHorizontal={0}>
      {Areas => (
        <>
          <Areas.Header flex alignItems='center' paddingHorizontal={18}>
            <Text fontFamily='heavy' size='large'>
              Transfers
            </Text>
            <Box marginLeft={10}>
              <Count count={transfers.length} loading={loading} />
            </Box>
          </Areas.Header>
          {loading ? (
            <Box
              flex
              alignItems='center'
              justifyContent='center'
              paddingVertical={50}
            >
              <Spinner />
            </Box>
          ) : (
            <>
              <Areas.List marginTop={20} marginBottom={10}>
                {transfers &&
                  transfers.map(transfer => (
                    <TransferRow key={transfer.id} transfer={transfer} />
                  ))}
              </Areas.List>
            </>
          )}
        </>
      )}
    </Composition>
  )
}

export default Transfers
