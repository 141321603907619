import React, { useState } from 'react'
import styled from 'styled-components'
import { Box } from 'atomic-layout'
import { Controller, Control } from 'react-hook-form'

const Container = styled(Box)`
  position: relative;
  z-index: 1;
  margin: 5px auto;
  overflow: hidden;
  text-align: initial;
`

const Input = styled.input`
  box-sizing: border-box;
  position: absolute;
  z-index: 10;
  height: 48px;
  width: 100%;
  margin: 0;
  padding-left: 12px;
  appearance: none;
  background: transparent;
  border: none;
  outline: none;
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 1.5rem;
  line-height: normal;
  color: transparent;
  letter-spacing: 1.7em;
  caret-color: #000;
`

const BoxContainer = styled(Box)`
  position: relative;
  z-index: -1;
  width: 100%;
`

const InputBox = styled(Box)`
  height: 50px;
  width: 14%;
  background: ${({ theme }) => theme.colors.background};
  border: 1px solid
    ${props =>
      props.active ? props.theme.colors.primary : props.theme.colors.border};
  border-radius: 4px;
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 1.5rem;
  transition: border 0.5s cubic-bezier(0.2, 0.9, 0.3, 1);
`

interface Props {
  name: string
  control: Control<any, any>
  onSubmit: (authCode: string) => void
  readOnly: boolean
}

const AuthCodeField: React.FC<Props> = ({
  name,
  control,
  onSubmit,
  readOnly,
}) => {
  const [authCode, setAuthCode] = useState('')

  const onChangeHandler = (value: string) => {
    const regex = /^[0-9\b]+$/
    if (value === '' || regex.test(value)) {
      setAuthCode(value)
      if (value.length === 6) {
        onSubmit(value)
      }
    }
  }

  return (
    <Controller
      control={control}
      name={name}
      defaultValue=''
      render={({ field: { onChange } }) => (
        <Container>
          <Input
            type='tel'
            aria-label='verification code'
            maxLength={6}
            onChange={event => {
              onChange(event.target.value)
              onChangeHandler(event.target.value)
            }}
            value={authCode}
            autoFocus
            autoComplete='one-time-code'
            inputMode='numeric'
            readOnly={readOnly}
          />
          <BoxContainer flex justifyContent='space-between'>
            <InputBox
              flex
              alignItems='center'
              justifyContent='center'
              active={authCode.length === 0}
            >
              {authCode[0]}
            </InputBox>
            <InputBox
              flex
              alignItems='center'
              justifyContent='center'
              active={authCode.length === 1}
            >
              {authCode[1]}
            </InputBox>
            <InputBox
              flex
              alignItems='center'
              justifyContent='center'
              active={authCode.length === 2}
            >
              {authCode[2]}
            </InputBox>
            <InputBox
              flex
              alignItems='center'
              justifyContent='center'
              active={authCode.length === 3}
            >
              {authCode[3]}
            </InputBox>
            <InputBox
              flex
              alignItems='center'
              justifyContent='center'
              active={authCode.length === 4}
            >
              {authCode[4]}
            </InputBox>
            <InputBox
              flex
              alignItems='center'
              justifyContent='center'
              active={authCode.length === 5}
            >
              {authCode[5]}
            </InputBox>
          </BoxContainer>
        </Container>
      )}
    />
  )
}

export default AuthCodeField
