import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Composition } from 'atomic-layout'
import {
  getRoutines,
  selectRoutinesLoading,
  routinesSelectors,
} from './routinesSlice'
import { getAccounts } from '../accounts/accountsSlice'
import { getInstitutions } from '../accounts/institutionsSlice'
import { Container, Text } from '../../atoms'
import Count from '../../components/Count'
import RoutineRow from './RoutineRow'
import Spinner from '../../components/Spinner'

const areasMobile = `
  header
  list
`

const Routines = () => {
  const dispatch = useDispatch()
  const routines = useSelector(routinesSelectors.selectAll)
  const loading = useSelector(selectRoutinesLoading)

  useEffect(() => {
    dispatch(getRoutines())
    dispatch(getInstitutions())
    dispatch(getAccounts())
  }, [dispatch])

  return (
    <Composition areas={areasMobile} as={Container} paddingHorizontal={0}>
      {Areas => (
        <>
          <Areas.Header flex alignItems='center' paddingHorizontal={18}>
            <Text fontFamily='heavy' size='large'>
              Routines
            </Text>
            <Box marginLeft={10}>
              <Count count={routines.length} loading={loading} />
            </Box>
          </Areas.Header>
          {loading ? (
            <Box
              flex
              alignItems='center'
              justifyContent='center'
              paddingVertical={50}
            >
              <Spinner />
            </Box>
          ) : (
            <Areas.List marginTop={20} marginBottom={10}>
              {routines &&
                routines.map(routine => (
                  <RoutineRow key={routine.id} routine={routine} />
                ))}
            </Areas.List>
          )}
        </>
      )}
    </Composition>
  )
}

export default Routines
