import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Flex,
} from '@chakra-ui/react'

import { Button, IonIcon } from 'src/atoms'
import { astraTheme } from 'src/styles/theme'

interface ConfirmedSignoutModalProps {
  onDismiss: () => void
}

const ConfirmedSignoutModal: React.FC<ConfirmedSignoutModalProps> = ({
  onDismiss,
}) => (
  <Modal isOpen={true} size='lg' onClose={() => null}>
    <ModalOverlay />
    <ModalContent style={{ margin: 'auto', padding: 10 }}>
      <ModalHeader fontSize={20} fontWeight='bold'>
        <Flex alignItems='center'>
          <IonIcon
            size={25}
            name='time-outline'
            color={astraTheme.colors.info as any}
            style={{ marginRight: 5 }}
          />
          Session Timeout
        </Flex>
      </ModalHeader>
      <ModalBody>
        Due to extended inactivity, we&apos;ve ended your session for your
        security. Please sign back in to continue.
      </ModalBody>
      <ModalFooter>
        <Button onClick={onDismiss} title='Confirm' style={{ width: 100 }}>
          Confirm
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
)

export default ConfirmedSignoutModal
