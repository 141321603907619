import React from 'react'
import { useSelector } from 'react-redux'

import ConnectDebitCardModule from './ConnectDebitCardModule'
import ContinueButton from './ContinueButton'
import { selectClient } from 'src/features/auth/authSlice'

const ConnectAccounts: React.FC = () => {
  const isDebitEnabled = useSelector(selectClient).debitEnabled

  return (
    <>
      {isDebitEnabled && <ConnectDebitCardModule />}
      <ContinueButton />
    </>
  )
}

export default ConnectAccounts
