import React from 'react'
import { Box } from 'atomic-layout'
import styled from 'styled-components'

import { Link, Text } from '../../atoms'

const Container = styled(Box)`
  padding: 15px 18px;
  &:hover {
    background: ${({ theme }) => theme.colors.backgroundTinted};
  }
`

interface DebitCardRowProps {
  card: any
  isDisabled: boolean
}

const DebitCardRow: React.FC<DebitCardRowProps> = ({ card, isDisabled }) => {
  return (
    <Link to={`/cards/${card.id}`} disabled={isDisabled}>
      <Container flex alignItems='start'>
        <Box flexGrow='1' marginLeft={12}>
          <Text fontFamily='heavy' size='small'>
            {card.card_company}
          </Text>
          <Text color='textSubtle' size='xsmall'>
            {card.last_four_digits}
          </Text>
        </Box>
        <Box>
          <Text fontFamily='medium' size='small' textAlign='right'>
            {card.expiration_date}
          </Text>
        </Box>
      </Container>
    </Link>
  )
}

export default DebitCardRow
