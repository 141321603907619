import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AccountsOverview from 'src/features/accounts/AccountsOverview'
import AuthenticatedUser from 'src/features/auth/AuthenticatedUser'
import ClientAppsOverview from 'src/features/clients/ClientAppsOverview'
import DebitCardsOverview from 'src/features/accounts/DebitCardsOverview'
import RoutinesOverview from 'src/features/routines/RoutinesOverview'
import TransfersOverview from 'src/features/transfers/TransfersOverview'
import { getInstitutions } from 'src/features/accounts/institutionsSlice'
import { getAccounts } from 'src/features/accounts/accountsSlice'
import { getAddresses } from 'src/features/accounts/addressesSlice'
import { selectClient } from 'src/features/auth/authSlice'
import { getCards } from 'src/features/accounts/cardsSlice'
import { getRoutines } from 'src/features/routines/routinesSlice'
import { getTransfers } from 'src/features/transfers/transfersSlice'
import { getClients } from 'src/features/clients/clientsSlice'
import { Environment } from 'src/features/auth/types'

const Dashboard: React.FC = () => {
  const dispatch = useDispatch()
  const clientId = useSelector(selectClient).clientId

  const showDebitCardModule =
    process.env.REACT_APP_RELEASE_STAGE !== Environment.PRODUCTION

  useEffect(() => {
    dispatch(getInstitutions())
    dispatch(getAccounts())
    dispatch(getAddresses())
    dispatch(getRoutines())
    dispatch(getTransfers())
    dispatch(getClients())
  }, [dispatch])

  useEffect(() => {
    dispatch(getCards({ clientId }))
  }, [clientId, dispatch])

  return (
    <>
      <AuthenticatedUser />
      <TransfersOverview />
      <RoutinesOverview />
      <AccountsOverview />
      {showDebitCardModule && <DebitCardsOverview />}
      <ClientAppsOverview />
    </>
  )
}

export default Dashboard
